import React from 'react';
import { NavLink } from 'react-router-dom';
import { 
    FaUsers, 
    FaUserShield, 
    FaTools, 
    FaMapMarkerAlt, 
    FaCogs, 
    FaMobile,
    FaClipboardList,
    FaHistory
} from 'react-icons/fa';

const AdminNavigation = () => {
    const navItems = [
        { path: '/admin/users', icon: FaUsers, tooltip: 'User Management' },
        { path: '/admin/roles', icon: FaUserShield, tooltip: 'Role Management' },
        { path: '/admin/equipment', icon: FaTools, tooltip: 'Equipment Management' },
        { path: '/admin/locations', icon: FaMapMarkerAlt, tooltip: 'Location Management' },
        { path: '/admin/maintenance', icon: FaClipboardList, tooltip: 'Maintenance Management' },
        { path: '/admin/devices', icon: FaMobile, tooltip: 'Device Management' },
        { path: '/admin/settings', icon: FaCogs, tooltip: 'Settings' },
        { path: '/admin/logs', icon: FaHistory, tooltip: 'System Logs' }
    ];

    return (
        <nav className="bg-gray-800 text-white h-[40px] flex items-center">
            <ul className="flex justify-center space-x-3 w-full">
                {navItems.map(({ path, icon: Icon, tooltip }) => (
                    <li key={path}>
                        <NavLink
                            to={path}
                            className={({ isActive }) =>
                                `flex items-center justify-center p-1.5 rounded-lg transition-colors duration-200 hover:bg-gray-700 group relative ${
                                    isActive ? 'bg-gray-700' : ''
                                }`
                            }
                        >
                            <Icon className="w-5 h-5" />
                            <span className="sr-only">{tooltip}</span>
                            
                            {/* Tooltip */}
                            <span className="absolute left-1/2 -bottom-10 transform -translate-x-1/2 px-2 py-1 bg-gray-900 text-white text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap z-50">
                                {tooltip}
                            </span>
                        </NavLink>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default AdminNavigation;
