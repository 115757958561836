import axiosInstance from './api';

export const getMaintenanceItems = async (activeOnly = true) => {
  try {
    console.log('Calling getMaintenanceItems with activeOnly:', activeOnly); // Debug log
    const response = await axiosInstance.get('/maintenance-items', {
      params: activeOnly ? { active: true } : {}  // Only send active param if we want active items
    });
    console.log('Response data:', response.data); // Debug log
    return response.data;
  } catch (error) {
    console.error('Error in getMaintenanceItems:', error);
    throw error;
  }
};

export const getMaintenanceItemById = async (id) => {
  try {
    const response = await axiosInstance.get(`/maintenance-items/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createMaintenanceItem = async (itemData) => {
  try {
    const response = await axiosInstance.post('/maintenance-items', itemData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateMaintenanceItem = async (id, itemData) => {
  try {
    const response = await axiosInstance.put(`/maintenance-items/${id}`, itemData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteMaintenanceItem = async (id) => {
  try {
    await axiosInstance.delete(`/maintenance-items/${id}`);
  } catch (error) {
    throw error;
  }
};

export const getEquipmentMaintenanceItems = async (equipmentId) => {
  try {
    const response = await axiosInstance.get(`/maintenance-items/equipment/${equipmentId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching equipment maintenance items:', error);
    // Return empty array instead of throwing to prevent modal from breaking
    return [];
  }
};

export const setEquipmentMaintenanceItem = async (equipmentId, maintenanceItemId, config) => {
  try {
    const response = await axiosInstance.post(`/maintenance-items/equipment/${equipmentId}/maintenance`, {
      maintenanceItemId,
      ...config
    });
    return response.data;
  } catch (error) {
    console.error('Error setting equipment maintenance item:', error);
    throw error;
  }
};

export const updateEquipmentMaintenanceItem = async (equipmentId, maintenanceItemId, updates) => {
  try {
    const response = await axiosInstance.put(
      `/equipment-maintenance/${equipmentId}/${maintenanceItemId}`,
      updates
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const completeMaintenanceItem = async (equipmentId, maintenanceItemId, completionData) => {
  try {
    const response = await axiosInstance.post(
      `/equipment-maintenance/${equipmentId}/${maintenanceItemId}/complete`,
      completionData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateMaintenanceItemsOrder = async (items) => {
  try {
    console.log('Sending reorder request with items:', items);
    const response = await axiosInstance.put('/maintenance-items/reorder', {
      items: items.map((item, index) => ({
        id: item._id,
        order: index
      }))
    });
    console.log('Reorder response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating maintenance items order:', error);
    console.error('Error details:', error.response?.data || error.message);
    throw error;
  }
};