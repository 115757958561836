import React, { useState, useEffect } from 'react';
import { FiPlus, FiTrash2, FiEdit2, FiCheck, FiX, FiEdit, FiSave } from 'react-icons/fi';
import { getMeterReadings } from '../services/meterReadingService';
import { getMaintenanceItems } from '../services/maintenanceItemService';
import { useAuth } from '../hooks/useAuth';
import MaintenanceServiceHistoryModal from './MaintenanceServiceHistoryModal';
import '../styles/MaintenanceSchedule.css';

const INTERVAL_TYPES = ['Hours', 'Odometer', 'Date', 'Both'];

const getStatusColor = (schedule, remainingValue, isDateInterval = false) => {
  if (isDateInterval) {
    if (remainingValue < 0) return 'rgba(255, 0, 0, 0.1)'; // Red for overdue
    const percentRemaining = (remainingValue / schedule.dateInterval) * 100;
    if (percentRemaining > 15) return 'rgba(0, 255, 0, 0.1)';
    if (percentRemaining > 5) return 'rgba(255, 255, 0, 0.1)';
    return 'rgba(255, 165, 0, 0.1)';
  }

  let worstStatus = 'green'; // Start with best status

  const getStatus = (remaining, interval) => {
    if (remaining < 0) return 'red';
    const percent = (remaining / interval) * 100;
    if (percent <= 5) return 'orange';
    if (percent <= 15) return 'yellow';
    return 'green';
  };

  if (schedule.hoursInterval) {
    const hoursStatus = getStatus(remainingValue.hours || 0, schedule.hoursInterval);
    if (hoursStatus === 'red') return 'rgba(255, 0, 0, 0.1)';
    if (hoursStatus === 'orange' && worstStatus === 'green') worstStatus = 'orange';
    if (hoursStatus === 'yellow' && worstStatus === 'green') worstStatus = 'yellow';
  }

  if (schedule.odometerInterval) {
    const odometerStatus = getStatus(remainingValue.odometer || 0, schedule.odometerInterval);
    if (odometerStatus === 'red') return 'rgba(255, 0, 0, 0.1)';
    if (odometerStatus === 'orange' && worstStatus === 'green') worstStatus = 'orange';
    if (odometerStatus === 'yellow' && worstStatus === 'green') worstStatus = 'yellow';
  }

  switch (worstStatus) {
    case 'red': return 'rgba(255, 0, 0, 0.1)';
    case 'orange': return 'rgba(255, 165, 0, 0.1)';
    case 'yellow': return 'rgba(255, 255, 0, 0.1)';
    default: return 'rgba(0, 255, 0, 0.1)';
  }
};

function MaintenanceScheduleSection({ 
  equipment, 
  onUpdate, 
  isEditing, 
  canEdit,
  onToggleEdit 
}) {
  const [editingInterval, setEditingInterval] = useState(null);
  const [editedValues, setEditedValues] = useState(null);
  const [newInterval, setNewInterval] = useState({
    type: '',
    intervalType: 'Hours',
    hoursInterval: '',
    odometerInterval: '',
    dateInterval: '',
    enabled: true
  });
  const [showAddForm, setShowAddForm] = useState(false);
  const [lastMaintenanceReadings, setLastMaintenanceReadings] = useState({});
  const [maintenanceItems, setMaintenanceItems] = useState([]);
  const { user } = useAuth();
  const [selectedService, setSelectedService] = useState(null);
  const [showServiceHistoryModal, setShowServiceHistoryModal] = useState(false);

  useEffect(() => {
    fetchLastMaintenanceReadings();
    fetchMaintenanceItems();
  }, [equipment._id]);

  const fetchLastMaintenanceReadings = async () => {
    try {
      const readings = await getMeterReadings(equipment._id);
      const lastReadings = {};
      
      (equipment.maintenanceSchedule || []).forEach(interval => {
        const maintenanceItemName = interval.maintenanceItem?.name || interval.type;
        
        const maintenanceReadings = readings.filter(reading => 
          reading.maintenanceDetails?.completedChecklist?.some(item => 
            item.item === maintenanceItemName
          )
        );
        
        if (maintenanceReadings.length > 0) {
          const lastReading = maintenanceReadings.sort((a, b) => 
            new Date(b.date) - new Date(a.date)
          )[0];
          
          lastReadings[maintenanceItemName] = {
            date: lastReading.date,
            hours: lastReading.readings.hours,
            odometer: lastReading.readings.odometer
          };
        }
      });

      setLastMaintenanceReadings(lastReadings);
    } catch (error) {
      console.error('Error fetching maintenance readings:', error);
    }
  };

  const fetchMaintenanceItems = async () => {
    try {
      const items = await getMaintenanceItems();
      const activeItems = items.filter(item => item.active);
      setMaintenanceItems(activeItems);
    } catch (error) {
      console.error('Error fetching maintenance items:', error);
    }
  };

  const handleAddInterval = async () => {
    try {
      const updatedSchedule = [...(equipment.maintenanceSchedule || []), {
        ...newInterval,
        maintenanceItem: newInterval.type
      }];
      await onUpdate({ maintenanceSchedule: updatedSchedule });
      setShowAddForm(false);
      setNewInterval({
        type: '',
        intervalType: 'Hours',
        hoursInterval: '',
        odometerInterval: '',
        dateInterval: '',
        enabled: true
      });
    } catch (error) {
      console.error('Failed to add maintenance interval:', error);
    }
  };

  const handleUpdateInterval = async (index, updatedInterval) => {
    try {
      const updatedSchedule = [...equipment.maintenanceSchedule];
      const maintenanceItem = maintenanceItems.find(
        item => item._id === updatedInterval.type || item._id === updatedInterval.maintenanceItem
      );
      
      updatedSchedule[index] = {
        ...updatedInterval,
        enabled: updatedInterval.enabled !== undefined ? updatedInterval.enabled : true,
        maintenanceItem: maintenanceItem?._id || updatedInterval.maintenanceItem,
        type: maintenanceItem?.name || updatedInterval.type
      };
      
      await onUpdate({ maintenanceSchedule: updatedSchedule });
      setEditingInterval(null);
      setEditedValues(null);
    } catch (error) {
      console.error('Failed to update maintenance interval:', error);
    }
  };

  const handleEditStart = (index, interval) => {
    setEditingInterval(index);
    setEditedValues({ ...interval });
  };

  const handleEditCancel = () => {
    setEditingInterval(null);
    setEditedValues(null);
  };

  const handleInputChange = (field, value) => {
    setEditedValues(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleDeleteInterval = async (index) => {
    try {
      const updatedSchedule = equipment.maintenanceSchedule.filter((_, i) => i !== index);
      await onUpdate({ maintenanceSchedule: updatedSchedule });
    } catch (error) {
      console.error('Failed to delete maintenance interval:', error);
    }
  };

  const hasAbility = (ability) => {
    return user?.abilities?.includes(ability) || false;
  };

  const handleServiceClick = (schedule) => {
    if (hasAbility('CanCloseAllWorkOrders')) {
      setSelectedService(schedule);
      setShowServiceHistoryModal(true);
    }
  };

  const renderIntervalForm = (interval, index, isNew = false) => {
    const currentInterval = isNew ? newInterval : 
                          editingInterval === index ? editedValues : interval;
    const isEditing = isNew || editingInterval === index;

    return (
      <div className="maintenance-interval-form">
        <select
          value={currentInterval.maintenanceItem?._id || currentInterval.type}
          onChange={(e) => isNew ? 
            setNewInterval({ ...newInterval, type: e.target.value }) :
            handleInputChange('type', e.target.value)}
          disabled={!isEditing}
          className="maintenance-select"
        >
          <option value="">Select Type</option>
          {maintenanceItems.map(item => (
            <option key={item._id} value={item._id}>
              {item.name}
            </option>
          ))}
        </select>

        <select
          value={currentInterval.intervalType}
          onChange={(e) => isNew ?
            setNewInterval({ ...newInterval, intervalType: e.target.value }) :
            handleInputChange('intervalType', e.target.value)}
          disabled={!isEditing}
          className="maintenance-select"
        >
          {INTERVAL_TYPES.map(type => (
            <option key={type} value={type}>{type}</option>
          ))}
        </select>

        {(currentInterval.intervalType === 'Hours' || currentInterval.intervalType === 'Both') && (
          <input
            type="number"
            placeholder="Hours"
            value={currentInterval.hoursInterval || ''}
            onChange={(e) => isNew ?
              setNewInterval({ ...newInterval, hoursInterval: e.target.value }) :
              handleInputChange('hoursInterval', e.target.value)}
            disabled={!isEditing}
            className="maintenance-input"
          />
        )}

        {(currentInterval.intervalType === 'Odometer' || currentInterval.intervalType === 'Both') && (
          <input
            type="number"
            placeholder="Miles/Km"
            value={currentInterval.odometerInterval || ''}
            onChange={(e) => isNew ?
              setNewInterval({ ...newInterval, odometerInterval: e.target.value }) :
              handleInputChange('odometerInterval', e.target.value)}
            disabled={!isEditing}
            className="maintenance-input"
          />
        )}

        {(currentInterval.intervalType === 'Date' || currentInterval.intervalType === 'Both') && (
          <input
            type="number"
            placeholder="Days"
            value={currentInterval.dateInterval || ''}
            onChange={(e) => isNew ?
              setNewInterval({ ...newInterval, dateInterval: e.target.value }) :
              handleInputChange('dateInterval', e.target.value)}
            disabled={!isEditing}
            className="maintenance-input"
          />
        )}

        {isNew ? (
          <div className="maintenance-actions">
            <button onClick={handleAddInterval} className="icon-button">
              <FiCheck className="icon save" />
            </button>
            <button onClick={() => setShowAddForm(false)} className="icon-button">
              <FiX className="icon cancel" />
            </button>
          </div>
        ) : (
          <div className="maintenance-actions">
            {isEditing ? (
              <>
                <button 
                  onClick={() => handleUpdateInterval(index, editedValues)} 
                  className="icon-button"
                >
                  <FiCheck className="icon save" />
                </button>
                <button 
                  onClick={handleEditCancel} 
                  className="icon-button"
                >
                  <FiX className="icon cancel" />
                </button>
              </>
            ) : (
              <>
                <button 
                  onClick={() => handleEditStart(index, interval)} 
                  className="icon-button"
                >
                  <FiEdit2 className="icon edit" />
                </button>
                <button 
                  onClick={() => handleDeleteInterval(index)} 
                  className="icon-button"
                >
                  <FiTrash2 className="icon delete" />
                </button>
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderReadableInterval = (schedule) => {
    const parts = [];
    const maintenanceItemName = schedule.maintenanceItem?.name || schedule.type;
    const lastReading = lastMaintenanceReadings[maintenanceItemName];
    
    if (schedule.odometerInterval) {
      parts.push(`every ${schedule.odometerInterval.toLocaleString()} miles`);
    }
    if (schedule.hoursInterval) {
      parts.push(`every ${schedule.hoursInterval.toLocaleString()} hours`);
    }
    if (schedule.dateInterval) {
      const days = schedule.dateInterval;
      if (days === 365 || days === 366) {
        parts.push('every year');
      } else if (days === 730 || days === 731) {
        parts.push('every 2 years');
      } else if (days % 365 === 0) {
        const years = days / 365;
        parts.push(`every ${years} years`);
      } else if (days === 30 || days === 31) {
        parts.push('every month');
      } else if (days === 60 || days === 61) {
        parts.push('every 2 months');
      } else if (days === 90 || days === 91) {
        parts.push('every 3 months');
      } else if (days === 180 || days === 181) {
        parts.push('every 6 months');
      } else if (days === 7) {
        parts.push('every week');
      } else if (days === 14) {
        parts.push('every 2 weeks');
      } else {
        parts.push(`every ${days} days`);
      }
    }

    let remainingValues = {
      hours: null,
      odometer: null
    };
    let backgroundColor = 'transparent';

    if (schedule.dateInterval) {
      if (lastReading) {
        const lastServiceDate = new Date(lastReading.date);
        const nextDueDate = new Date(lastServiceDate);
        nextDueDate.setDate(nextDueDate.getDate() + schedule.dateInterval);
        const today = new Date();
        remainingValues.date = Math.ceil((nextDueDate - today) / (1000 * 60 * 60 * 24));
        backgroundColor = getStatusColor(schedule, remainingValues.date, true);
      }
    } else {
      if (schedule.hoursInterval) {
        const lastHours = lastReading?.hours ?? 0;
        const nextDueHours = lastHours + schedule.hoursInterval;
        const currentHours = equipment.Hours || 0;
        remainingValues.hours = nextDueHours - currentHours;
      }
      
      if (schedule.odometerInterval) {
        const lastOdometer = lastReading?.odometer ?? 0;
        const nextDueOdometer = lastOdometer + schedule.odometerInterval;
        const currentOdometer = equipment.Odometer || 0;
        remainingValues.odometer = nextDueOdometer - currentOdometer;
      }

      backgroundColor = getStatusColor(schedule, remainingValues);
    }

    return (
      <div 
        className="maintenance-item"
        style={{ backgroundColor }}
        onClick={() => handleServiceClick(schedule)}
        role="button"
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleServiceClick(schedule);
          }
        }}
      >
        <div className="maintenance-description">
          {schedule.enabled ? (
            <>
              <span className="service-text">
                {maintenanceItemName} {parts.join(' or ')}
              </span>
              {schedule.notes && (
                <span className="service-notes"> - {schedule.notes}</span>
              )}
            </>
          ) : (
            <span className="service-disabled">
              {maintenanceItemName} (Disabled)
            </span>
          )}
        </div>
        {lastReading && (
          <div className="previous-service">
            Previous service: {new Date(lastReading.date).toLocaleDateString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: '2-digit'
            })}
            {lastReading.odometer ? 
              ` - ${parseInt(lastReading.odometer, 10).toLocaleString()} Miles` : ''}
            {lastReading.hours > 0 ? 
              ` - ${parseInt(lastReading.hours, 10).toLocaleString()} Hours` : ''}
          </div>
        )}
        <div className="next-due">
          Next due: {getNextDueText(schedule)}
        </div>
      </div>
    );
  };

  const getNextDueText = (schedule) => {
    const lastReading = lastMaintenanceReadings[schedule.type];
    const parts = [];
    
    if (schedule.dateInterval) {
      if (!lastReading) {
        return 'No previous service recorded';
      }

      const lastServiceDate = new Date(lastReading.date);
      const nextDueDate = new Date(lastServiceDate);
      nextDueDate.setDate(nextDueDate.getDate() + schedule.dateInterval);
      
      const today = new Date();
      const remainingDays = Math.ceil((nextDueDate - today) / (1000 * 60 * 60 * 24));

      if (remainingDays > 0) {
        parts.push(`${remainingDays} days remaining`);
      } else {
        parts.push(`Overdue by ${Math.abs(remainingDays)} days`);
      }
    }
    
    if (schedule.odometerInterval) {
      const lastOdometer = lastReading?.odometer ?? 0;
      const nextDueOdometer = lastOdometer + schedule.odometerInterval;
      const currentOdometer = equipment.Odometer || 0;
      const remainingMiles = nextDueOdometer - currentOdometer;

      if (remainingMiles > 0) {
        parts.push(`${remainingMiles.toLocaleString()} miles remaining`);
      } else {
        parts.push(`Overdue by ${Math.abs(remainingMiles).toLocaleString()} miles`);
      }
    }
    
    if (schedule.hoursInterval) {
      const lastHours = lastReading?.hours ?? 0;
      const nextDueHours = lastHours + schedule.hoursInterval;
      const currentHours = equipment.Hours || 0;
      const remainingHours = nextDueHours - currentHours;
      
      if (remainingHours > 0) {
        parts.push(`${remainingHours.toLocaleString()} hours remaining`);
      } else {
        parts.push(`Overdue by ${Math.abs(remainingHours).toLocaleString()} hours`);
      }
    }

    return parts.join(' or ') || 'Not scheduled';
  };

  return (
    <div className="maintenance-schedule-section">
      <div className="section-header">
        <h3>{equipment.ID} Maintenance Schedule</h3>
        {canEdit && (
          <button 
            className="edit-button"
            onClick={onToggleEdit}
            aria-label={isEditing ? "Save maintenance schedule" : "Edit maintenance schedule"}
          >
            {isEditing ? (
              <>
                <FiSave className="icon" />
                <span>Save</span>
              </>
            ) : (
              <FiEdit className="icon" />
            )}
          </button>
        )}
      </div>

      {isEditing && (
        <button 
          className="cancel-button"
          onClick={onToggleEdit}
          aria-label="Cancel editing maintenance schedule"
        >
          <FiX className="icon" />
          <span>Cancel</span>
        </button>
      )}

      {isEditing ? (
        <div className="maintenance-schedule-display">
          {(equipment.maintenanceSchedule || [])
            .sort((a, b) => (a.enabled === b.enabled ? 0 : a.enabled ? -1 : 1))
            .map((schedule, index) => (
              <div key={index} className="schedule-item">
                {renderIntervalForm(schedule, index)}
              </div>
            ))}
        </div>
      ) : (
        <div className="maintenance-schedule-display">
          {(equipment.maintenanceSchedule || [])
            .sort((a, b) => (a.enabled === b.enabled ? 0 : a.enabled ? -1 : 1))
            .map((schedule, index) => (
              <div key={index} className="schedule-item">
                {renderReadableInterval(schedule)}
              </div>
            ))}
        </div>
      )}

      {isEditing && !showAddForm && (
        <button 
          onClick={() => setShowAddForm(true)}
          className="add-interval-button"
        >
          <FiPlus /> Add Maintenance Interval
        </button>
      )}

      {showAddForm && renderIntervalForm(newInterval, -1, true)}

      {showServiceHistoryModal && (
        <MaintenanceServiceHistoryModal
          equipment={equipment}
          maintenanceItem={selectedService}
          onClose={() => {
            setShowServiceHistoryModal(false);
            setSelectedService(null);
          }}
          onUpdate={() => {
            fetchLastMaintenanceReadings();
          }}
        />
      )}
    </div>
  );
}

export default MaintenanceScheduleSection; 