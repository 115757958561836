import React, { useState, useEffect } from 'react';
import { HiOutlineBeaker, HiOutlineFilter } from 'react-icons/hi';
import { BsGearFill, BsWrench } from 'react-icons/bs';
import { FaOilCan } from 'react-icons/fa';
import { MdOutlineChecklist } from 'react-icons/md';
import PartSelectionSection from './PartSelectionSection';
import { 
  getMaintenanceItems, 
  getEquipmentMaintenanceItems 
} from '../services/maintenanceItemService';
import '../styles/MaintenanceItemModal.css';

const MaintenanceItemSelectionModal = ({ onClose, onSelect, equipment, existingItems = [] }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [maintenanceItems, setMaintenanceItems] = useState([]);
  const [equipmentMaintenanceConfigs, setEquipmentMaintenanceConfigs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPartsState, setSelectedPartsState] = useState([]);

  useEffect(() => {
    console.log('Equipment prop:', equipment);
    fetchItems();
  }, [equipment?._id]);

  useEffect(() => {
    // Add class to body when modal opens
    document.body.classList.add('maintenance-item-modal-open');
    
    // Remove class when modal closes
    return () => {
      document.body.classList.remove('maintenance-item-modal-open');
    };
  }, []);

  useEffect(() => {
    if (existingItems.length > 0) {
      setSelectedPartsState(existingItems);
    }
  }, [existingItems]);

  const fetchItems = async () => {
    try {
      setLoading(true);
      console.log('Fetching maintenance items and equipment configs...');
      
      // Get maintenance items first
      const items = await getMaintenanceItems(true);
      console.log('Fetched maintenance items:', items);
      
      // Get equipment configs (this might be empty for new items)
      const equipmentConfigs = await getEquipmentMaintenanceItems(equipment._id);
      console.log('Fetched equipment configs:', equipmentConfigs);
      
      // Map equipment configs for easy lookup
      const configMap = new Map(
        equipmentConfigs.map(config => [config.maintenanceItem._id, config])
      );
      
      const filteredItems = items.filter(item => item.active);
      setMaintenanceItems(filteredItems);
      setEquipmentMaintenanceConfigs(configMap);
      setError(null);
    } catch (err) {
      console.error('Error in fetchItems:', err);
      // Still set maintenance items if we got them, even if equipment configs failed
      if (err.response?.status === 404) {
        setMaintenanceItems(await getMaintenanceItems(true));
        setEquipmentMaintenanceConfigs(new Map());
        setError(null);
      } else {
        setError('Failed to load maintenance items');
      }
    } finally {
      setLoading(false);
    }
  };

  const getCategoryIcon = (category) => {
    switch (category) {
      case 'Fluid': return HiOutlineBeaker;
      case 'Filter': return HiOutlineFilter;
      case 'Part': return BsGearFill;
      case 'Service': return BsWrench;
      default: return BsGearFill;
    }
  };

  const handleItemSelect = async (item) => {
    if (item.requiresParts) {
      setSelectedItem(item);
      return;
    }

    const equipmentConfig = equipmentMaintenanceConfigs.get(item._id);
    
    if (item.type === 'Group') {
      // For group items (PM services), include all the individual items
      const includedItems = item.includedItems.map(included => ({
        maintenanceItem: included.maintenanceItem,
        quantity: included.quantity,
        config: equipmentMaintenanceConfigs.get(included.maintenanceItem._id)
      }));

      onSelect({
        type: 'group',
        maintenanceItem: item,
        includedItems,
        equipmentConfig
      });
    } else {
      onSelect({
        type: 'single',
        maintenanceItem: item,
        equipmentConfig
      });
    }
    
    onClose();
  };

  const handleBack = () => {
    if (selectedItem) {
      setSelectedItem(null);
    } else {
      setSelectedCategory(null);
    }
  };

  const handlePartSelect = (data) => {
    if (data.type === 'part' && data.parts) {
      setSelectedPartsState(data.parts);
    }
  };

  const handleSaveConfiguration = () => {
    if (selectedItem && selectedPartsState.length > 0) {
      onSelect({
        type: 'part',
        maintenanceItem: selectedItem,
        parts: selectedPartsState,
        details: {
          notes: ''
        }
      });
      onClose();
    }
  };

  if (loading) {
    return <div className="modal-overlay">
      <div className="maintenance-item-modal">
        <h2>Loading maintenance items...</h2>
      </div>
    </div>;
  }

  if (error) {
    return <div className="modal-overlay">
      <div className="maintenance-item-modal">
        <h2>Error loading maintenance items</h2>
        <p>{error}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>;
  }

  const groupedItems = maintenanceItems.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = [];
    }
    acc[item.category].push(item);
    return acc;
  }, {});

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="maintenance-item-modal" onClick={e => e.stopPropagation()}>
        <h2>Add Maintenance Item</h2>
        
        <div className="modal-content-wrapper">
          {selectedItem ? (
            <PartSelectionSection
              category={selectedItem.category}
              items={[selectedItem]}
              onSelect={handlePartSelect}
              onBack={handleBack}
              onClose={onClose}
              equipment={equipment}
              backButtonText="Back to Filters"
              buttonPosition="left"
              existingItems={existingItems}
            />
          ) : !selectedCategory ? (
            <div className="category-grid">
              {Object.entries(groupedItems).map(([category, items]) => {
                if (items.length === 0) return null;
                
                const Icon = getCategoryIcon(category);
                return (
                  <button
                    key={category}
                    className="category-button"
                    onClick={() => setSelectedCategory(category)}
                  >
                    <Icon size={24} />
                    <span>{category}</span>
                  </button>
                );
              })}
            </div>
          ) : (
            <div className="items-section">
              <button onClick={handleBack} className="back-button">
                ← Back to Categories
              </button>
              
              <div className="items-list">
                {groupedItems[selectedCategory]?.map(item => (
                  <div 
                    key={item._id} 
                    className="maintenance-item"
                    onClick={() => handleItemSelect(item)}
                  >
                    <h3>{item.name}</h3>
                    {item.description && <p>{item.description}</p>}
                    {item.type === 'Group' && (
                      <div className="included-items">
                        <small>Includes:</small>
                        <ul>
                          {item.includedItems.map((included, index) => (
                            <li key={index}>
                              {included.maintenanceItem.name} 
                              {included.quantity > 1 && ` (x${included.quantity})`}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="modal-actions">
          <button onClick={onClose} className="cancel-button">Cancel</button>
          {selectedItem && selectedPartsState.length > 0 && (
            <button 
              onClick={handleSaveConfiguration} 
              className="save-button"
            >
              Add Item(s) to Order
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MaintenanceItemSelectionModal; 