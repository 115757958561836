import React, { useState } from 'react';
import { FiCheck, FiX, FiArrowDown, FiChevronsUp, FiChevronsDown } from 'react-icons/fi';
import '../styles/WorkOrderSortModal.css';

function WorkOrderSortModal({ onApply, onClose, currentSort }) {
  
    const [sortConfig, setSortConfig] = useState(currentSort || {
        field: 'dateScheduled',
        direction: 'asc',
        emptyPosition: 'bottom'
      });
    
      const sortOptions = [
        { value: 'dateCreated', label: 'Creation Date' },
        { value: 'dateRequested', label: 'Requested Date' },
        { value: 'dateCompleted', label: 'Completion Date' },
        { value: 'dateScheduled', label: 'Scheduled Date' }
      ];
    
      const handleChange = (field, value) => {
        setSortConfig(prev => ({ ...prev, [field]: value }));
      };
    
      const toggleDirection = () => {
        setSortConfig(prev => ({
          ...prev,
          direction: prev.direction === 'desc' ? 'asc' : 'desc'
        }));
      };
    
      const toggleEmptyPosition = () => {
        setSortConfig(prev => ({
          ...prev,
          emptyPosition: prev.emptyPosition === 'bottom' ? 'top' : 'bottom'
        }));
      };
    
      const defaultSort = {
        field: 'dateScheduled',
        direction: 'asc',
        emptyPosition: 'bottom'
      };
    
      const handleReset = () => {
        setSortConfig(defaultSort);
      };
    
      return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[12000]">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Sort Work Orders</h2>
            
            {/* Sort controls */}
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Sort By:</label>
                <select 
                  className="w-full border border-gray-300 rounded-md p-2"
                  value={sortConfig.field} 
                  onChange={(e) => handleChange('field', e.target.value)}
                >
                  {sortOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
    
              <div className="sort-options">
                <button 
                  className="toggle-button"
                  onClick={toggleDirection}
                >
                  <FiArrowDown /> {sortConfig.direction === 'desc' ? 'New to Old' : 'Old to New'}
                </button>
    
                {sortConfig.field !== 'dateCreated' && (
                  <button 
                    className="toggle-button"
                    onClick={toggleEmptyPosition}
                  >
                    {sortConfig.emptyPosition === 'bottom' ? (
                      <>
                        <FiChevronsDown /> Empty
                      </>
                    ) : (
                      <>
                        <FiChevronsUp /> Empty
                      </>
                    )}
                  </button>
                )}
              </div>
            </div>

            {/* Updated Action Buttons - using flex with justify-between */}
            <div className="flex justify-between items-center mt-6">
              {/* Cancel button on the left */}
              <button
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
                onClick={onClose}
              >
                Cancel
              </button>

              {/* Reset and Apply buttons grouped on the right */}
              <div className="flex gap-2">
                <button
                  className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
                  onClick={handleReset}
                >
                  Reset
                </button>
                <button
                  className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition-colors"
                  onClick={() => onApply(sortConfig)}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      );
}

export default WorkOrderSortModal; 