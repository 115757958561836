import React, { useState } from 'react';
import '../../../styles/Modal.css';

function AddEquipmentTypeModal({ onSubmit, onClose }) {
  const [typeName, setTypeName] = useState('');
  const [typeDescription, setTypeDescription] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ name: typeName, description: typeDescription, categories: [] });
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Add Equipment Type</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="typeName">Type:</label>
            <input
              type="text"
              id="typeName"
              value={typeName}
              onChange={(e) => setTypeName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="typeDescription">Description:</label>
            <textarea
              id="typeDescription"
              value={typeDescription}
              onChange={(e) => setTypeDescription(e.target.value)}
              rows="3"
            />
          </div>
          <div className="form-actions">
            <button type="submit">Add</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddEquipmentTypeModal;
