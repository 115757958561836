import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { FiGrid, FiFileText, FiClipboard, FiFilePlus, FiFilter } from 'react-icons/fi';
import { PiGauge } from "react-icons/pi";
import WorkOrders from './WorkOrders';
import AssignedOrders from './AssignedOrders';
import UpdateMeters from './UpdateMeters';
import Equipment from './Equipment';
import Parts from './Parts';
import WorkOrderForm from './WorkOrderForm';
import { checkAbilityPermissions } from '../services/authService';
import { getEquipment, updateEquipment } from '../services/equipmentService';
import { addMeterReading } from '../services/meterReadingService';
import '../styles/Dashboard.css';
import axiosInstance from '../services/api';
import MaintenanceScheduleSection from './MaintenanceScheduleSection';
import { toast } from 'react-toastify';
import NavigationBar from './NavigationBar';
import AdminPanel from './Admin';

function Dashboard({ activeTab, handleTabChange }) {
  const { user } = useAuth();
  const [canViewAllWorkOrders, setCanViewAllWorkOrders] = useState(false);
  const [canBeAssigned, setCanBeAssigned] = useState(false);
  const [canUpdateMeters, setCanUpdateMeters] = useState(false);
  const [canViewDriverOptions, setCanViewDriverOptions] = useState(false);
  const [showWorkOrderForm, setShowWorkOrderForm] = useState(false);
  const [workOrderStats, setWorkOrderStats] = useState({
    openCount: 0,
    recentlyClosedCount: 0
  });
  const [workOrderFilters, setWorkOrderFilters] = useState(null);
  
  // New state for driver options
  const [trucks, setTrucks] = useState([]);
  const [trailers, setTrailers] = useState([]);
  const [selectedTruck, setSelectedTruck] = useState('');
  const [selectedTrailer, setSelectedTrailer] = useState('');
  const [meterReadings, setMeterReadings] = useState({
    truckHours: '',
    truckOdometer: '',
    trailerHours: '',
    trailerOdometer: ''
  });

  // Add new state for last readings
  const [lastReadings, setLastReadings] = useState({
    truckHours: '',
    truckOdometer: '',
    trailerHours: '',
    trailerOdometer: ''
  });

  // Add new state for admin check
  const [isAdmin, setIsAdmin] = useState(false);

  // Add function to fetch last readings
  const fetchLastReadings = async (equipmentId) => {
    try {
      const response = await axiosInstance.get(`/equipment/${equipmentId}/last-readings`);
      if (response.data.success) {
        return {
          hours: response.data.lastValidHours,
          odometer: response.data.lastValidOdometer
        };
      }
      return { hours: 0, odometer: 0 };
    } catch (error) {
      console.error('Error fetching last readings:', error);
      return { hours: 0, odometer: 0 };
    }
  };

  const [canViewEquipment, setCanViewEquipment] = useState(false);
  const [canViewParts, setCanViewParts] = useState(false);

  useEffect(() => {
    const initializeDashboard = async () => {
      if (!user) return;

      try {
        // Check all required permissions at once
        const [viewAll, assigned, meters, driver, equipment, parts, admin] = await Promise.all([
          checkAbilityPermissions('CanViewAllWorkOrders'),
          checkAbilityPermissions('CanBeAssignedWorkOrders'),
          checkAbilityPermissions('CanUpdateEquipmentMeters'),
          checkAbilityPermissions('CanViewDriverOptions'),
          checkAbilityPermissions('CanViewEquipment'),
          checkAbilityPermissions('CanViewParts'),
          checkAbilityPermissions('IsAdmin')
        ]);
        
        // Set all permissions states
        setCanViewAllWorkOrders(viewAll);
        setCanBeAssigned(assigned);
        setCanUpdateMeters(meters);
        setCanViewDriverOptions(driver);
        setCanViewEquipment(equipment);
        setCanViewParts(parts);
        setIsAdmin(admin);

        // Fetch work order stats
        try {
          const response = await axiosInstance.get('/workorders/stats', {
            params: { userId: user.id }
          });
          setWorkOrderStats({
            openCount: response.data.openCount || 0,
            recentlyClosedCount: response.data.recentlyClosedCount || 0
          });
        } catch (error) {
          console.error('Error fetching work order stats:', error);
        }

        if (driver) {
          try {
            // First fetch user data
            const userResponse = await axiosInstance.get(`/users/${user.id}`);
            console.log('Fetched user data:', userResponse.data);
            
            // Then fetch equipment
            const equipment = await getEquipment();
            console.log('Fetched equipment:', equipment);
            
            if (equipment && Array.isArray(equipment)) {
              const trucks = equipment.filter(eq => eq.Type?.name === 'Vehicle');
              const trailers = equipment.filter(eq => eq.Type?.name === 'Trailer');
              
              setTrucks(trucks);
              setTrailers(trailers);

              // Get the saved preferences from the user data
              const savedVehicle = userResponse.data.data.drivesVehicle;
              const savedTrailer = userResponse.data.data.drivesTrailer;

              console.log('Saved vehicle ID:', savedVehicle?._id || savedVehicle);
              console.log('Saved trailer ID:', savedTrailer?._id || savedTrailer);

              // Set the selections
              if (savedVehicle) {
                setSelectedTruck(savedVehicle._id || savedVehicle);
                const truckReadings = await fetchLastReadings(savedVehicle._id || savedVehicle);
                setLastReadings(prev => ({
                  ...prev,
                  truckHours: truckReadings.hours,
                  truckOdometer: truckReadings.odometer
                }));
              }
              if (savedTrailer) {
                setSelectedTrailer(savedTrailer._id || savedTrailer);
                const trailerReadings = await fetchLastReadings(savedTrailer._id || savedTrailer);
                setLastReadings(prev => ({
                  ...prev,
                  trailerHours: trailerReadings.hours,
                  trailerOdometer: trailerReadings.odometer
                }));
              }
            }
          } catch (error) {
            console.error('Error in initialization:', error);
          }
        }
      } catch (error) {
        console.error('Error checking permissions:', error);
      }
    };

    initializeDashboard();
  }, [user]);

  const handleMeterUpdate = async () => {
    try {
      // Update truck readings if a truck is selected
      if (selectedTruck) {
        const truckReadingData = {
          type: 'Meter Check',
          readings: {
            odometer: meterReadings.truckOdometer ? Number(meterReadings.truckOdometer) : null,
            hours: meterReadings.truckHours ? Number(meterReadings.truckHours) : null
          },
          notes: 'Updated by driver',
          maintenanceDetails: {
            fluidsAndFilters: [],
            completedChecklist: []
          }
        };

        await addMeterReading(selectedTruck, truckReadingData);
      }

      // Update trailer readings if a trailer is selected
      if (selectedTrailer) {
        const trailerReadingData = {
          type: 'Meter Check',
          readings: {
            odometer: meterReadings.trailerOdometer ? Number(meterReadings.trailerOdometer) : null,
            hours: meterReadings.trailerHours ? Number(meterReadings.trailerHours) : null
          },
          notes: 'Updated by driver',
          maintenanceDetails: {
            fluidsAndFilters: [],
            completedChecklist: []
          }
        };

        await addMeterReading(selectedTrailer, trailerReadingData);
      }

      // After successful update, fetch the latest readings
      if (selectedTruck) {
        const newTruckReadings = await fetchLastReadings(selectedTruck);
        setLastReadings(prev => ({
          ...prev,
          truckHours: newTruckReadings.hours,
          truckOdometer: newTruckReadings.odometer
        }));
      }

      if (selectedTrailer) {
        const newTrailerReadings = await fetchLastReadings(selectedTrailer);
        setLastReadings(prev => ({
          ...prev,
          trailerHours: newTrailerReadings.hours,
          trailerOdometer: newTrailerReadings.odometer
        }));
      }

      // Reset input fields after successful update
      setMeterReadings({
        truckHours: '',
        truckOdometer: '',
        trailerHours: '',
        trailerOdometer: ''
      });

      // Show success toast
      toast.success('Meters Updated, Thank you!', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    } catch (error) {
      console.error('Error updating meters:', error);
      // Show error toast
      toast.error('Failed to update meters. Please try again.', {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const handleWorkOrderAdded = () => {
    setShowWorkOrderForm(false);
    handleTabChange('myWorkOrders');
  };

  const handleViewWorkOrders = () => {
    const params = {
      status: 'All',
      location: 'All Locations',
      type: 'All Types',
      category: 'All',
      equipment: 'All',
      author: user.id
    };

    const sortConfig = {
      field: 'dateCreated',
      direction: 'desc',
      emptyPosition: 'bottom'
    };

    setWorkOrderFilters(params);
    handleTabChange('myWorkOrders');

    return <WorkOrders 
      initialFilters={params}
      sortConfig={sortConfig}
    />;
  };

  const updateUserEquipment = async (vehicleId, trailerId) => {
    try {
      // Create payload object
      const payload = {
        drivesVehicle: vehicleId || null
      };

      // Only include drivesTrailer if a trailer is selected
      if (trailerId) {
        payload.drivesTrailer = trailerId;
      } else {
        // Explicitly set to null to remove the trailer assignment
        payload.drivesTrailer = null;
      }

      await axiosInstance.put(`/users/${user.id}/equipment-preferences`, payload);
    } catch (error) {
      console.error('Error updating equipment preferences:', error);
    }
  };

  const handleTruckChange = async (e) => {
    const newTruckId = e.target.value;
    setSelectedTruck(newTruckId);
    
    if (newTruckId) {
      const lastReadings = await fetchLastReadings(newTruckId);
      setLastReadings(prev => ({
        ...prev,
        truckHours: lastReadings.hours,
        truckOdometer: lastReadings.odometer
      }));
    }
    
    await updateUserEquipment(newTruckId, selectedTrailer);
  };

  const handleTrailerChange = async (e) => {
    const newTrailerId = e.target.value;
    setSelectedTrailer(newTrailerId);
    
    if (newTrailerId) {
      const lastReadings = await fetchLastReadings(newTrailerId);
      setLastReadings(prev => ({
        ...prev,
        trailerHours: lastReadings.hours,
        trailerOdometer: lastReadings.odometer
      }));
    }
    
    await updateUserEquipment(selectedTruck, newTrailerId);
  };

  const handleUpdate = async (id, updates) => {
    try {
      const updatedEquipment = await updateEquipment(id, updates);
      // Refresh equipment data after update
      const equipment = await getEquipment();
      if (equipment && Array.isArray(equipment)) {
        setTrucks(equipment.filter(eq => eq.Type?.name === 'Vehicle'));
        setTrailers(equipment.filter(eq => eq.Type?.name === 'Trailer'));
      }
    } catch (error) {
      console.error('Failed to update equipment:', error);
    }
  };

  const renderDriverSection = () => {
    if (!canViewDriverOptions) return null;

    console.log('Rendering driver section with:');
    console.log('- Selected truck:', selectedTruck);
    console.log('- Selected trailer:', selectedTrailer);
    console.log('- Available trucks:', trucks);
    console.log('- Available trailers:', trailers);

    // Find both selected equipment data
    const selectedTruckData = trucks.find(t => t._id === selectedTruck);
    const selectedTrailerData = trailers.find(t => t._id === selectedTrailer);

    return (
      <div className="driver-section">
        <h3 className="driver-section-title">What are you driving today?</h3>
        <div className="equipment-selection">
          <div className="select-group">
            <label>Select Truck:</label>
            <select 
              value={selectedTruck || ''} 
              onChange={handleTruckChange}
              required
            >
              <option value="">Select a truck</option>
              {trucks.map(truck => (
                <option key={truck._id} value={truck._id}>
                  {truck.ID} - {truck.Make} {truck.Model}
                </option>
              ))}
            </select>
          </div>
          
          <div className="select-group">
            <label>Select Trailer:</label>
            <select 
              value={selectedTrailer || ''} 
              onChange={handleTrailerChange}
            >
              <option value="">No trailer</option>
              {trailers.map(trailer => (
                <option key={trailer._id} value={trailer._id}>
                  {trailer.ID}
                </option>
              ))}
            </select>
          </div>
        </div>

        {(selectedTruckData || selectedTrailerData) && (
          <div className="meter-readings">
            {selectedTruckData && (
              <div className="equipment-readings">
                <h4></h4>
                {!selectedTruckData.ignoreOdometer && (
                  <div className="reading-input">
                    <label>Truck Odometer:</label>
                    <input
                      type="number"
                      value={meterReadings.truckOdometer}
                      onChange={(e) => setMeterReadings(prev => ({
                        ...prev,
                        truckOdometer: e.target.value
                      }))}
                      placeholder={lastReadings.truckOdometer ? `Last: ${lastReadings.truckOdometer}` : 'Enter odometer'}
                    />
                  </div>
                )}
                {!selectedTruckData.ignoreHours && (
                  <div className="reading-input">
                    <label>Truck Hours:</label>
                    <input
                      type="number"
                      value={meterReadings.truckHours}
                      onChange={(e) => setMeterReadings(prev => ({
                        ...prev,
                        truckHours: e.target.value
                      }))}
                      placeholder={lastReadings.truckHours ? `Last: ${lastReadings.truckHours}` : 'Enter hours'}
                    />
                  </div>
                )}
              </div>
            )}

            {selectedTrailerData && (
              <div className="equipment-readings">
                <h4></h4>
                {!selectedTrailerData.ignoreOdometer && (
                  <div className="reading-input">
                    <label>Trailer Odometer:</label>
                    <input
                      type="number"
                      value={meterReadings.trailerOdometer}
                      onChange={(e) => setMeterReadings(prev => ({
                        ...prev,
                        trailerOdometer: e.target.value
                      }))}
                      placeholder={lastReadings.trailerOdometer ? `Last: ${lastReadings.trailerOdometer}` : 'Enter odometer'}
                    />
                  </div>
                )}
                {!selectedTrailerData.ignoreHours && (
                  <div className="reading-input">
                    <label>Trailer Hours:</label>
                    <input
                      type="number"
                      value={meterReadings.trailerHours}
                      onChange={(e) => setMeterReadings(prev => ({
                        ...prev,
                        trailerHours: e.target.value
                      }))}
                      placeholder={lastReadings.trailerHours ? `Last: ${lastReadings.trailerHours}` : 'Enter hours'}
                    />
                  </div>
                )}
              </div>
            )}

            <button 
              className="w-full flex justify-center items-center py-2 px-4 bg-btn-submit 
                text-white rounded hover:bg-btn-submit-hover transition-colors duration-200
                disabled:opacity-50 disabled:cursor-not-allowed text-center"
              onClick={handleMeterUpdate}
              disabled={!selectedTruck && !selectedTrailer}
            >
              <span className="mx-auto">Update Readings</span>
            </button>
          </div>
        )}

        {selectedTruckData && (
          <div className="maintenance-schedule-container">
            <MaintenanceScheduleSection 
              equipment={selectedTruckData}
              onUpdate={(updates) => handleUpdate(selectedTruck, updates)}
              isEditing={false}
            />
          </div>
        )}

        {selectedTrailerData && (
          <div className="maintenance-schedule-container">
            <MaintenanceScheduleSection 
              equipment={selectedTrailerData}
              onUpdate={(updates) => handleUpdate(selectedTrailer, updates)}
              isEditing={false}
            />
          </div>
        )}
      </div>
    );
  };

  const renderTabContent = () => {
    if (!user) return null;

    switch (activeTab) {
      case 'welcome':
        return (
          <div className="welcome-message">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Welcome, {user.firstname}!
            </h2>
            
            {/* Inline text with icon */}
            <p className="flex items-center gap-1 mb-2">
              Click
              <FiFilePlus 
                className="w-8 h-8 text-green-500 hover:text-green-600 cursor-pointer inline-block" 
                onClick={() => setShowWorkOrderForm(true)}
              />
              to create a new work order.
            </p>

            <p className="text-gray-600 mb-4">
              You have {workOrderStats.openCount} open work order{workOrderStats.openCount !== 1 ? 's' : ''} and{' '}
              {workOrderStats.recentlyClosedCount} recently closed work order{workOrderStats.recentlyClosedCount !== 1 ? 's' : ''}.
            </p>

            {/* Inline text with icon */}
            <p className="flex items-center gap-1 mb-4">
              Click
              <FiFileText 
                className="w-8 h-8 text-blue-500 hover:text-blue-600 cursor-pointer inline-block"
                onClick={handleViewWorkOrders}
              />
              to view your work orders.
            </p>

            {renderDriverSection()}
          </div>
        );
      case 'myWorkOrders':
        return <WorkOrders 
          initialFilters={workOrderFilters} 
          sortConfig={{ 
            field: 'dateCreated',  // Force this specific sort
            direction: 'desc',
            emptyPosition: 'bottom'
          }}
        />;
      case 'assignedOrders':
        return <AssignedOrders />;
      case 'updateMeters':
        return <UpdateMeters />;
      case 'equipment':
        return <Equipment />;
      case 'parts':
        return <Parts />;
      case 'admin':
        return <AdminPanel />;
      default:
        return <div>Invalid tab</div>;
    }
  };

  return (
    <div className="relative h-[calc(100vh-40px)] mt-10 overflow-hidden">
      <NavigationBar 
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        canViewAllWorkOrders={canViewAllWorkOrders}
        canUpdateMeters={canUpdateMeters}
        canBeAssigned={canBeAssigned}
        canViewEquipment={canViewEquipment}
        canViewParts={canViewParts}
        isAdmin={isAdmin}
      />
      <div className="relative top-[50px] px-2 sm:p-4 pt-1 h-[calc(100vh-140px)] overflow-y-auto">
        {renderTabContent()}
      </div>

      {showWorkOrderForm && (
        <WorkOrderForm 
          onClose={() => setShowWorkOrderForm(false)}
          onWorkOrderAdded={handleWorkOrderAdded}
        />
      )}
    </div>
  );
}

export default Dashboard;
