import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

const EmailUpdate = () => {
    const [newEmail, setNewEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isTokenValid, setIsTokenValid] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        
        if (!token) {
            toast.error('Invalid or missing token');
            navigate('/profile');
            return;
        }

        // Verify token validity
        const verifyToken = async () => {
            try {
                const response = await axios.get(`/api/auth/verify-email-token?token=${token}`);
                setIsTokenValid(response.data.success);
                if (!response.data.success) {
                    toast.error('Invalid or expired token');
                    navigate('/profile');
                }
            } catch (error) {
                console.error('Error verifying token:', error);
                toast.error('Invalid or expired token');
                navigate('/profile');
            }
        };

        verifyToken();
    }, [navigate, location]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!newEmail) {
            toast.error('Please enter a new email address');
            return;
        }

        try {
            setIsLoading(true);
            const queryParams = new URLSearchParams(location.search);
            const token = queryParams.get('token');

            const response = await axios.post('/api/auth/update-email', {
                token,
                newEmail
            });

            if (response.data.success) {
                toast.success('Email updated successfully!');
                navigate('/profile');
            }
        } catch (error) {
            console.error('Error updating email:', error);
            toast.error(error.response?.data?.message || 'Failed to update email');
        } finally {
            setIsLoading(false);
        }
    };

    if (!isTokenValid) {
        return <div>Verifying token...</div>;
    }

    return (
        <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
            <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                <div className="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
                    <div className="max-w-md mx-auto">
                        <div className="divide-y divide-gray-200">
                            <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                <h2 className="text-2xl font-bold mb-8">Update Email Address</h2>
                                <form onSubmit={handleSubmit} className="space-y-6">
                                    <div>
                                        <label htmlFor="newEmail" className="block text-sm font-medium text-gray-700">
                                            New Email Address
                                        </label>
                                        <input
                                            type="email"
                                            id="newEmail"
                                            value={newEmail}
                                            onChange={(e) => setNewEmail(e.target.value)}
                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                            required
                                        />
                                    </div>
                                    <button
                                        type="submit"
                                        disabled={isLoading}
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                    >
                                        {isLoading ? 'Updating...' : 'Update Email'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailUpdate;
