import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { updateUserProfile, changeOwnPassword } from '../services/userService';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import EmailUpdateButton from './EmailUpdateButton';

function Profile() {
  const { user, login } = useAuth();
  const [profileData, setProfileData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    cellPhone: '',
    displayName: ''
  });
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [username, setUsername] = useState('');

  // Section collapse states
  const [sectionsOpen, setSectionsOpen] = useState({
    accountInfo: false,
    authentication: false,
    profilePicture: false
  });

  // Toggle section function
  const toggleSection = (section) => {
    setSectionsOpen(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  useEffect(() => {
    if (user) {
      setProfileData({
        firstname: user.firstname || '',
        lastname: user.lastname || '',
        email: user.email || '',
        cellPhone: user.cellPhone || '',
        displayName: user.displayName || ''
      });
      setUsername(user.username || '');
    }
  }, [user]);

  const handleProfileChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      const updatedUser = await updateUserProfile(user.id, profileData);
      login(updatedUser);
      setSuccess('Profile updated successfully');
    } catch (err) {
      setError('Failed to update profile');
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setError('New passwords do not match');
      return;
    }
    try {
      await changeOwnPassword(passwordData.currentPassword, passwordData.newPassword);
      setSuccess('Password changed successfully');
      setPasswordData({ currentPassword: '', newPassword: '', confirmPassword: '' });
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to change password');
    }
  };

  const SectionHeader = ({ title, isOpen, onClick }) => (
    <div 
      onClick={onClick}
      className="flex justify-between items-center cursor-pointer py-4 px-6 bg-gray-50 hover:bg-gray-100 transition-colors duration-200 rounded-t-lg"
    >
      <h3 className="text-xl font-semibold text-gray-700">{title}</h3>
      {isOpen ? (
        <FiChevronUp className="w-6 h-6 text-gray-500" />
      ) : (
        <FiChevronDown className="w-6 h-6 text-gray-500" />
      )}
    </div>
  );

  return (
    <div className="max-w-4xl mx-auto p-4 space-y-6 mt-[80px]">
      <h2 className="text-2xl font-bold text-gray-800">Profile</h2>
      {error && <p className="p-3 bg-red-100 text-red-700 rounded-md">{error}</p>}
      {success && <p className="p-3 bg-green-100 text-green-700 rounded-md">{success}</p>}
      
      <div className="space-y-6">
        {/* Account Information Section */}
        <div className="bg-white rounded-lg shadow-sm">
          <SectionHeader 
            title="Account Information"
            isOpen={sectionsOpen.accountInfo}
            onClick={() => toggleSection('accountInfo')}
          />
          <div className={`transition-all duration-200 ease-in-out ${
            sectionsOpen.accountInfo ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
          }`}>
            <form onSubmit={handleProfileSubmit} className="p-6 space-y-4">
              <div className="space-y-2">
                <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">
                  First Name
                </label>
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  value={profileData.firstname}
                  onChange={handleProfileChange}
                  required
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-transparent"
                />
              </div>
              <div className="space-y-2">
                <label htmlFor="lastname" className="block text-sm font-medium text-gray-700">
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  value={profileData.lastname}
                  onChange={handleProfileChange}
                  required
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-transparent"
                />
              </div>
              <div className="space-y-2">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <div className="mt-1 flex">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={profileData.email}
                    readOnly
                    className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-50"
                  />
                  <EmailUpdateButton currentEmail={profileData.email} />
                </div>
              </div>
              <div className="space-y-2">
                <label htmlFor="cellPhone" className="block text-sm font-medium text-gray-700">
                  Cell Phone
                </label>
                <input
                  type="tel"
                  id="cellPhone"
                  name="cellPhone"
                  value={profileData.cellPhone}
                  onChange={handleProfileChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-transparent"
                />
              </div>
              <div className="space-y-2">
                <label htmlFor="displayName" className="block text-sm font-medium text-gray-700">
                  Display Name
                </label>
                <input
                  type="text"
                  id="displayName"
                  name="displayName"
                  value={profileData.displayName}
                  onChange={handleProfileChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-transparent"
                />
              </div>
              <button 
                type="submit" 
                className="w-full bg-primary text-white py-2 px-4 rounded-md hover:bg-opacity-90 transition-colors duration-200"
              >
                Update Profile
              </button>
            </form>
          </div>
        </div>

        {/* Authentication Section */}
        <div className="bg-white rounded-lg shadow-sm">
          <SectionHeader 
            title="Authentication"
            isOpen={sectionsOpen.authentication}
            onClick={() => toggleSection('authentication')}
          />
          <div className={`transition-all duration-200 ease-in-out ${
            sectionsOpen.authentication ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
          }`}>
            <div className="p-6 space-y-4">
              <div className="mb-6 p-4 bg-gray-50 rounded-md">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Username
                </label>
                <p className="text-gray-900 font-medium">{username}</p>
              </div>
              <form onSubmit={handlePasswordSubmit} className="space-y-4">
                <div className="space-y-2">
                  <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700">
                    Current Password
                  </label>
                  <input
                    type="password"
                    id="currentPassword"
                    name="currentPassword"
                    value={passwordData.currentPassword}
                    onChange={handlePasswordChange}
                    required
                    className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-transparent"
                  />
                </div>
                <div className="space-y-2">
                  <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                    New Password
                  </label>
                  <input
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    value={passwordData.newPassword}
                    onChange={handlePasswordChange}
                    required
                    className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-transparent"
                  />
                </div>
                <div className="space-y-2">
                  <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={passwordData.confirmPassword}
                    onChange={handlePasswordChange}
                    required
                    className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-transparent"
                  />
                </div>
                <button 
                  type="submit" 
                  className="w-full bg-primary text-white py-2 px-4 rounded-md hover:bg-opacity-90 transition-colors duration-200"
                >
                  Change Password
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* Profile Picture Section */}
        <div className="bg-white rounded-lg shadow-sm">
          <SectionHeader 
            title="Profile Picture"
            isOpen={sectionsOpen.profilePicture}
            onClick={() => toggleSection('profilePicture')}
          />
          <div className={`transition-all duration-200 ease-in-out ${
            sectionsOpen.profilePicture ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
          }`}>
            <div className="p-6">
              <p className="text-gray-600">Profile picture upload functionality coming soon...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
