import React, { useState } from 'react';
import Switch from 'react-switch';

function EditRoleForm({ role, onClose, onSave }) {
  const [formData, setFormData] = useState({
    name: role.name,
    allowedPages: role.allowedPages || [],
    abilities: role.abilities || [],
    isDefault: role.isDefault || false,
  });

  const pages = ['Dashboard', 'Work Orders', 'Equipment', 'Parts', 'Reports', 'Admin', 'Profile'];
  const abilities = [
    'CanViewAllWorkOrders',
    'CanCloseAllWorkOrders',
    'CanEditAllWorkOrders',
    'CanDeleteAllWorkOrders',
    'CanCommentOnAllWorkOrders',
    'CanAddEquipment',
    'CanAddParts',
    'CanEditEquipment',
    'CanDeleteEquipment',
    'CanEditParts',
    'CanDeleteParts',
    'CanOrderParts',
    'CanUpdateEquipmentMeters',
    'CanCreateWorkOrderAsOtherUser',
    'CanBeAssignedWorkOrders',
    'CanAssignUsersToWorkOrders',
    'CanViewWorkOrderViewers',
    'CanViewDriverOptions'
  ];

  const handleChange = (name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({ ...role, ...formData });
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-[9999]">
      <div className="bg-white rounded-lg shadow-xl p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-900">Edit Role</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 focus:outline-none"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Role Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={(e) => handleChange('name', e.target.value)}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>

          <div>
            <h3 className="text-lg font-medium text-gray-900 mb-4">Allowed Pages</h3>
            <div className="space-y-3">
              {pages.map(page => (
                <div key={page} className="flex items-center justify-between py-2 px-3 bg-gray-50 rounded-lg">
                  <label className="text-sm font-medium text-gray-700">{page}</label>
                  <Switch
                    onChange={(checked) => {
                      const newAllowedPages = checked
                        ? [...formData.allowedPages, page]
                        : formData.allowedPages.filter(p => p !== page);
                      handleChange('allowedPages', newAllowedPages);
                    }}
                    checked={formData.allowedPages.includes(page)}
                    onColor="#4F46E5"
                    onHandleColor="#ffffff"
                    handleDiameter={24}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
                    activeBoxShadow="0px 0px 1px 8px rgba(99, 102, 241, 0.2)"
                    height={20}
                    width={48}
                    className="react-switch"
                  />
                </div>
              ))}
            </div>
          </div>

          <div>
            <h3 className="text-lg font-medium text-gray-900 mb-4">Abilities</h3>
            <div className="space-y-3">
              {abilities.map(ability => (
                <div key={ability} className="flex items-center justify-between py-2 px-3 bg-gray-50 rounded-lg">
                  <label className="text-sm font-medium text-gray-700">{ability}</label>
                  <Switch
                    onChange={(checked) => {
                      const newAbilities = checked
                        ? [...formData.abilities, ability]
                        : formData.abilities.filter(a => a !== ability);
                      handleChange('abilities', newAbilities);
                    }}
                    checked={formData.abilities.includes(ability)}
                    onColor="#4F46E5"
                    onHandleColor="#ffffff"
                    handleDiameter={24}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
                    activeBoxShadow="0px 0px 1px 8px rgba(99, 102, 241, 0.2)"
                    height={20}
                    width={48}
                    className="react-switch"
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              id="isDefault"
              name="isDefault"
              checked={formData.isDefault}
              onChange={(e) => handleChange('isDefault', e.target.checked)}
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            <label htmlFor="isDefault" className="ml-2 block text-sm text-gray-900">
              Set as Default Role for New Users
            </label>
          </div>

          <div className="flex justify-end space-x-3 pt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditRoleForm;
