import axiosInstance from './api';

// Function to get all equipment
export const getEquipment = async (params = {}) => {
  try {
    console.log('Getting equipment with params:', params);
    const queryParams = new URLSearchParams();
    
    // Handle multiple status parameters
    if (Object.keys(params).some(key => key.startsWith('status'))) {
      // Multiple statuses
      Object.entries(params).forEach(([key, value]) => {
        if (key.startsWith('status')) {
          queryParams.append('status', value);
        }
      });
    } else if (params.status && params.status !== 'All') {
      // Single status
      queryParams.append('status', params.status);
    }
    
    // Handle other filters
    if (params.location) queryParams.append('location', params.location);
    if (params.type) queryParams.append('type', params.type);
    if (params.category) queryParams.append('category', params.category);
    if (params.search) queryParams.append('search', params.search);

    console.log('Making request to:', `/equipment?${queryParams.toString()}`);
    const response = await axiosInstance.get(`/equipment?${queryParams.toString()}`);
    console.log('Equipment response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in getEquipment:', error);
    throw error;
  }
};

// Function to create new equipment
export const createEquipment = async (equipmentData) => {
  const response = await axiosInstance.post('/equipment', equipmentData);
  return response.data;
};

// Function to create work order related to equipment
export const createWorkOrder = async (workOrderData) => {
  try {
    const response = await axiosInstance.post('/workOrders', workOrderData);
    return response.data;
  } catch (error) {
    console.error('Error creating work order:', error);
    throw error;
  }
};

// Function to update equipment
export const updateEquipment = async (id, equipmentData) => {
  try {
    console.log('Updating equipment:', id, 'with data:', equipmentData);
    const response = await axiosInstance.put(`/equipment/${id}`, equipmentData);
    return response.data;
  } catch (error) {
    console.error('Error updating equipment:', error);
    throw error;
  }
};

// Function to delete equipment
export const deleteEquipment = async (id) => {
  try {
    const response = await axiosInstance.delete(`/equipment/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting equipment:', error);
    throw error;
  }
};

// Additional functions (updateEquipment, etc.) can be added similarly

export const getEquipmentTypes = async () => {
  const response = await axiosInstance.get('/equipment/types');
  return response.data;
};

export const getEquipmentCategories = async () => {
  try {
    const response = await axiosInstance.get('/equipment/categories');
    return response.data;
  } catch (error) {
    //console.error('Error fetching equipment categories:', error);
    throw error;
  }
};

export const getEquipmentCategory = async (id) => {
  try {
    const response = await axiosInstance.get(`/equipment/categories/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching equipment category:', error);
    throw error;
  }
};

export const createEquipmentType = async (typeData) => {
  const response = await axiosInstance.post('/equipment/types', typeData);
  return response.data;
};

export const deleteEquipmentType = async (id) => {
  const response = await axiosInstance.delete(`/equipment/types/${id}`);
  return response.data;
};

export const createEquipmentCategory = async (categoryData) => {
  const response = await axiosInstance.post('/equipment/categories', categoryData);
  return response.data;
};

export const updateEquipmentCategory = async (id, categoryData) => {
  const response = await axiosInstance.put(`/equipment/categories/${id}`, categoryData);
  return response.data;
};

export const deleteEquipmentCategory = async (id) => {
  const response = await axiosInstance.delete(`/equipment/categories/${id}`);
  return response.data;
};

export const updateEquipmentType = async (id, typeData) => {
  try {
    const response = await axiosInstance.put(`/equipment/types/${id}`, typeData);
    return response.data;
  } catch (error) {
    console.error('Error updating equipment type:', error);
    throw error;
  }
};

export const updateEquipmentMeters = async (equipmentId, meterData) => {
  try {
    console.log('Sending meter update request:', { equipmentId, meterData });
    const response = await axiosInstance.put(`/equipment/${equipmentId}/meters`, meterData);
    console.log('Meter update response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in updateEquipmentMeters:', error.response?.data || error);
    throw error;
  }
};

// Add this function to your existing equipmentService.js file
export const getEquipmentById = async (id) => {
  try {
    console.log('Getting equipment by ID:', id);
    const response = await axiosInstance.get(`/equipment/${id}`);
    console.log('Equipment by ID response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching equipment by ID:', error);
    throw error;
  }
};

// Add this function to equipmentService.js
export const updateEquipmentPartApplications = async (equipmentId, partApplications) => {
  try {
    const response = await axiosInstance.put(`/equipment/${equipmentId}/parts`, {
      partApplications
    });
    return response.data;
  } catch (error) {
    console.error('Error updating equipment part applications:', error);
    throw error;
  }
};

// Add these new functions to handle saved configurations and previously used parts
export const saveMaintenanceConfiguration = async (equipmentId, configData) => {
  try {
    const response = await axiosInstance.post(
      `/equipment/${equipmentId}/maintenance-configurations`,
      configData
    );
    return response.data;
  } catch (error) {
    console.error('Error saving maintenance configuration:', error);
    throw error;
  }
};

export const getSavedConfigurations = async (equipmentId) => {
  try {
    const response = await axiosInstance.get(
      `/equipment/${equipmentId}/maintenance-configurations`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching saved configurations:', error);
    throw error;
  }
};

export const updatePreviouslyUsedParts = async (equipmentId, partsData) => {
  try {
    const response = await axiosInstance.post(
      `/equipment/${equipmentId}/previously-used-parts`,
      partsData
    );
    return response.data;
  } catch (error) {
    console.error('Error updating previously used parts:', error);
    throw error;
  }
};

export const loadMaintenanceConfiguration = async (equipmentId, configId) => {
  try {
    const response = await axiosInstance.get(
      `/equipment/${equipmentId}/maintenance-configurations/${configId}`
    );
    return response.data;
  } catch (error) {
    console.error('Error loading maintenance configuration:', error);
    throw error;
  }
};

export const deleteMaintenanceConfiguration = async (equipmentId, configId) => {
  try {
    const response = await axiosInstance.delete(
      `/equipment/${equipmentId}/maintenance-configurations/${configId}`
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting maintenance configuration:', error);
    throw error;
  }
};

// Add this new function to update equipment parts
export const updateEquipmentParts = async (equipmentId, partData) => {
  try {
    // If updating an existing part application
    if (partData.isUpdate) {
      const response = await axiosInstance.put(
        `/equipment/${equipmentId}/parts/${partData.part}`,
        {
          ...partData,
          unit: partData.unit || 'Each' // Ensure unit is always sent
        }
      );
      return response.data;
    }
    // If adding a new part application
    else {
      const response = await axiosInstance.post(
        `/equipment/${equipmentId}/parts`,
        partData
      );
      return response.data;
    }
  } catch (error) {
    console.error('Error updating equipment parts:', error);
    throw error;
  }
};
