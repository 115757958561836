import React, { useState, useEffect } from 'react';
import { createEquipment, getEquipmentTypes, getEquipmentCategories } from '../services/equipmentService';
import { getLocations } from '../services/locationService';
import { FiX } from 'react-icons/fi';

const AddEquipment = ({ onClose, onEquipmentAdded }) => {
  const [formData, setFormData] = useState({
    ID: '',
    Type: '',
    Category: '',
    Make: '',
    Model: '',
    Year: '',
    Serial: '',
    Status: 'In-Service',
    Location: '',
    Driver: '',
    Hours: '',
    Odometer: '',
    Notes: '',
    ignoreHours: false,
    ignoreOdometer: false
  });
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [equipmentCategories, setEquipmentCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const [types, categories, locationsList] = await Promise.all([
          getEquipmentTypes(),
          getEquipmentCategories(),
          getLocations()
        ]);
        setEquipmentTypes(types);
        setEquipmentCategories(categories);
        setLocations(locationsList);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load necessary data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent double submission
    
    try {
      setIsSubmitting(true);
      setError('');
      
      const response = await createEquipment(formData);
      if (response.success) {
        onEquipmentAdded();
        onClose(); // Close the modal on success
      } else {
        setError(response.message || 'Failed to create equipment. Please try again.');
      }
    } catch (err) {
      console.error('Error creating equipment:', err);
      setError(err.response?.data?.message || 'Failed to create equipment. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Handle checkbox changes
    if (type === 'checkbox') {
      const fieldToReset = name === 'ignoreHours' ? 'Hours' : name === 'ignoreOdometer' ? 'Odometer' : null;
      
      setFormData(prev => ({

        ...prev,
        [name]: checked,
        // Clear the corresponding field if checkbox is checked
        ...(fieldToReset && checked ? { [fieldToReset]: '' } : {})
      }));
      return;
    }

    // Handle regular input changes
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  if (isLoading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999]">
        <div className="bg-white p-6 rounded-lg">Loading...</div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999]">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto relative">
        <div className="sticky top-0 bg-white p-4 border-b border-gray-200 flex justify-between items-center">
          <h2 className="text-xl font-semibold">Add New Equipment</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 transition-colors"
          >
            <FiX className="w-6 h-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          {error && (
            <div className="bg-red-50 text-red-500 p-3 rounded-md mb-4">
              {error}
            </div>
          )}

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Equipment ID
              </label>
              <input
                type="text"
                name="ID"
                value={formData.ID}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Status
              </label>
              <select
                name="Status"
                value={formData.Status}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                required
              >
                <option value="In-Service">In-Service</option>
                <option value="Storage">Storage</option>
                <option value="Maintenance">Maintenance</option>
                <option value="Repair">Repair</option>
                <option value="Out-of-Service">Out-of-Service</option>
                <option value="Salvage">Salvage</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Type
              </label>
              <select
                name="Type"
                value={formData.Type}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                required
              >
                <option value="">Select Type</option>
                {equipmentTypes.map(type => (
                  <option key={type._id} value={type._id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Category
              </label>
              <select
                name="Category"
                value={formData.Category}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                required
              >
                <option value="">Select Category</option>
                {equipmentCategories.map(category => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Make
              </label>
              <input
                type="text"
                name="Make"
                value={formData.Make}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Model
              </label>
              <input
                type="text"
                name="Model"
                value={formData.Model}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Year
              </label>
              <input
                type="text"
                name="Year"
                value={formData.Year}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Serial Number
              </label>
              <input
                type="text"
                name="Serial"
                value={formData.Serial}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              />
            </div>

            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Location
              </label>
              <select
                name="Location"
                value={formData.Location}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                required
              >
                <option value="">Select Location</option>
                {locations.map(location => (
                  <option key={location._id} value={location._id}>
                    {location.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <div className="flex justify-between items-center mb-1">
                <label className="block text-sm font-medium text-gray-700">
                  Hours
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="ignoreHours"
                    checked={formData.ignoreHours}
                    onChange={handleChange}
                    className="rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                  />
                  <span className="text-sm text-gray-700">Ignore Hours</span>
                </label>
              </div>
              {!formData.ignoreHours && (
                <input
                  type="number"
                  name="Hours"
                  value={formData.Hours}
                  onChange={handleChange}
                  className="w-full border border-gray-300 rounded-md p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                />
              )}
            </div>

            <div>
              <div className="flex justify-between items-center mb-1">
                <label className="block text-sm font-medium text-gray-700">
                  Odometer
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="ignoreOdometer"
                    checked={formData.ignoreOdometer}
                    onChange={handleChange}
                    className="rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                  />
                  <span className="text-sm text-gray-700">Ignore Odometer</span>
                </label>
              </div>
              {!formData.ignoreOdometer && (
                <input
                  type="number"
                  name="Odometer"
                  value={formData.Odometer}
                  onChange={handleChange}
                  className="w-full border border-gray-300 rounded-md p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                />
              )}
            </div>
          </div>

          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Notes
            </label>
            <textarea
              name="Notes"
              value={formData.Notes}
              onChange={handleChange}
              rows="3"
              className="w-full border border-gray-300 rounded-md p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            />
          </div>

          <div className="flex justify-end gap-2 pt-4 border-t">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 transition-colors"
            >
              Create Equipment
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEquipment;
