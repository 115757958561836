import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { register } from '../services/authService';

function Register() {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    email: '',
    cellPhone: '',
    firstname: '',
    lastname: '',
    displayName: ''
  });
  const [error, setError] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    setIsLoading(true);

    try {
      const result = await register(formData);
      
      if (result.success) {
        navigate('/dashboard');
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError(err.response?.data?.message || 'An unexpected error occurred. Please try again.');
      console.error('Registration error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    if (name === 'password' || name === 'confirmPassword') {
      setPasswordMatch(value === (name === 'password' ? formData.confirmPassword : formData.password));
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="bg-primary px-6 py-4">
          <h2 className="text-center text-2xl font-bold text-white">
            Register
          </h2>
        </div>

        <div className="px-6 py-6">
          {error && (
            <div className="mb-4 text-red-500 text-sm text-center bg-red-50 border border-red-200 rounded-md p-2">
              {error}
            </div>
          )}

          <form className="space-y-4" onSubmit={handleSubmit}>
            {/* Username Field */}
            <div>
              <label htmlFor="username" className="block text-sm font-medium text-gray-700 mb-1">
                Username
              </label>
              <input
                id="username"
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                required
                placeholder="This is what you'll use to log in"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 
                  rounded-md shadow-sm placeholder-gray-400 
                  focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>

            {/* Password Fields */}
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                Password
              </label>
              <input
                id="password"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 
                  rounded-md shadow-sm placeholder-gray-400 
                  focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>

            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 mb-1">
                Confirm Password
              </label>
              <input
                id="confirmPassword"
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
                className={`appearance-none block w-full px-3 py-2 border 
                  ${passwordMatch === false ? 'border-red-300' : 
                    passwordMatch === true ? 'border-green-300' : 'border-gray-300'}
                  rounded-md shadow-sm placeholder-gray-400 
                  focus:outline-none focus:ring-primary focus:border-primary sm:text-sm`}
              />
              {passwordMatch === false && (
                <p className="mt-1 text-sm text-red-600">Passwords do not match.</p>
              )}
              {passwordMatch === true && (
                <p className="mt-1 text-sm text-green-600">Passwords match!</p>
              )}
            </div>

            {/* Contact Information */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Email
              </label>
              <input
                id="email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="youremail@emails.com"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 
                  rounded-md shadow-sm placeholder-gray-400 
                  focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>

            <div>
              <label htmlFor="cellPhone" className="block text-sm font-medium text-gray-700 mb-1">
                Cell Phone
              </label>
              <input
                id="cellPhone"
                type="tel"
                name="cellPhone"
                value={formData.cellPhone}
                onChange={handleChange}
                required
                placeholder="(123) 456-7890"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 
                  rounded-md shadow-sm placeholder-gray-400 
                  focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>

            {/* Personal Information */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="firstname" className="block text-sm font-medium text-gray-700 mb-1">
                  First Name
                </label>
                <input
                  id="firstname"
                  type="text"
                  name="firstname"
                  value={formData.firstname}
                  onChange={handleChange}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 
                    rounded-md shadow-sm placeholder-gray-400 
                    focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="lastname" className="block text-sm font-medium text-gray-700 mb-1">
                  Last Name
                </label>
                <input
                  id="lastname"
                  type="text"
                  name="lastname"
                  value={formData.lastname}
                  onChange={handleChange}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 
                    rounded-md shadow-sm placeholder-gray-400 
                    focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="displayName" className="block text-sm font-medium text-gray-700 mb-1">
                Display Name
              </label>
              <input
                id="displayName"
                type="text"
                name="displayName"
                value={formData.displayName}
                onChange={handleChange}
                required
                placeholder="How users will see you"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 
                  rounded-md shadow-sm placeholder-gray-400 
                  focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>

            <div className="pt-2">
              <button
                type="submit"
                disabled={isLoading || passwordMatch === false}
                className="w-full flex justify-center py-2 px-4 border border-transparent 
                  rounded-md shadow-sm text-sm font-medium text-white bg-primary 
                  hover:bg-primary/90 focus:outline-none focus:ring-2 
                  focus:ring-offset-2 focus:ring-primary transition-colors
                  disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? 'Creating Account...' : 'Register'}
              </button>
            </div>
          </form>

          <div className="mt-6 space-y-2 border-t border-gray-200 pt-4">
            <div className="text-center">
              <Link 
                to="/login" 
                className="text-sm text-primary hover:text-primary/80 font-medium"
              >
                Already have an account? Login here.
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
