import React from 'react';
import { FiX } from 'react-icons/fi';
import '../styles/AssignUserModal.css';

function AssignUserModal({ allUsers, assignedUsers, onAssign, onClose }) {
  const users = Array.isArray(allUsers) ? allUsers : (allUsers?.data || []);

  const eligibleUsers = users.filter(user => 
    user.abilities && 
    user.abilities.includes('CanBeAssignedWorkOrders') &&
    !assignedUsers.some(assigned => assigned._id === user._id)
  );

  if (!Array.isArray(users)) {
    return <div>Error: User data is not in the correct format.</div>;
  }

  return (
    <div className="assign-user-modal-overlay">
      <div className="assign-user-modal">
        <button className="close-modal" onClick={onClose}>
          <FiX />
        </button>
        <h4>Assign User</h4>
        <div className="user-list">
          {eligibleUsers.map(user => (
            <div key={user._id} className="user-option">
              <span>{user.displayName || user.username}</span>
              <button onClick={() => onAssign(user._id)}>Assign</button>
            </div>
          ))}
        </div>
        {eligibleUsers.length === 0 && (
          <p className="no-users-message">No eligible users available to assign.</p>
        )}
      </div>
    </div>
  );
}

export default AssignUserModal;
