import React, { useState } from 'react';
import axios from 'axios';
import { FiMail } from 'react-icons/fi';
import { toast } from 'react-toastify';

const EmailUpdateButton = ({ currentEmail }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleSendVerificationEmail = async () => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            
            const response = await axios.post('/api/email-update/request-email-change', 
                { currentEmail },
                { 
                    headers: { 
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            
            if (response.data.success) {
                toast.success('Verification email sent! Please check your inbox.');
            }
        } catch (error) {
            console.error('Error sending verification email:', error);
            toast.error(error.response?.data?.message || 'Failed to send verification email');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <button
            onClick={handleSendVerificationEmail}
            disabled={isLoading}
            className="ml-2 inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
            <FiMail className="mr-2" />
            {isLoading ? 'Sending...' : 'Update Email'}
        </button>
    );
};

export default EmailUpdateButton;
