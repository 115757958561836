import axiosInstance from './api';

// Function to get all work orders
export const getWorkOrders = async (params) => {
  try {
    const response = await axiosInstance.get('/work-orders', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching work orders:', error);
    throw error;
  }
};

// Function to create a new work order
export const createWorkOrder = async (workOrderData) => {
  try {
    const response = await axiosInstance.post('/work-orders', workOrderData);
    return response.data;
  } catch (error) {
    console.error('Error creating work order:', error);
    throw error;
  }
};

// Function to get a work order by ID
export const getWorkOrderById = async (id) => {
  try {
    if (!id) {
      throw new Error('Work order ID is undefined');
    }
    const response = await axiosInstance.get(`/work-orders/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching work order:', error);
    throw error;
  }
};

// Function to update a work order
export const updateWorkOrder = async (id, updatedData) => {
  try {
    const response = await axiosInstance.put(`/work-orders/${id}`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error updating work order:', error);
    if (error.response && error.response.status === 403) {
      throw new Error('You do not have permission to update this work order');
    }
    throw error;
  }
};

// Function to delete a work order
export const deleteWorkOrder = async (id) => {
  try {
    const response = await axiosInstance.delete(`/work-orders/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting work order:', error);
    throw error;
  }
};

// Additional functions can be added similarly

export const getLocations = async () => {
  try {
    const response = await axiosInstance.get('/locations');
    return response.data;
  } catch (error) {
    console.error('Error fetching locations:', error);
    throw error;
  }
};

export const getUsers = async () => {
  try {
    const response = await axiosInstance.get('/users');
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getEquipment = async () => {
  try {
    const response = await axiosInstance.get('/equipment');
    return response.data;
  } catch (error) {
    console.error('Error fetching equipment:', error);
    throw error;
  }
};

export const getWorkOrderComments = async (workOrderId) => {
  try {
    const response = await axiosInstance.get(`/work-orders/${workOrderId}/comments`);
    return response.data;
  } catch (error) {
    console.error('Error fetching work order comments:', error);
    throw error;
  }
};

export const addWorkOrderComment = async (workOrderId, commentData) => {
  try {
    const response = await axiosInstance.post(`/work-orders/${workOrderId}/comments`, commentData);
    return response.data;
  } catch (error) {
    console.error('Error adding work order comment:', error);
    throw error;
  }
};

export const assignUsersToWorkOrder = async (workOrderId, userIds) => {
  try {
    const response = await axiosInstance.post(`/work-orders/${workOrderId}/assign`, { userIds });
    return response.data;
  } catch (error) {
    console.error('Error assigning users to work order:', error);
    throw error;
  }
};

export const removeAssignedUsersFromWorkOrder = async (workOrderId, userIds) => {
  try {
    const response = await axiosInstance.post(`/work-orders/${workOrderId}/unassign`, { userIds });
    return response.data;
  } catch (error) {
    console.error('Error removing assigned users from work order:', error);
    throw error;
  }
};

export const subscribeToWorkOrders = () => {
  console.warn('subscribeToWorkOrders is deprecated. SSE is handled in the WorkOrders component.');
};

export const deleteWorkOrderComment = async (workOrderId, commentId) => {
  try {
    const response = await axiosInstance.delete(`/work-orders/${workOrderId}/comments/${commentId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting work order comment:', error);
    throw error;
  }
};
