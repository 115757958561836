import React, { useState, useEffect } from 'react';
import { FiGrid, FiFileText, FiClipboard, FiTruck, FiPackage, FiSettings } from 'react-icons/fi';
import { PiGauge } from "react-icons/pi";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { checkAbilityPermissions } from '../services/authService';

function NavigationBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  
  // Permission states
  const [permissions, setPermissions] = useState({
    canViewAllWorkOrders: false,
    canUpdateMeters: false,
    canViewEquipment: false,
    canViewParts: false,
    isAdmin: false
  });

  // Get active tab from pathname
  const getActiveTab = (pathname) => {
    const path = pathname.split('/')[1];
    // If path is empty (root path '/') or 'dashboard', return 'welcome'
    return (!path || path === 'dashboard') ? 'welcome' : path;
  };

  const [activeTab, setActiveTab] = useState(getActiveTab(location.pathname));

  // Update active tab when location changes
  useEffect(() => {
    setActiveTab(getActiveTab(location.pathname));
  }, [location]);

  // Check permissions when user is loaded
  useEffect(() => {
    const checkPermissions = async () => {
      if (!user) return;

      try {
        const [
          viewAll,
          meters,
          equipment,
          parts,
          admin
        ] = await Promise.all([
          checkAbilityPermissions('CanViewAllWorkOrders'),
          checkAbilityPermissions('CanUpdateEquipmentMeters'),
          checkAbilityPermissions('CanViewEquipment'),
          checkAbilityPermissions('CanViewParts'),
          checkAbilityPermissions('IsAdmin')
        ]);

        setPermissions({
          canViewAllWorkOrders: viewAll,
          canUpdateMeters: meters,
          canViewEquipment: equipment,
          canViewParts: parts,
          isAdmin: admin
        });
      } catch (error) {
        console.error('Error checking permissions:', error);
      }
    };

    checkPermissions();
  }, [user]);

  // Don't show navigation bar on login or register pages
  if (!user || location.pathname === '/login' || location.pathname === '/register') {
    return null;
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    navigate(`/${tab === 'welcome' ? '' : tab}`);
  };

  const iconClass = "w-6 h-6 stroke-[2]";

  return (
    <div className="flex fixed top-10 left-0 right-0 h-[40px] bg-gray-100 z-[9998]">
      <button
        className={`w-[40px] h-[40px] flex justify-center items-center border-none cursor-pointer transition-colors p-1
          hover:bg-gray-200 ${activeTab === 'welcome' ? 'bg-gray-300' : 'bg-transparent'}`}
        onClick={() => handleTabChange('welcome')}
        title="Dashboard"
      >
        <FiGrid className={iconClass} />
      </button>
      
      <button
        className={`w-[40px] h-[40px] flex justify-center items-center border-none cursor-pointer transition-colors p-1
          hover:bg-gray-200 ${activeTab === 'WorkOrders' ? 'bg-gray-300' : 'bg-transparent'}`}
        onClick={() => handleTabChange('WorkOrders')}
        title={permissions.canViewAllWorkOrders ? 'All Work Orders' : 'Your Work Orders'}
      >
        <FiFileText className={iconClass} />
      </button>

      {permissions.canUpdateMeters && (
        <button
          className={`w-[40px] h-[40px] flex justify-center items-center border-none cursor-pointer transition-colors p-1
            hover:bg-gray-200 ${activeTab === 'updateMeters' ? 'bg-gray-300' : 'bg-transparent'}`}
          onClick={() => handleTabChange('updateMeters')}
          title="Update Meters"
        >
          <PiGauge className={iconClass} />
        </button>
      )}

      {permissions.canViewEquipment && (
        <button
          className={`w-[40px] h-[40px] flex justify-center items-center border-none cursor-pointer transition-colors p-1
            hover:bg-gray-200 ${activeTab === 'equipment' ? 'bg-gray-300' : 'bg-transparent'}`}
          onClick={() => handleTabChange('equipment')}
          title="Equipment"
        >
          <FiTruck className={iconClass} />
        </button>
      )}

      {permissions.canViewParts && (
        <button
          className={`w-[40px] h-[40px] flex justify-center items-center border-none cursor-pointer transition-colors p-1
            hover:bg-gray-200 ${activeTab === 'parts' ? 'bg-gray-300' : 'bg-transparent'}`}
          onClick={() => handleTabChange('parts')}
          title="Parts"
        >
          <FiPackage className={iconClass} />
        </button>
      )}

      {permissions.isAdmin && (
        <button
          className={`w-[40px] h-[40px] flex justify-center items-center border-none cursor-pointer transition-colors p-1
            hover:bg-gray-200 ${activeTab === 'admin' ? 'bg-gray-300' : 'bg-transparent'}`}
          onClick={() => handleTabChange('admin')}
          title="Admin Controls"
        >
          <FiSettings className={iconClass} />
        </button>
      )}
    </div>
  );
}

export default NavigationBar;