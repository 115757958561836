import React, { useState, useEffect, useCallback } from 'react';
import { getEquipment, deleteEquipment } from '../services/equipmentService';
import { getLocations } from '../services/locationService';
import { checkAbilityPermissions } from '../services/authService';
import { useAuth } from '../hooks/useAuth';
import EquipmentList from './EquipmentList';
import EquipmentModal from './EquipmentModal';
import AddEquipment from './AddEquipment';
import EquipmentFilterModal from './EquipmentFilterModal';
import { FiFilter, FiSearch } from 'react-icons/fi';
import { FaSort } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import EquipmentSortModal from './EquipmentSortModal';
import { MdOutlineCreateNewFolder } from 'react-icons/md';

const ACTIVE_STATUSES = ['In-Service', 'Storage', 'Maintenance', 'Repair'];
const INACTIVE_STATUSES = ['Out-of-Service', 'Salvage'];

function Equipment() {
  const { user } = useAuth();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  
  const defaultFilters = {
    status: 'Active',
    statusArray: ['In-Service', 'Storage', 'Maintenance', 'Repair'],
    location: 'All Locations',
    type: 'All Types',
    category: 'All'
  };

  const [equipment, setEquipment] = useState([]);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showSortModal, setShowSortModal] = useState(false);
  const [activeFilter, setActiveFilter] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [showEquipmentModal, setShowEquipmentModal] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({
    field: 'ID',
    direction: 'asc'
  });

  const fetchEquipment = useCallback(async () => {
    try {
      setIsLoading(true);
      const params = {};

      // Handle status filter
      if (filters.status) {
        console.log('Status filter:', filters.status);
        
        // If Active or Inactive, use the statusArray from filters
        if (filters.status === 'Active' || filters.status === 'Inactive') {
          if (filters.statusArray && filters.statusArray.length > 0) {
            // Send each status as a separate parameter
            filters.statusArray.forEach((status, index) => {
              params[`status${index}`] = status;
            });
          }
        } else if (filters.status !== 'All') {
          // For single status selections
          params.status = filters.status;
        }
      }

      // Handle other filters
      if (filters.location && filters.location !== 'All Locations') {
        params.location = filters.location;
      }

      if (filters.type && filters.type !== 'All Types') {
        params.type = filters.type;
      }

      if (filters.category && filters.category !== 'All') {
        params.category = filters.category;
      }

      // Add search parameter if there's a query
      if (searchQuery && searchQuery.trim()) {
        params.search = searchQuery.trim();
        console.log('Adding search parameter:', params.search);
      }

      console.log('Sending filter params:', params);
      const data = await getEquipment(params);
      console.log('Received equipment data:', data.length, 'items');
      
      // If we have a search query, log the results for debugging
      if (params.search) {
        console.log('Search results for query:', params.search);
        console.log('Matching equipment:', data.map(e => e.ID));
      }
      
      setEquipment(data);
      setError('');
    } catch (err) {
      console.error('Error fetching equipment:', err);
      const errorMessage = err.response?.data?.message || err.message || 'Failed to fetch equipment.';
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [filters, searchQuery]);

  // Add debounce to search
  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchQuery !== undefined) {
        fetchEquipment();
      }
    }, 300); // Wait 300ms after last keystroke before searching

    return () => clearTimeout(timer);
  }, [searchQuery, fetchEquipment]);

  useEffect(() => {
    fetchEquipment();
    checkPermissions();
  }, [fetchEquipment, filters]);

  const checkPermissions = async () => {
    const editPermission = await checkAbilityPermissions('CanModifyEquipment');
    const deletePermission = await checkAbilityPermissions('CanDeleteEquipment');
    setCanEdit(editPermission);
    setCanDelete(deletePermission);
  };

  const handleFilterChange = (filterType, value) => {
    setFilters(prev => ({ ...prev, [filterType]: value }));
  };

  const handleFilterApply = (newFilters) => {
    setFilters(newFilters);
    setShowFilterModal(false);
    const isFilterActive = Object.keys(newFilters).some(key => 
      key !== 'statusArray' && newFilters[key] !== defaultFilters[key]
    );
    setActiveFilter(isFilterActive);
  };

  const handleFilterReset = () => {
    setFilters(defaultFilters);
    setShowFilterModal(false);
    setActiveFilter(false);
  };

  const handleEquipmentClick = (equipment) => {
    setSelectedEquipment(equipment);
    if (isMobile) {
      setShowEquipmentModal(true);
    }
  };

  const handleEquipmentUpdate = (updatedEquipment) => {
    setEquipment(prevEquipment => 
      prevEquipment.map(eq => eq._id === updatedEquipment._id ? updatedEquipment : eq)
    );
  };

  const handleCloseModal = () => {
    setShowEquipmentModal(false);
    setSelectedEquipment(null);
    fetchEquipment();
  };

  const handleSortApply = (sortConfig) => {
    setSortConfig(sortConfig);
    setShowSortModal(false);
  };

  return (
    <div className="flex flex-col h-[calc(100vh-90px)] mt-[90px]">
      <div className="flex h-full overflow-hidden">
        {/* Left section - Equipment List */}
        <div className={`flex flex-col min-w-0 ${!isMobile ? 'w-[400px]' : 'flex-1'}`}>
          {/* Controls section - always visible */}
          <div className="px-2 pt-2 pb-1 bg-white">
            <div className="flex justify-between items-center">
              {canEdit && (
                <button
                  className="p-1 text-green-500 hover:text-green-600 transition-colors"
                  onClick={() => setShowAddForm(true)}
                  title="Add Equipment"
                >
                  <MdOutlineCreateNewFolder className="w-8 h-8" />
                </button>
              )}
              
              <div className="flex gap-1">
                <button
                  className={`p-1 ${showSearch ? 'text-blue-500 hover:text-blue-600' : 'text-gray-600 hover:text-gray-800'} transition-colors`}
                  onClick={() => setShowSearch(!showSearch)}
                  title="Toggle Search"
                >
                  <FiSearch className="w-7 h-7" />
                </button>

                <button
                  className={`p-1 transition-colors ${
                    activeFilter 
                      ? 'text-blue-500 hover:text-blue-600' 
                      : 'text-gray-600 hover:text-gray-800'
                  }`}
                  onClick={() => setShowFilterModal(true)}
                  title="Filter Equipment"
                >
                  <FiFilter className="w-7 h-7" />
                </button>

                <button
                  className="p-1 text-gray-600 hover:text-gray-800 transition-colors"
                  onClick={() => setShowSortModal(true)}
                  title="Sort Equipment"
                >
                  <FaSort className="w-7 h-7" />
                </button>
              </div>
            </div>
          </div>

          {/* List Header - Fixed */}
          <div className="px-2 py-1 bg-white border-b border-gray-200">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-bold">Equipment</h2>
              <span className="text-sm text-gray-600">Sort: {sortConfig.field}</span>
            </div>
          </div>

          {/* Search Bar - Toggleable */}
          {showSearch && (
            <div className="px-2 py-2 bg-white border-b border-gray-200">
              <div className="flex items-center gap-2 bg-white border border-gray-300 rounded-lg px-3 py-2">
                <FiSearch className="w-5 h-5 text-gray-500" />
                <input
                  type="text"
                  placeholder="Search equipment..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full outline-none text-sm"
                  autoFocus
                />
              </div>
            </div>
          )}

          {error && (
            <p className="text-red-500 text-center my-4">{error}</p>
          )}

          {/* Equipment List section - scrollable */}
          {isLoading ? (
            <p className="text-center p-4">Loading equipment...</p>
          ) : (
            <div className="flex-1 overflow-y-auto">
              <EquipmentList 
                equipment={equipment}
                onSelectEquipment={handleEquipmentClick}
                selectedEquipment={selectedEquipment}
                sortConfig={sortConfig}
              />
            </div>
          )}
        </div>

        {/* Right section - Equipment Modal */}
        {!isMobile && selectedEquipment && (
          <div className="flex-1 border-l border-gray-200 flex flex-col relative z-[9000]">
            <div className="flex-1 overflow-y-auto pt-8">
              <EquipmentModal 
                equipment={selectedEquipment}
                onClose={() => setSelectedEquipment(null)}
                onUpdate={handleEquipmentUpdate}
                isInlineDisplay={true}
              />
            </div>
          </div>
        )}
      </div>

      {/* Modals */}
      {showAddForm && (
        <AddEquipment
          onClose={() => setShowAddForm(false)}
          onEquipmentAdded={fetchEquipment}
        />
      )}

      {showFilterModal && (
        <EquipmentFilterModal
          filters={filters}
          onApply={handleFilterApply}
          onReset={handleFilterReset}
          onClose={() => setShowFilterModal(false)}
        />
      )}

      {showSortModal && (
        <EquipmentSortModal
          currentSort={sortConfig}
          onApply={handleSortApply}
          onClose={() => setShowSortModal(false)}
        />
      )}

      {isMobile && showEquipmentModal && selectedEquipment && (
        <EquipmentModal
          equipment={selectedEquipment}
          onClose={handleCloseModal}
          onUpdate={handleEquipmentUpdate}
          isInlineDisplay={false}
        />
      )}
    </div>
  );
}

export default Equipment;
