import React from 'react';
import { Routes, Route } from 'react-router-dom';
import UserManagement from './Admin/UserManagement';
import RoleManagement from './Admin/RoleManagement';
import Settings from './Admin/Settings';
import EquipmentManagement from './Admin/EquipmentManagement';
import LocationManagement from './Admin/LocationManagement';
import DeviceManagement from './Admin/DeviceManagement';
import MaintenanceItemManagement from './Admin/MaintenanceItemManagement';
import AdminNavigation from './Admin/AdminNavigation';
import LogManagement from './Admin/LogManagement';

function Admin() {
  return (
    <div className="flex flex-col h-[calc(100vh-80px)] mt-[80px]">
      <div className="h-[40px]">
        <AdminNavigation />
      </div>
      
      <div className="flex-1 overflow-auto bg-gray-50 p-4">
        <Routes>
          <Route path="/" element={<UserManagement />} />
          <Route path="users" element={<UserManagement />} />
          <Route path="roles" element={<RoleManagement />} />
          <Route path="settings" element={<Settings />} />
          <Route path="equipment" element={<EquipmentManagement />} />
          <Route path="locations" element={<LocationManagement />} />
          <Route path="devices" element={<DeviceManagement />} />
          <Route path="maintenance" element={<MaintenanceItemManagement />} />
          <Route path="logs" element={<LogManagement />} />
        </Routes>
      </div>
    </div>
  );
}

export default Admin;
