import { useState, useEffect, createContext, useContext } from 'react';
import { login as loginService, logout as logoutService, checkRoles } from '../services/authService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadUser = async () => {
      const storedUser = localStorage.getItem('user');
      const storedToken = localStorage.getItem('token');
      if (storedUser && storedToken) {
        try {
          const parsedUser = JSON.parse(storedUser);
          // Fetch the latest user data including permissions
          const updatedUser = await checkRoles();
          
          // Ensure we preserve tracked equipment data
          const userWithTrackedEquipment = {
            ...updatedUser,
            trackedEquipment: parsedUser.trackedEquipment || updatedUser.trackedEquipment || []
          };
          
          // Update localStorage with the latest user data
          localStorage.setItem('user', JSON.stringify(userWithTrackedEquipment));
          setUser(userWithTrackedEquipment);
          
        } catch (error) {
          console.error('Failed to load user data:', error);
          setUser(null);
        }
      }
      setLoading(false);
    };

    loadUser();
  }, []);

  const login = (userData) => {
    // Ensure trackedEquipment is initialized
    const userWithTrackedEquipment = {
      ...userData,
      trackedEquipment: userData.trackedEquipment || []
    };
    setUser(userWithTrackedEquipment);
    localStorage.setItem('user', JSON.stringify(userWithTrackedEquipment));
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  };

  const updateUserData = (newData) => {
    setUser(current => {
      const updated = {
        ...current,
        ...newData,
        // Ensure trackedEquipment is preserved
        trackedEquipment: newData.trackedEquipment || current.trackedEquipment || []
      };
      // Update localStorage whenever user data changes
      localStorage.setItem('user', JSON.stringify(updated));
      return updated;
    });
  };

  useEffect(() => {
    if (user) {
    }
  }, [user]);

  return (
    <AuthContext.Provider value={{ user, login, logout, updateUserData, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
