import React, { useState, useEffect } from 'react';
import { getParts } from '../services/partService';
import { createPart } from '../services/partService';
import { 
  setEquipmentMaintenanceItem,
  getEquipmentMaintenanceItems
} from '../services/maintenanceItemService';
import { FiPlus, FiMinus, FiTrash2 } from 'react-icons/fi';

const PartSelectionSection = ({ 
  category, 
  items, 
  onSelect, 
  onBack, 
  onClose, 
  equipment,
  existingItems = [],
  backButtonText = 'Back',
  buttonPosition = 'left'
}) => {
  const [parts, setParts] = useState([]);
  const [previouslyUsedParts, setPreviouslyUsedParts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedParts, setSelectedParts] = useState([]);
  const [error, setError] = useState('');
  const [showNewPartForm, setShowNewPartForm] = useState(false);
  const [newPart, setNewPart] = useState({
    name: '',
    manufacturer: '',
    partNumber: '',
    description: '',
    category: items[0]?.partCategories[0] || 'Air Filter',
    unit: 'Each'
  });
  const [usedPartIds, setUsedPartIds] = useState(new Set());

  // Get the current maintenance item type
  const currentMaintenanceType = items[0]?.name; // e.g., "Fuel Filter" or "Air Filter"

  useEffect(() => {
    // Initialize selectedParts with any existing items of this type
    const existingItemsOfType = existingItems.filter(item => 
      item.type === currentMaintenanceType
    );
    setSelectedParts(existingItemsOfType);
  }, [existingItems, currentMaintenanceType]);

  useEffect(() => {
    fetchPartsAndHistory();
  }, [items, equipment._id]);

  const fetchPartsAndHistory = async () => {
    try {
      const selectedItem = items[0];
      if (selectedItem && selectedItem.requiresParts) {
        console.log('Selected maintenance item:', selectedItem);
        
        // Get the main category and specific category from the maintenance item
        const mainCategory = selectedItem.category; // 'Filter', 'Fluid', etc.
        const specificCategory = selectedItem.name; // 'Engine Oil Filter', etc.
        
        console.log('Fetching parts for:', { mainCategory, specificCategory });

        // Fetch parts using the new schema
        const partsData = await getParts({
          category: mainCategory,
          subCategory: specificCategory
        });

        console.log('Fetched parts:', partsData);

        // Get previously used parts for this maintenance type
        const usedParts = equipment.previouslyUsedParts?.filter(p => 
          p.category === specificCategory
        ) || [];

        const usedIds = new Set(usedParts.map(p => p.part.toString()));
        
        // Split parts into previously used and available
        const used = partsData.filter(part => usedIds.has(part._id.toString()));
        const others = partsData.filter(part => !usedIds.has(part._id.toString()));

        setPreviouslyUsedParts(used);
        setParts(others);
      }
    } catch (error) {
      console.error('Error fetching parts:', error);
      setError('Failed to load parts');
    } finally {
      setLoading(false);
    }
  };

  const handleAddPart = (part) => {
    // Only add to selectedParts, don't remove from lists
    setSelectedParts(prev => {
      const existing = prev.find(p => p.part._id === part._id);
      if (existing) {
        return prev.map(p => 
          p.part._id === part._id 
            ? { ...p, quantity: p.quantity + 1 }
            : p
        );
      }
      return [...prev, { part, quantity: 1 }];
    });
  };

  const handleRemovePart = (partId) => {
    setSelectedParts(prev => {
      const existing = prev.find(p => p.part._id === partId);
      if (existing && existing.quantity > 1) {
        return prev.map(p => 
          p.part._id === partId 
            ? { ...p, quantity: p.quantity - 1 }
            : p
        );
      }
      return prev.filter(p => p.part._id !== partId);
    });
  };

  const handleSave = async () => {
    const selectedItem = items[0];
    try {
      // Save the equipment-specific maintenance configuration
      await setEquipmentMaintenanceItem(equipment._id, selectedItem._id, {
        parts: selectedParts.map(({ part, quantity }) => ({
          part: part._id,
          quantity,
          category: selectedItem.partCategories[0]
        }))
      });

      // Just notify parent of selected parts, don't close
      onSelect({
        type: 'part',
        maintenanceItem: selectedItem,
        parts: selectedParts,
        details: {
          notes: ''
        }
      });
    } catch (error) {
      console.error('Error saving equipment maintenance configuration:', error);
      setError('Failed to save part configuration');
    }
  };

  // Add useEffect to automatically save when parts change
  useEffect(() => {
    if (selectedParts.length > 0) {
      handleSave();
    }
  }, [selectedParts]);

  const handleCreatePart = async (e) => {
    e.preventDefault();
    try {
      // Get the current maintenance item
      const selectedItem = items[0];
      
      // Generate name from manufacturer and part number
      const partName = `${newPart.manufacturer} ${newPart.partNumber}`;
      
      // Create part data with mainCategory
      const partData = {
        ...newPart,
        name: partName,
        mainCategory: selectedItem.category, // 'Filter', 'Fluid', etc.
        category: selectedItem.name // 'Engine Oil Filter', 'Air Filter', etc.
      };

      console.log('Creating new part with data:', partData);

      const createdPart = await createPart(partData);
      setParts(prevParts => [...prevParts, createdPart]);
      setShowNewPartForm(false);
      setNewPart({
        name: '',
        manufacturer: '',
        partNumber: '',
        description: '',
        category: selectedItem.name,
        unit: 'Each'
      });
    } catch (error) {
      console.error('Error creating part:', error);
      setError('Failed to create new part');
    }
  };

  const renderFilterItem = (filter, index) => {
    return (
      <div key={index} className="filter-item">
        {buttonPosition === "left" && (
          <button
            onClick={() => handleAddPart(filter)}
            className="add-filter-button"
            aria-label="Add filter"
          >
            <FiPlus />
          </button>
        )}
        <div className="filter-info">
          <span className="filter-name">{filter.manufacturer} - {filter.partNumber}</span>
          {filter.description && <span className="filter-description">{filter.description}</span>}
        </div>
        {buttonPosition !== "left" && (
          <button
            onClick={() => handleAddPart(filter)}
            className="add-filter-button"
            aria-label="Add filter"
          >
            <FiPlus />
          </button>
        )}
      </div>
    );
  };

  if (loading) {
    return <div>Loading parts...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="maint-part-modal-section">
      <div className="maint-part-modal-header">
        <button onClick={onBack} className="maint-part-back-button">
          ← {backButtonText}
        </button>
        <h3>{currentMaintenanceType}</h3>
      </div>

      <div className="maint-part-content">
        <div className="maintenance-parts-list">
          {items.map(item => (
            <div key={item._id} className="maintenance-item">
              {item.description && <p>{item.description}</p>}
              {item.requiresParts && (
                <div className="parts-section">
                  {selectedParts.length > 0 && (
                    <>
                      <h4>Selected Parts:</h4>
                      <div className="selected-parts">
                        {selectedParts
                          .map(({ part, quantity }) => (
                            <div key={part._id} className="selected-part">
                              <div className="part-details">
                                <span className="part-name">{part.manufacturer} - {part.partNumber}</span>
                                {part.description && (
                                  <span className="part-description">{part.description}</span>
                                )}
                                <span className="part-stock">In stock: {part.inventory?.quantity || 0}</span>
                              </div>
                              <div className="quantity-controls">
                                {quantity > 1 ? (
                                  <button 
                                    onClick={() => handleRemovePart(part._id)}
                                    className="quantity-button"
                                    aria-label="Decrease quantity"
                                  >
                                    <FiMinus />
                                  </button>
                                ) : (
                                  <button 
                                    onClick={() => handleRemovePart(part._id)}
                                    className="remove-button"
                                    aria-label="Remove part"
                                  >
                                    <FiTrash2 />
                                  </button>
                                )}
                                <span className="quantity-display">{quantity}</span>
                                <button 
                                  onClick={() => handleAddPart(part)}
                                  className="quantity-button"
                                  aria-label="Increase quantity"
                                >
                                  <FiPlus />
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  )}

                  <div className="parts-list-container">
                    {previouslyUsedParts.length > 0 && (
                      <>
                        <h4>Previously Used Parts</h4>
                        <div className="previously-used-parts">
                          {previouslyUsedParts
                            .filter(part => !selectedParts.some(sp => sp.part._id === part._id))
                            .map((part, index) => renderFilterItem(part, index))}
                        </div>
                      </>
                    )}

                    <h4>Available Parts</h4>
                    <div className="available-parts">
                      {parts
                        .filter(part => !selectedParts.some(sp => sp.part._id === part._id))
                        .map((part, index) => renderFilterItem(part, index))}
                    </div>
                  </div>

                  {!showNewPartForm ? (
                    <button 
                      onClick={() => setShowNewPartForm(true)}
                      className="create-part-button"
                    >
                      <FiPlus /> Add New Part
                    </button>
                  ) : (
                    <div className="new-part-form">
                      <h4>Add New Part</h4>
                      <form onSubmit={handleCreatePart}>
                        <div className="form-group">
                          <label>Manufacturer</label>
                          <input
                            type="text"
                            value={newPart.manufacturer}
                            onChange={(e) => setNewPart(prev => ({
                              ...prev,
                              manufacturer: e.target.value
                            }))}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label>Part Number</label>
                          <input
                            type="text"
                            value={newPart.partNumber}
                            onChange={(e) => setNewPart(prev => ({
                              ...prev,
                              partNumber: e.target.value
                            }))}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label>Description</label>
                          <input
                            type="text"
                            value={newPart.description}
                            onChange={(e) => setNewPart(prev => ({
                              ...prev,
                              description: e.target.value
                            }))}
                          />
                        </div>
                        <div className="form-actions">
                          <button type="submit" className="save-button">
                            Create Part
                          </button>
                          <button 
                            type="button" 
                            className="cancel-button"
                            onClick={() => setShowNewPartForm(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PartSelectionSection; 