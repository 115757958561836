import React, { useState } from 'react';
import { FiPrinter, FiDownload } from 'react-icons/fi';
import { getEquipment } from '../services/equipmentService';
import '../styles/Reports.css';

function Reports() {
  const [reportType, setReportType] = useState('fuel');
  const [month, setMonth] = useState(new Date().toISOString().slice(0, 7));
  const [selectedEquipment, setSelectedEquipment] = useState('all');
  const [equipment, setEquipment] = useState([]);

  const generateFuelLog = () => {
    // Generate fuel log report
  };

  const generateIMR = () => {
    // Generate individual mileage report
  };

  const handlePrint = () => {
    window.print();
  };

  const handleDownload = () => {
    // Handle PDF download
  };

  return (
    <div className="reports-container">
      <div className="reports-header">
        <h2 className="page-title">Reports</h2>
        <div className="report-actions">
          <button onClick={handlePrint} className="action-button">
            <FiPrinter /> Print
          </button>
          <button onClick={handleDownload} className="action-button">
            <FiDownload /> Download PDF
          </button>
        </div>
      </div>

      <div className="report-controls">
        <div className="control-group">
          <label>Report Type:</label>
          <select 
            value={reportType} 
            onChange={(e) => setReportType(e.target.value)}
          >
            <option value="fuel">Fuel Log</option>
            <option value="imr">Individual Mileage Report</option>
          </select>
        </div>

        <div className="control-group">
          <label>Month:</label>
          <input 
            type="month" 
            value={month}
            onChange={(e) => setMonth(e.target.value)}
          />
        </div>

        <div className="control-group">
          <label>Equipment:</label>
          <select 
            value={selectedEquipment}
            onChange={(e) => setSelectedEquipment(e.target.value)}
          >
            <option value="all">All Equipment</option>
            {equipment.map(eq => (
              <option key={eq._id} value={eq._id}>
                {eq.ID} - {eq.Make} {eq.Model}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="report-preview">
        {reportType === 'fuel' ? (
          <div className="fuel-log-preview">
            {/* Fuel Log Preview */}
          </div>
        ) : (
          <div className="imr-preview">
            {/* IMR Preview */}
          </div>
        )}
      </div>
    </div>
  );
}

export default Reports;