import React, { useState, useMemo, useEffect } from 'react';
import '../styles/WorkOrderList.css';
import { subscribeToWorkOrders, deleteWorkOrder } from '../services/workOrderService';

function WorkOrderList({ 
  workOrders: initialWorkOrders, 
  onSelectWorkOrder, 
  selectedWorkOrder, 
  sortConfig = { field: 'dateScheduled', direction: 'asc', emptyPosition: 'bottom' },
  loadMoreRef,
  hasMore,
  isLoading,
  currentUser
}) {
  const [workOrders, setWorkOrders] = useState(initialWorkOrders);
  const [expandedGroups, setExpandedGroups] = useState(new Set());
  const [sseStatus, setSseStatus] = useState('connecting');

  useEffect(() => {
    let mounted = true;
    
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (sseStatus === 'error') {
      console.error('Lost connection to server. Attempting to reconnect...');
    }
  }, [sseStatus]);

  // Update local state when props change
  useEffect(() => {
    setWorkOrders(initialWorkOrders);
  }, [initialWorkOrders]);

  // Function to get the date based on the current sort field
  const getDisplayDate = (workOrder) => {
    switch (sortConfig.field) {
      case 'dateCreated':
        return workOrder.createdAt;
      case 'dateRequested':
        return workOrder.dateRequested;
      case 'dateCompleted':
        return workOrder.dateCompleted;
      case 'dateScheduled':
      default:
        return workOrder.dateRequested || workOrder.dateScheduled;
    }
  };

  // Filter work orders based on user permissions
  const filteredWorkOrders = useMemo(() => {
    if (!currentUser) return [];
    
    if (currentUser.abilities?.includes('CanViewAllWorkOrders') || 
        currentUser.roles?.some(role => role.name === 'Admin')) {
      return workOrders;
    }
    
    return workOrders.filter(order => order.createdBy._id === currentUser.id);
  }, [workOrders, currentUser]);

  // Update the groupedWorkOrders to use filteredWorkOrders instead of workOrders
  const groupedWorkOrders = useMemo(() => {
    const groups = filteredWorkOrders.reduce((acc, order) => {
      const equipmentId = order.equipment?.ID;
      if (!equipmentId) return acc;
      
      if (!acc[equipmentId]) {
        acc[equipmentId] = [];
      }
      acc[equipmentId].push(order);
      return acc;
    }, {});

    // Sort orders within each group by date
    Object.values(groups).forEach(orders => {
      orders.sort((a, b) => {
        const dateA = getDisplayDate(a);
        const dateB = getDisplayDate(b);
        if (!dateA && !dateB) return 0;
        if (!dateA) return sortConfig.emptyPosition === 'top' ? -1 : 1;
        if (!dateB) return sortConfig.emptyPosition === 'top' ? 1 : -1;
        return sortConfig.direction === 'asc' ? 
          new Date(dateA) - new Date(dateB) : 
          new Date(dateB) - new Date(dateA);
      });
    });

    return groups;
  }, [filteredWorkOrders, sortConfig]);

  // Function to toggle group expansion
  const toggleGroup = (equipmentId) => {
    setExpandedGroups(prev => {
      const newSet = new Set(prev);
      if (newSet.has(equipmentId)) {
        newSet.delete(equipmentId);
      } else {
        newSet.add(equipmentId);
      }
      return newSet;
    });
  };

  // Function to determine date color
  const getDateColor = (date) => {
    if (!date) return 'text-gray-500';
    const now = new Date();
    now.setHours(0, 0, 0, 0); // Set to start of day for accurate comparison
    const targetDate = new Date(date);
    targetDate.setHours(0, 0, 0, 0);
    
    if (targetDate < now && sortConfig.field === 'dateScheduled') return 'text-red-500';
    return 'text-gray-700';
  };

  // Function to get the date label based on the current sort field
  const getDateLabel = (field) => {
    switch (field) {
      case 'dateCreated':
        return 'Creation Date';
      case 'dateRequested':
        return 'Requested Date';
      case 'dateCompleted':
        return 'Completion Date';
      case 'dateScheduled':
      default:
        return 'Scheduled Date';
    }
  };

  // Function to render a single work order
  const renderWorkOrder = (workOrder, isNested = false) => {
    const displayDate = getDisplayDate(workOrder);
    return (
      <li 
        key={workOrder._id} 
        onClick={() => onSelectWorkOrder(workOrder)} 
        className={`
          relative flex justify-between items-center p-1 border-b border-gray-200 cursor-pointer
          hover:bg-gray-50 overflow-hidden
          ${isNested ? 'pl-6' : ''}
          ${selectedWorkOrder && selectedWorkOrder._id === workOrder._id ? 'bg-gray-100' : ''}
        `}
      >
        <div className="flex items-center gap-2 flex-1">
          {!isNested && (
            <span className="min-w-[20px] font-bold text-lg">{workOrder.equipment?.ID}</span>
          )}
          <span className="text-sm flex-1">{workOrder.title}</span>
        </div>
        <span className={`text-sm font-medium ${getDateColor(displayDate)}`}>
          {displayDate ? 
            new Date(displayDate).toLocaleDateString() : 'N/A'}
        </span>
        
        {workOrder.status === 'closed' && (
          <div className="absolute top-1/2 left-[70%] -translate-y-1/2 -rotate-12 
                        text-lg font-bold text-red-500 opacity-75 pointer-events-none
                        border-2 border-red-500 rounded-xl px-2 bg-white">
            Closed
          </div>
        )}
        
        {workOrder.status === 'postponed' && (
          <div className="absolute top-1/2 left-[70%] -translate-y-1/2 -rotate-12 
                        text-lg font-bold text-blue-500 opacity-75 pointer-events-none
                        border-2 border-blue-500 rounded-xl px-2 bg-white">
            Postponed
          </div>
        )}
      </li>
    );
  };

  // Function to render a group of work orders
  const renderWorkOrderGroup = (equipmentId, orders) => {
    const isExpanded = expandedGroups.has(equipmentId);
    const firstOrder = orders[0];
    const displayDate = getDisplayDate(firstOrder);

    if (orders.length === 1) {
      return renderWorkOrder(firstOrder);
    }

    return (
      <div key={equipmentId} className="border-b border-gray-200">
        <li 
          className={`
            flex justify-between items-center p-1 cursor-pointer
            ${isExpanded ? 'bg-gray-50' : 'hover:bg-gray-50'}
          `}
          onClick={() => toggleGroup(equipmentId)}
        >
          <div className="flex items-center gap-2 flex-1">
            <span className="min-w-[20px] font-bold text-lg">{equipmentId}</span>
            <span className="text-sm">
              ({orders.length} orders) {isExpanded ? '▼' : '▶'}
            </span>
          </div>
          <span className={`text-sm font-medium ${getDateColor(displayDate)}`}>
            {displayDate ? 
              new Date(displayDate).toLocaleDateString() : 'N/A'}
          </span>
        </li>
        {isExpanded && (
          <div className="bg-white">
            {orders.map(order => renderWorkOrder(order, true))}
          </div>
        )}
      </div>
    );
  };

  // Add this function to handle local deletion
  const handleLocalDelete = (workOrderId) => {
    setWorkOrders(prev => prev.filter(order => order._id !== workOrderId));
  };

  // Pass handleLocalDelete to your delete button/function
  const handleDelete = async (workOrderId) => {
    try {
      await deleteWorkOrder(workOrderId); // Your API call
      handleLocalDelete(workOrderId); // Update local state immediately
    } catch (error) {
      console.error('Error deleting work order:', error);
      // Optionally refresh the list if delete fails
    }
  };

  return (
    <div className="w-full h-auto overflow-y-auto px-1">
      {!Array.isArray(filteredWorkOrders) ? (
        <p className="text-red-500">Error: Invalid work orders data</p>
      ) : filteredWorkOrders.length === 0 ? (
        <p className="text-gray-500 text-center py-4">No work orders found.</p>
      ) : (
        <>
          <ul className="list-none p-0 m-0">
            {Object.entries(groupedWorkOrders).map(([equipmentId, orders]) => 
              renderWorkOrderGroup(equipmentId, orders)
            )}
          </ul>
          <div ref={loadMoreRef} className="text-center py-2 text-gray-600 text-sm">
            {isLoading && <p>Loading more work orders...</p>}
            {!hasMore && filteredWorkOrders.length > 0 && (
              <p>No more work orders to load.</p>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default WorkOrderList;
