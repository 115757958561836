import React, { useState } from 'react';
import { FiCheck, FiX, FiArrowDown } from 'react-icons/fi';
import '../styles/PartSortModal.css';

const PartSortModal = ({ onApply, onClose, currentSort }) => {
  const [sortConfig, setSortConfig] = useState(currentSort || {
    field: 'partNumber',
    direction: 'asc'
  });
  
  const sortOptions = [
    { value: 'partNumber', label: 'Part Number' },
    { value: 'manufacturer', label: 'Manufacturer' },
    { value: 'category', label: 'Category' },
    { value: 'inventory.quantity', label: 'Stock Level' }
  ];

  const toggleDirection = () => {
    setSortConfig(prev => ({
      ...prev,
      direction: prev.direction === 'desc' ? 'asc' : 'desc'
    }));
  };

  return (
    <div className="modal-overlay">
      <div className="part-sort-modal">
        <h2>Sort Parts</h2>
        
        <div className="sort-section">
          <label>Sort By:</label>
          <select 
            value={sortConfig.field} 
            onChange={(e) => setSortConfig(prev => ({ ...prev, field: e.target.value }))}
          >
            {sortOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="sort-direction">
          <button 
            className="direction-button"
            onClick={toggleDirection}
          >
            <FiArrowDown 
              className={sortConfig.direction === 'desc' ? 'rotated' : ''} 
            />
            {sortConfig.direction === 'desc' ? 'Z to A' : 'A to Z'}
          </button>
        </div>

        <div className="modal-actions">
          <button onClick={() => onApply(sortConfig)} className="apply-button">
            <FiCheck /> Apply
          </button>
          <button onClick={onClose} className="cancel-button">
            <FiX /> Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default PartSortModal; 