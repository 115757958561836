import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FiMove } from 'react-icons/fi';

function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div ref={setNodeRef} style={style} className={isDragging ? 'bg-gray-100' : ''}>
      <div className="flex items-center">
        <div 
          {...attributes} 
          {...listeners}
          className="p-2 text-gray-500 hover:text-gray-700 cursor-grab active:cursor-grabbing touch-none"
        >
          <FiMove size={20} />
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default SortableItem;