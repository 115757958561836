import React, { useState, useEffect } from 'react';
import { FiEdit2, FiCheck, FiX, FiTruck, FiPackage, FiMapPin, FiHash, FiPlus, FiTrash2 } from 'react-icons/fi';
import { updatePart, deletePart } from '../services/partService';
import { getMaintenanceItems } from '../services/maintenanceItemService';
import { getEquipment, updateEquipmentParts } from '../services/equipmentService';
import '../styles/PartModal.css';

const UNIT_OPTIONS = [
  'Each',
  'Quart',
  'Gallon',
  'Liter',
  'Foot',
  'Inch',
  'Meter',
  'Millimeter',
  'Kilogram',
  'Pound',
  'Ounce'
];

const PartModal = ({ part, onClose, onUpdate, isInlineDisplay, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedPart, setEditedPart] = useState(null);
  const [error, setError] = useState('');
  const [mainCategories] = useState(['Fluid', 'Filter', 'Part']);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedMainCategory, setSelectedMainCategory] = useState(null);
  const [maintenanceItems, setMaintenanceItems] = useState([]);
  const [showAddEquipmentModal, setShowAddEquipmentModal] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [newApplication, setNewApplication] = useState({
    equipment: '',
    usage: '',
    quantity: 1,
    notes: '',
    unit: 'Each'
  });
  const [availableEquipment, setAvailableEquipment] = useState([]);
  const [editingApplication, setEditingApplication] = useState(null);
  const [editApplicationModal, setEditApplicationModal] = useState(false);

  useEffect(() => {
    setEditedPart({ ...part });
    setSelectedMainCategory(part?.mainCategory || 'Filter');
    setIsEditing(false);
    setError('');
  }, [part]);

  useEffect(() => {
    fetchMaintenanceItems();
  }, []);

  useEffect(() => {
    if (selectedMainCategory && maintenanceItems.length > 0) {
      updateSubCategories(selectedMainCategory, maintenanceItems);
    }
  }, [selectedMainCategory, maintenanceItems]);

  useEffect(() => {
    if (showAddEquipmentModal) {
      fetchAvailableEquipment();
    }
  }, [showAddEquipmentModal]);

  const fetchMaintenanceItems = async () => {
    try {
      const items = await getMaintenanceItems();
      const activeItems = items.filter(item => 
        item.category !== 'Service' && item.active
      );
      setMaintenanceItems(activeItems);
      
      if (part?.mainCategory) {
        updateSubCategories(part.mainCategory, activeItems);
      }
    } catch (error) {
      console.error('Error fetching maintenance items:', error);
    }
  };

  const updateSubCategories = (mainCategory, items = maintenanceItems) => {
    console.log('Updating subcategories for:', mainCategory);
    console.log('Available items:', items);
    
    const filteredItems = items.filter(
      item => item.category === mainCategory
    );
    const uniqueNames = [...new Set(filteredItems.map(item => item.name))];
    
    console.log('Filtered subcategories:', uniqueNames);
    setSubCategories(uniqueNames);
  };

  const handleMainCategoryChange = (category) => {
    console.log('Main category changed to:', category);
    setSelectedMainCategory(category);
    setEditedPart(prev => ({
      ...prev,
      mainCategory: category,
      category: ''
    }));
    
    if (category && maintenanceItems.length > 0) {
      updateSubCategories(category, maintenanceItems);
    }
  };

  const handleSubCategoryChange = (subCategory) => {
    console.log('Subcategory changed to:', subCategory);
    setEditedPart(prev => {
      const updated = {
        ...prev,
        category: subCategory,
        mainCategory: selectedMainCategory
      };
      console.log('Updated editedPart:', updated);
      return updated;
    });
  };

  const handleEdit = async () => {
    try {
      const { _id, ...updateData } = editedPart;
      
      // Ensure we're sending the correct category data
      const finalUpdateData = {
        ...updateData,
        mainCategory: selectedMainCategory,
        category: editedPart.category,
        description: editedPart.description || ''
      };

      // Remove any undefined values
      Object.keys(finalUpdateData).forEach(key => {
        if (finalUpdateData[key] === undefined) {
          delete finalUpdateData[key];
        }
      });

      console.log('Sending update with data:', finalUpdateData);

      const updatedPart = await updatePart(part._id, finalUpdateData);
      setIsEditing(false);
      
      // Update the local part data with the response from the server
      setEditedPart(updatedPart);
      
      // Call onUpdate with the updated part data
      onUpdate(updatedPart);
    } catch (error) {
      console.error('Error updating part:', error);
      setError('Failed to update part');
    }
  };

  const handleQuantityChange = async (app, newQuantity) => {
    try {
      const updatedApplications = editedPart.equipmentApplications.map(a =>
        a._id === app._id ? { ...a, quantity: newQuantity } : a
      );

      const updatedPart = await updatePart(part._id, {
        ...editedPart,
        equipmentApplications: updatedApplications
      });

      // Update both editedPart and the parent component
      setEditedPart(updatedPart);
      onUpdate(updatedPart);
    } catch (error) {
      console.error('Error updating quantity:', error);
      setError('Failed to update quantity');
    }
  };

  const handleAddEquipmentApplication = async () => {
    try {
      if (!newApplication.equipment || !newApplication.usage) {
        setError('Please select equipment and specify usage');
        return;
      }

      // Find the selected equipment details from availableEquipment
      const selectedEquipmentDetails = availableEquipment.find(
        eq => eq._id === newApplication.equipment
      );

      // Create the new application with the full equipment object
      const newEquipmentApp = {
        equipment: selectedEquipmentDetails, // Use full equipment object
        usage: newApplication.usage,
        quantity: newApplication.quantity,
        notes: newApplication.notes,
        unit: newApplication.unit
      };

      // Create updated applications array for Part
      const updatedApplications = [
        ...editedPart.equipmentApplications,
        newEquipmentApp
      ];

      // First update the Part
      const updatedPart = await updatePart(part._id, {
        ...editedPart,
        equipmentApplications: updatedApplications
      });

      // Then update the Equipment's previouslyUsedParts
      const equipmentUpdateData = {
        part: part._id,
        category: part.category,
        lastUsed: new Date(),
        useCount: 1,
        quantityUsed: newApplication.quantity,
        unit: newApplication.unit,
        notes: newApplication.notes
      };

      await updateEquipmentParts(newApplication.equipment, equipmentUpdateData);

      // Update both local states
      setEditedPart(updatedPart);
      onUpdate(updatedPart);
      
      // Reset the form and close modal
      setShowAddEquipmentModal(false);
      setNewApplication({
        equipment: '',
        usage: '',
        quantity: 1,
        notes: '',
        unit: 'Each'
      });

    } catch (error) {
      console.error('Error adding equipment application:', error);
      setError('Failed to add equipment application');
    }
  };

  const fetchAvailableEquipment = async () => {
    try {
      const equipment = await getEquipment();
      // Filter out equipment that already has this part
      const existingEquipmentIds = new Set(
        editedPart.equipmentApplications.map(app => app.equipment._id)
      );
      const availableEquipment = equipment.filter(
        eq => !existingEquipmentIds.has(eq._id)
      );
      setAvailableEquipment(availableEquipment);
    } catch (error) {
      console.error('Error fetching equipment:', error);
      setError('Failed to load available equipment');
    }
  };

  // Group equipment applications by usage type
  const groupedApplications = part.equipmentApplications?.reduce((acc, app) => {
    if (!acc[app.usage]) {
      acc[app.usage] = [];
    }
    acc[app.usage].push(app);
    return acc;
  }, {}) || {};

  const handleEditApplication = async (updatedApp) => {
    try {
      // Update Part
      const updatedApplications = editedPart.equipmentApplications.map(app =>
        app._id === updatedApp._id ? updatedApp : app
      );

      const updatedPart = await updatePart(part._id, {
        ...editedPart,
        equipmentApplications: updatedApplications
      });

      // Update Equipment's previouslyUsedParts
      const equipmentUpdateData = {
        isUpdate: true,
        part: part._id,
        category: part.category,
        quantityUsed: updatedApp.quantity,
        unit: updatedApp.unit || 'Each',
        notes: updatedApp.notes || ''
      };

      await updateEquipmentParts(updatedApp.equipment._id, equipmentUpdateData);

      // Update local state
      setEditedPart(updatedPart);
      onUpdate(updatedPart);
      setEditingApplication(null);
      setEditApplicationModal(false);

    } catch (error) {
      console.error('Error updating application:', error);
      setError('Failed to update equipment application');
    }
  };

  const handleDeleteApplication = async (application) => {
    try {
      if (!window.confirm('Are you sure you want to remove this equipment application?')) {
        return;
      }

      // Remove from Part's applications
      const updatedApplications = editedPart.equipmentApplications.filter(
        app => app._id !== application._id
      );

      const updatedPart = await updatePart(part._id, {
        ...editedPart,
        equipmentApplications: updatedApplications
      });

      // Remove from Equipment's previouslyUsedParts
      const equipmentUpdateData = {
        isDelete: true,
        part: part._id,
        category: part.category
      };

      await updateEquipmentParts(application.equipment._id, equipmentUpdateData);

      // Update local state
      setEditedPart(updatedPart);
      onUpdate(updatedPart);
      setEditingApplication(null);
      setEditApplicationModal(false);

    } catch (error) {
      console.error('Error deleting application:', error);
      setError('Failed to delete equipment application');
    }
  };

  // Add this new function to handle part deletion
  const handleDeletePart = async () => {
    if (!window.confirm('Are you sure you want to delete this part? This action cannot be undone.')) {
      return;
    }

    try {
      await deletePart(part._id);
      onDelete?.(part._id);
      onClose();
    } catch (error) {
      console.error('Error deleting part:', error);
      setError('Failed to delete part');
    }
  };

  return (
    <div className={`part-modal ${isInlineDisplay ? 'inline' : 'overlay'}`}>
      <div className="part-modal-content">
        <div className="part-modal-header">
          <div className="header-info">
            <h2>{part.manufacturer} - {part.partNumber}</h2>
            <span className="category-badge">{part.category}</span>
          </div>
          <div className="header-actions">
            {isEditing ? (
              <>
                <button 
                  onClick={handleEdit} 
                  className="save-button"
                  title="Save changes"
                >
                  <FiCheck /> Save
                </button>
                <button 
                  onClick={handleDeletePart}
                  className="delete-button"
                  title="Delete part"
                >
                  <FiTrash2 /> Delete
                </button>
                <button 
                  onClick={() => setIsEditing(false)} 
                  className="cancel-button"
                  title="Cancel editing"
                >
                  <FiX /> Cancel
                </button>
              </>
            ) : (
              <button 
                className="edit-button"
                onClick={() => setIsEditing(true)}
                title="Edit part"
              >
                <FiEdit2 />
              </button>
            )}
            {!isInlineDisplay && (
              <button 
                className="close-button" 
                onClick={onClose} 
                title="Close"
              >
                <FiX />
              </button>
            )}
          </div>
        </div>

        {error && <div className="error-message">{error}</div>}

        <div className="part-details">
          <div className="detail-section">
            <h3>Description</h3>
            {isEditing ? (
              <textarea
                value={editedPart.description || ''}
                onChange={e => setEditedPart(prev => ({
                  ...prev,
                  description: e.target.value
                }))}
                rows={3}
                placeholder="Enter part description"
              />
            ) : (
              <p>{part.description || 'No description available'}</p>
            )}
          </div>

          <div className="detail-section">
            <h3>Classification</h3>
            {isEditing ? (
              <div className="category-selects">
                <div className="select-group">
                  <label>Main Category</label>
                  <select
                    value={selectedMainCategory}
                    onChange={e => handleMainCategoryChange(e.target.value)}
                  >
                    {mainCategories.map(category => (
                      <option key={category} value={category}>{category}</option>
                    ))}
                  </select>
                </div>
                <div className="select-group">
                  <label>Sub Category</label>
                  <select
                    value={editedPart?.category || ''}
                    onChange={e => handleSubCategoryChange(e.target.value)}
                  >
                    <option value="">Select Subcategory</option>
                    {subCategories.map(category => (
                      <option key={category} value={category}>{category}</option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (
              <div className="category-display">
                <span className="main-category">{part.mainCategory}</span>
                <span className="separator">›</span>
                <span className="sub-category">{part.category}</span>
              </div>
            )}
          </div>

          <div className="detail-section">
            <h3>Inventory Details</h3>
            <div className="inventory-grid">
              <div className="inventory-item">
                <FiPackage className="icon" />
                <div className="inventory-content">
                  <label>Current Stock</label>
                  {isEditing ? (
                    <input
                      type="number"
                      value={editedPart.inventory?.quantity || 0}
                      onChange={e => setEditedPart({
                        ...editedPart,
                        inventory: { 
                          ...editedPart.inventory, 
                          quantity: Number(e.target.value) 
                        }
                      })}
                    />
                  ) : (
                    <span>{part.inventory?.quantity || 0} {part.unit}</span>
                  )}
                </div>
              </div>

              <div className="inventory-item">
                <FiHash className="icon" />
                <div className="inventory-content">
                  <label>Minimum Stock</label>
                  {isEditing ? (
                    <input
                      type="number"
                      value={editedPart.inventory?.minimumQuantity || 0}
                      onChange={e => setEditedPart({
                        ...editedPart,
                        inventory: { 
                          ...editedPart.inventory, 
                          minimumQuantity: Number(e.target.value) 
                        }
                      })}
                    />
                  ) : (
                    <span>{part.inventory?.minimumQuantity || 0} {part.unit}</span>
                  )}
                </div>
              </div>

              <div className="inventory-item">
                <FiMapPin className="icon" />
                <div className="inventory-content">
                  <label>Location</label>
                  {isEditing ? (
                    <input
                      type="text"
                      value={editedPart.inventory?.location || ''}
                      onChange={e => setEditedPart({
                        ...editedPart,
                        inventory: { 
                          ...editedPart.inventory, 
                          location: e.target.value 
                        }
                      })}
                    />
                  ) : (
                    <span>{part.inventory?.location || 'Not specified'}</span>
                  )}
                </div>
              </div>

              <div className="inventory-item">
                <FiHash className="icon" />
                <div className="inventory-content">
                  <label>Bin Number</label>
                  {isEditing ? (
                    <input
                      type="text"
                      value={editedPart.inventory?.binNumber || ''}
                      onChange={e => setEditedPart({
                        ...editedPart,
                        inventory: { 
                          ...editedPart.inventory, 
                          binNumber: e.target.value 
                        }
                      })}
                    />
                  ) : (
                    <span>{part.inventory?.binNumber || 'Not specified'}</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="detail-section">
            <div className="section-header">
              <h3>Equipment Applications</h3>
              <button 
                className="add-equipment-button"
                onClick={() => setShowAddEquipmentModal(true)}
                title="Add Equipment"
              >
                <FiPlus />
              </button>
            </div>
            
            {Object.entries(groupedApplications).length > 0 ? (
              <div className="applications-list">
                {Object.entries(groupedApplications).map(([usage, applications]) => (
                  <div key={usage} className="application-group">
                    <h4>{usage}</h4>
                    <div className="application-items">
                      {applications.map((app, index) => (
                        <div 
                          key={index} 
                          className="application-item"
                          onClick={() => {
                            setEditingApplication(app);
                            setEditApplicationModal(true);
                          }}
                        >
                          <FiTruck className="icon" />
                          <div className="application-details">
                            <span className="equipment-id">{app.equipment?.ID || 'Unknown Equipment'}</span>
                            <div className="quantity-control">
                              <span>Quantity: {app.quantity} {app.unit || 'Each'}</span>
                            </div>
                            <span className="usage">Usage: {app.usage}</span>
                            {app.notes && <span className="notes">{app.notes}</span>}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="no-applications">No equipment applications found</p>
            )}
          </div>

          {isEditing && (
            <div className="edit-actions">
              <button onClick={handleEdit} className="save-button">
                <FiCheck /> Save Changes
              </button>
              <button onClick={() => setIsEditing(false)} className="cancel-button">
                <FiX /> Cancel
              </button>
            </div>
          )}
        </div>
      </div>

      {showAddEquipmentModal && (
        <div className="modal-overlay" onClick={() => setShowAddEquipmentModal(false)}>
          <div className="add-equipment-modal" onClick={e => e.stopPropagation()}>
            <h3>Add Equipment Application</h3>
            <div className="form-group">
              <label>Equipment</label>
              <select
                value={newApplication.equipment}
                onChange={(e) => setNewApplication(prev => ({
                  ...prev,
                  equipment: e.target.value
                }))}
                required
              >
                <option value="">Select Equipment</option>
                {availableEquipment.map(eq => (
                  <option key={eq._id} value={eq._id}>
                    {eq.ID} - {eq.Make} {eq.Model}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Usage</label>
              <input
                type="text"
                value={newApplication.usage}
                onChange={(e) => setNewApplication(prev => ({
                  ...prev,
                  usage: e.target.value
                }))}
                placeholder="How is this part used?"
                required
              />
            </div>
            <div className="form-group">
              <label>Quantity</label>
              <input
                type="number"
                min="1"
                value={newApplication.quantity}
                onChange={(e) => setNewApplication(prev => ({
                  ...prev,
                  quantity: Number(e.target.value)
                }))}
                required
              />
            </div>
            <div className="form-group">
              <label>Notes</label>
              <textarea
                value={newApplication.notes}
                onChange={(e) => setNewApplication(prev => ({
                  ...prev,
                  notes: e.target.value
                }))}
                placeholder="Optional notes"
              />
            </div>
            <div className="form-group">
              <label>Unit</label>
              <select
                value={newApplication.unit}
                onChange={(e) => setNewApplication(prev => ({
                  ...prev,
                  unit: e.target.value
                }))}
                required
              >
                {UNIT_OPTIONS.map(unit => (
                  <option key={unit} value={unit}>{unit}</option>
                ))}
              </select>
            </div>
            <div className="modal-actions">
              <button onClick={handleAddEquipmentApplication} className="save-button">
                Add Application
              </button>
              <button 
                onClick={() => setShowAddEquipmentModal(false)} 
                className="cancel-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {editApplicationModal && editingApplication && (
        <div className="modal-overlay" onClick={() => setEditApplicationModal(false)}>
          <div className="edit-application-modal" onClick={e => e.stopPropagation()}>
            <div className="modal-header">
              <h3>Edit Equipment Application</h3>
              <button 
                onClick={() => handleDeleteApplication(editingApplication)}
                className="delete-button"
                title="Delete application"
              >
                <FiTrash2 />
              </button>
            </div>
            <div className="form-group">
              <label>Equipment</label>
              <input
                type="text"
                value={`${editingApplication.equipment?.ID} - ${editingApplication.equipment?.Make} ${editingApplication.equipment?.Model}`}
                disabled
              />
            </div>
            <div className="form-group">
              <label>Usage</label>
              <input
                type="text"
                value={editingApplication.usage}
                onChange={(e) => setEditingApplication(prev => ({
                  ...prev,
                  usage: e.target.value
                }))}
                required
              />
            </div>
            <div className="form-group">
              <label>Quantity</label>
              <input
                type="number"
                min="1"
                value={editingApplication.quantity}
                onChange={(e) => setEditingApplication(prev => ({
                  ...prev,
                  quantity: Number(e.target.value)
                }))}
                required
              />
            </div>
            <div className="form-group">
              <label>Unit</label>
              <select
                value={editingApplication.unit}
                onChange={(e) => setEditingApplication(prev => ({
                  ...prev,
                  unit: e.target.value
                }))}
                required
              >
                {UNIT_OPTIONS.map(unit => (
                  <option key={unit} value={unit}>{unit}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Notes</label>
              <textarea
                value={editingApplication.notes || ''}
                onChange={(e) => setEditingApplication(prev => ({
                  ...prev,
                  notes: e.target.value
                }))}
                placeholder="Optional notes"
              />
            </div>
            <div className="modal-actions">
              <button 
                onClick={() => handleEditApplication(editingApplication)} 
                className="save-button"
              >
                Save Changes
              </button>
              <button 
                onClick={() => {
                  setEditApplicationModal(false);
                  setEditingApplication(null);
                }} 
                className="cancel-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PartModal; 