import React, { useState, useEffect } from 'react';
import { getEquipment, createEquipment, getEquipmentTypes } from '../services/equipmentService';
import { getLocations } from '../services/locationService';
import { getUsers } from '../services/adminService';
import { createWorkOrder } from '../services/workOrderService';
import { useAuth } from '../hooks/useAuth';
import { checkAbilityPermissions } from '../services/authService';
import { toast } from 'react-toastify';
import { getLatestMeterReading } from '../services/meterReadingService';
import MaintenanceScheduleSection from './MaintenanceScheduleSection';

function WorkOrderForm({ onClose, onWorkOrderAdded }) {
  const { user } = useAuth();
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [formData, setFormData] = useState({
    Type: '',
    ID: '',
    newID: '',
    Category: '',
    location: '',
    offSiteLocation: { address: '', city: '', state: '', latitude: null, longitude: null },
    hours: '',
    odometer: '',
    title: '',
    description: '',
    dateRequested: '',
    usesHours: true,
    usesOdometer: true
  });
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [error, setError] = useState('');
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [canCreateAsOther, setCanCreateAsOther] = useState(false);
  const [meterPlaceholders, setMeterPlaceholders] = useState({
    hours: '',
    odometer: ''
  });
  const [showNewEquipmentFields, setShowNewEquipmentFields] = useState(false);

  const fetchUsers = async () => {
    try {
      const response = await getUsers();
      if (response && response.data) {
        setUserList(response.data);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users.');
    }
  };

  useEffect(() => {
    fetchEquipmentTypes();
    fetchEquipment();
    fetchLocations();
    if (user) {
      checkPermissions();
    }
  }, [user]);

  const fetchEquipmentTypes = async () => {
    try {
      const types = await getEquipmentTypes();
      setEquipmentTypes(types);
    } catch (err) {
      console.error('Error fetching equipment types:', err);
      setError('Failed to fetch equipment types.');
    }
  };

  const fetchEquipment = async () => {
    try {
      const equipment = await getEquipment();
      setEquipmentList(equipment);
    } catch (err) {
      console.error('Error fetching equipment:', err);
      setError('Failed to fetch equipment.');
    }
  };

  const fetchLocations = async () => {
    try {
      const locations = await getLocations();
      setLocationList(locations);
    } catch (err) {
      setError('Failed to fetch locations.');
    }
  };

  const checkPermissions = async () => {
    const createAsOtherPermission = await checkAbilityPermissions('CanCreateWorkOrderAsOtherUser');
    setCanCreateAsOther(createAsOtherPermission);
    if (createAsOtherPermission) {
      fetchUsers();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'ID' && value === 'new') {
      setShowNewEquipmentFields(true);
    } else if (name === 'ID') {
      setShowNewEquipmentFields(false);
    }

    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  // Add handleSubmit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const workOrderData = {
        type: formData.Type,
        equipment: formData.ID === 'new' ? 'new' : formData.ID,
        newEquipmentData: formData.ID === 'new' ? {
          ID: formData.newID,
          Type: formData.Type,
          Category: formData.Category,
          Make: 'Unknown',
          Model: 'Unknown',
          Year: null
        } : null,
        location: formData.location,
        offSiteLocation: formData.location === 'Off-Site' ? formData.offSiteLocation : null,
        hours: formData.hours || null,
        odometer: formData.odometer || null,
        title: formData.title,
        description: formData.description,
        dateRequested: formData.dateRequested || null,
        createdBy: selectedUser?._id
      };

      // Validate required fields for new equipment
      if (formData.ID === 'new') {
        if (!formData.newID || !formData.Type || !formData.Category) {
          throw new Error('Please fill in all required fields for new equipment');
        }
      }

      console.log('Submitting work order data:', workOrderData);

      await createWorkOrder(workOrderData);
      toast.success('Work order created successfully!');
      onWorkOrderAdded();
    } catch (err) {
      console.error('Error creating work order:', err);
      if (err.response) {
        console.error('Error response:', err.response.data);
        setError(err.response.data.message || 'Failed to create work order');
      } else {
        setError(err.message || 'Failed to create work order');
      }
      toast.error('Failed to create work order');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[12000]">
      <div className="bg-white rounded-lg p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <h2 className="text-xl font-bold mb-4">Create New Work Order</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {error && <p className="text-red-500">{error}</p>}
          
          {/* Equipment Type Selection */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Equipment Type
            </label>
            <select
              name="Type"
              value={formData.Type}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md p-2"
              required
            >
              <option value="">Select Type</option>
              {equipmentTypes.map(type => (
                <option key={type._id} value={type._id}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>

          {/* Equipment Selection with Add New option */}
          {formData.Type && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Equipment
              </label>
              <select
                name="ID"
                value={formData.ID}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md p-2"
                required
              >
                <option value="">Select Equipment</option>
                <option value="new" className="font-semibold text-blue-600">
                  + Add New Equipment
                </option>
                {equipmentList
                  .filter(eq => eq.Type?._id === formData.Type)
                  .map(eq => (
                    <option key={eq._id} value={eq._id}>
                      {eq.ID} - {eq.Make} {eq.Model}
                    </option>
                  ))}
              </select>
            </div>
          )}

          {/* New Equipment Fields */}
          {showNewEquipmentFields && (
            <div className="space-y-4 p-4 bg-gray-50 rounded-md">
              <h3 className="font-medium text-gray-700">New Equipment Details</h3>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Equipment ID
                </label>
                <input
                  type="text"
                  name="newID"
                  value={formData.newID}
                  onChange={handleChange}
                  className="w-full border border-gray-300 rounded-md p-2"
                  required
                  placeholder="Enter Equipment ID"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Category
                </label>
                <select
                  name="Category"
                  value={formData.Category}
                  onChange={handleChange}
                  className="w-full border border-gray-300 rounded-md p-2"
                  required
                >
                  <option value="">Select Category</option>
                  {equipmentTypes
                    .find(type => type._id === formData.Type)
                    ?.categories.map(category => (
                      <option key={category._id} value={category._id}>
                        {category.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          )}

          {/* Location Selection */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Location
            </label>
            <select
              name="location"
              value={formData.location}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md p-2"
              required
            >
              <option value="">Select Location</option>
              {locationList.map(location => (
                <option key={location._id} value={location._id}>
                  {location.name}
                </option>
              ))}
              <option value="Off-Site">Off-Site</option>
            </select>
          </div>

          {/* Off-Site Location Details */}
          {formData.location === 'Off-Site' && (
            <div className="space-y-2">
              <input
                type="text"
                name="offSiteLocation.address"
                value={formData.offSiteLocation.address}
                onChange={handleChange}
                placeholder="Address"
                className="w-full border border-gray-300 rounded-md p-2"
                required
              />
              <div className="grid grid-cols-2 gap-2">
                <input
                  type="text"
                  name="offSiteLocation.city"
                  value={formData.offSiteLocation.city}
                  onChange={handleChange}
                  placeholder="City"
                  className="border border-gray-300 rounded-md p-2"
                  required
                />
                <input
                  type="text"
                  name="offSiteLocation.state"
                  value={formData.offSiteLocation.state}
                  onChange={handleChange}
                  placeholder="State"
                  className="border border-gray-300 rounded-md p-2"
                  required
                />
              </div>
            </div>
          )}

          {/* Title and Description */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Title
            </label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md p-2"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md p-2 min-h-[100px]"
            />
          </div>

          {/* Add User Selection for users with permission */}
          {canCreateAsOther && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Create For User
              </label>
              <select
                value={selectedUser?._id || ''}
                onChange={(e) => {
                  const user = userList.find(u => u._id === e.target.value);
                  setSelectedUser(user || null);
                }}
                className="w-full border border-gray-300 rounded-md p-2"
              >
                <option value="">Select User</option>
                {userList.map(user => (
                  <option key={user._id} value={user._id}>
                    {user.displayName || user.username}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Requested Date */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Requested Date
            </label>
            <input
              type="date"
              name="dateRequested"
              value={formData.dateRequested}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md p-2"
              min={new Date().toISOString().split('T')[0]}
            />
          </div>

          {/* Updated Action Buttons - using grid for better alignment */}
          <div className="grid grid-cols-12 gap-4 pt-6 mt-6 border-t border-gray-200">
            <button
              type="button"
              onClick={onClose}
              className="col-span-3 h-10 text-sm font-medium text-white bg-red-600 border border-transparent 
                       rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 
                       focus:ring-offset-2 focus:ring-red-500 transition-colors duration-200
                       flex items-center justify-center"
            >
              Cancel
            </button>
            <div className="col-span-6"></div> {/* Spacer */}
            <button
              type="submit"
              className="col-span-3 h-10 text-sm font-medium text-white bg-blue-600 border border-transparent 
                       rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 
                       focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200
                       flex items-center justify-center"
            >
              Submit Work Order
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default WorkOrderForm;
