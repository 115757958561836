import React, { useState, useEffect, useRef } from 'react';
import { getEquipment, getEquipmentById } from '../services/equipmentService';
import { RiPlayListAddFill, RiDeleteRow } from 'react-icons/ri';
import { FaRegCheckSquare } from 'react-icons/fa';
import { MdOutlineLibraryAddCheck } from 'react-icons/md';

import { useAuth } from '../hooks/useAuth';
import { checkRoles } from '../services/authService';
import { updateEquipmentMeters } from '../services/equipmentService';
import { updateUserTrackedEquipment } from '../services/userService';
import { 
  addMeterReading, 
  getLatestMeterReading,
  getMeterReadings
} from '../services/meterReadingService';
import '../styles/UpdateMeters.css';

function UpdateMeters() {
  const { user, updateUserData } = useAuth();
  const [selectedEquipment, setSelectedEquipment] = useState([]);
  const [showSelectionModal, setShowSelectionModal] = useState(false);
  const [error, setError] = useState('');
  const [showHoursColumn, setShowHoursColumn] = useState(false);
  const [showOdometerColumn, setShowOdometerColumn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [allEquipment, setAllEquipment] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [equipmentLocations, setEquipmentLocations] = useState([]);
  const rowRefs = useRef({});
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    const fetchAllData = async () => {
      console.log('Fetching data with user:', user);
      if (!user) {
        console.log('No user data available');
        return;
      }
      
      setIsLoading(true);
      try {
        console.log('Fetching equipment data...');
        const equipmentData = await getEquipment();
        console.log('Equipment data received:', equipmentData);
        setAllEquipment(equipmentData);
        
        const uniqueTypes = [...new Set(equipmentData
          .filter(item => item.Type?.name)
          .map(item => item.Type?.name))]
          .filter(Boolean);
          
        const uniqueLocations = [...new Set(equipmentData
          .filter(item => item.Location?.name)
          .map(item => item.Location?.name))]
          .filter(Boolean);
        
        setEquipmentTypes(uniqueTypes);
        setEquipmentLocations(uniqueLocations);

        if (user.trackedEquipment?.length) {
          console.log('User tracked equipment:', user.trackedEquipment);
          const trackedIds = user.trackedEquipment.map(item => 
            typeof item === 'object' ? item._id : item
          );
          console.log('Tracked IDs:', trackedIds);

          const trackedEquipment = equipmentData.filter(eq => 
            trackedIds.includes(eq._id)
          );

          console.log('Matched tracked equipment:', trackedEquipment);
          
          if (trackedEquipment.length > 0) {
            setSelectedEquipment(trackedEquipment);

            const hasHours = trackedEquipment.some(eq => !eq.ignoreHours);
            const hasOdometer = trackedEquipment.some(eq => !eq.ignoreOdometer);
            setShowHoursColumn(hasHours);
            setShowOdometerColumn(hasOdometer);
          } else {
            console.log('No matching equipment found');
          }
        } else {
          console.log('No tracked equipment in user data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load equipment data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, [user?.trackedEquipment]);

  const handleSaveSelection = async (newSelection) => {
    try {
      setIsLoading(true);
      
      const updatedUser = await updateUserTrackedEquipment(user.id, newSelection);
      updateUserData(updatedUser);
      
      const selectedEquipmentData = allEquipment.filter(eq => 
        newSelection.includes(eq._id)
      );

      setSelectedEquipment(selectedEquipmentData);

      const hasHours = selectedEquipmentData.some(eq => !eq.ignoreHours);
      const hasOdometer = selectedEquipmentData.some(eq => !eq.ignoreOdometer);
      setShowHoursColumn(hasHours);
      setShowOdometerColumn(hasOdometer);

      setShowSelectionModal(false);
    } catch (err) {
      console.error('Error saving equipment selection:', err);
      setError('Failed to save equipment selection');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveEquipment = async (equipmentId) => {
    try {
      const newSelection = selectedEquipment.filter(eq => eq._id !== equipmentId);
      const newSelectionIds = newSelection.map(eq => eq._id);
      
      const updatedUser = await updateUserTrackedEquipment(user.id, newSelectionIds);
      updateUserData(updatedUser);

      setSelectedEquipment(newSelection);

      const hasHours = newSelection.some(eq => !eq.ignoreHours);
      const hasOdometer = newSelection.some(eq => !eq.ignoreOdometer);
      setShowHoursColumn(hasHours);
      setShowOdometerColumn(hasOdometer);
    } catch (error) {
      console.error('Error removing equipment:', error);
      setError('Failed to remove equipment');
    }
  };

  const handleUpdateAll = async () => {
    try {
      const updatePromises = selectedEquipment.map(equipmentId => {
        const rowRef = rowRefs.current[equipmentId];
        if (rowRef && rowRef.handleUpdate) {
          return rowRef.handleUpdate();
        }
        return Promise.resolve();
      });

      await Promise.all(updatePromises);
    } catch (error) {
      setError('Failed to update all equipment');
    }
  };

  const checkForChanges = () => {
    const changes = Object.values(rowRefs.current).some(ref => {
      return ref && ref.hasChanges && ref.hasChanges();
    });
    setHasChanges(changes);
  };

  if (isLoading) {
    return <div className="loading-message">Loading equipment data...</div>;
  }

  return (
    <div className="update-meters-container">
      <div className="update-meters-header">
        <h2>Update Meters</h2>
      </div>

      {error && <div className="error-message">{error}</div>}

      <div className="update-meters-table">
        <table style={{ 
          width: '100%',
          borderCollapse: 'collapse',
          tableLayout: 'fixed'
        }}>
          <thead>
            <tr style={{ 
              borderBottom: '2px solid #eee'
            }}>
              <th style={{ 
                padding: '5px',
                textAlign: 'left',
                color: '#333',
                backgroundColor: '#f8f8f8',
                width: '120px'
              }}>
                Equipment ID
              </th>
              <th style={{ 
                padding: '5px',
                textAlign: 'left',
                color: '#333',
                backgroundColor: '#f8f8f8'
              }}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  <span>Equipment Status</span>
                  <button 
                    onClick={handleUpdateAll}
                    disabled={!hasChanges}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '4px',
                      backgroundColor: 'transparent',
                      color: hasChanges ? '#4CAF50' : '#999',
                      border: 'none',
                      borderRadius: '4px',
                      cursor: hasChanges ? 'pointer' : 'default',
                      opacity: hasChanges ? 1 : 0.5,
                      transition: 'all 0.2s ease'
                    }}
                    title="Update All Readings"
                  >
                    <MdOutlineLibraryAddCheck size={24} />
                  </button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedEquipment.map(equipment => (
              <EquipmentRow 
                key={equipment._id}
                equipmentId={equipment._id}
                onRemove={handleRemoveEquipment}
                onChangeStatus={checkForChanges}
                ref={el => {
                  if (el) {
                    rowRefs.current[equipment._id] = el;
                  }
                }}
              />
            ))}
          </tbody>
        </table>
      </div>

      <div style={{ 
        display: 'flex', 
        justifyContent: 'flex-start',
        marginTop: '16px',
        paddingLeft: '16px'  // Align with the Equipment ID column
      }}>
        <button
          onClick={() => setShowSelectionModal(true)}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            padding: '8px 16px',
            backgroundColor: 'transparent',
            color: '#4CAF50',
            border: '2px solid #4CAF50',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '14px',
            transition: 'all 0.2s ease',
            hover: {
              backgroundColor: '#4CAF50',
              color: 'white'
            }
          }}
        >
          <RiPlayListAddFill size={20} />
          <span>Add Equipment</span>
        </button>
      </div>

      {showSelectionModal && (
        <EquipmentSelectionModal
          onClose={() => setShowSelectionModal(false)}
          onSave={handleSaveSelection}
          onRemove={handleRemoveEquipment}
          currentSelection={selectedEquipment}
          equipment={allEquipment}
          types={equipmentTypes}
          locations={equipmentLocations}
        />
      )}
    </div>
  );
}

const EquipmentRow = React.forwardRef(({ equipmentId, onRemove, onChangeStatus }, ref) => {
  const [equipment, setEquipment] = useState(null);
  const [hours, setHours] = useState('');
  const [odometer, setOdometer] = useState('');
  const [originalHours, setOriginalHours] = useState('');
  const [originalOdometer, setOriginalOdometer] = useState('');
  const [loading, setLoading] = useState(true);
  const [lastReadings, setLastReadings] = useState({ hours: null, odometer: null });
  const [maintenanceHistory, setMaintenanceHistory] = useState({});

  useEffect(() => {
    const fetchEquipmentAndReadings = async () => {
      setLoading(true);
      try {
        let equipData;
        if (typeof equipmentId === 'object') {
          equipData = equipmentId;
        } else {
          const id = typeof equipmentId === 'object' ? equipmentId._id : equipmentId;
          equipData = await getEquipmentById(id);
        }
        
        if (!equipData) {
          console.error('No equipment data received for:', equipmentId);
          return;
        }

        // Get all readings to find maintenance history and last readings
        const readings = await getMeterReadings(equipData._id);
        
        // Find the last non-zero readings for placeholders
        const lastNonZeroHours = readings
          .filter(r => r.readings?.hours > 0)
          .sort((a, b) => new Date(b.date) - new Date(a.date))[0]?.readings?.hours;
        
        const lastNonZeroOdometer = readings
          .filter(r => r.readings?.odometer > 0)
          .sort((a, b) => new Date(b.date) - new Date(a.date))[0]?.readings?.odometer;

        setLastReadings({
          hours: lastNonZeroHours || null,
          odometer: lastNonZeroOdometer || null
        });
        
        // Process maintenance history
        const history = {};
        readings.forEach(reading => {
          if (reading.maintenanceDetails?.completedChecklist) {
            reading.maintenanceDetails.completedChecklist.forEach(item => {
              if (!history[item.item] || new Date(reading.date) > new Date(history[item.item].date)) {
                history[item.item] = {
                  date: reading.date,
                  hours: reading.readings.hours,
                  odometer: reading.readings.odometer
                };
              }
            });
          }
        });

        setMaintenanceHistory(history);
        setEquipment(equipData);
        
        // Initialize empty values but keep track of original state
        if (!equipData.ignoreHours) {
          setHours('');
          setOriginalHours('');
        }
        
        if (!equipData.ignoreOdometer) {
          setOdometer('');
          setOriginalOdometer('');
        }

      } catch (error) {
        console.error('Error fetching equipment and readings:', error);
      } finally {
        setLoading(false);
      }
    };

    if (equipmentId) {
      fetchEquipmentAndReadings();
    }
  }, [equipmentId]);

  const handleUpdate = async () => {
    try {
      if ((hours !== originalHours && !equipment.ignoreHours) || 
          (odometer !== originalOdometer && !equipment.ignoreOdometer)) {
        
        const meterReadingData = {
          type: 'Meter Check',
          readings: {
            hours: !equipment.ignoreHours ? Number(hours) || null : null,
            odometer: !equipment.ignoreOdometer ? Number(odometer) || null : null
          },
          notes: 'Updated via meters page',
          maintenanceDetails: {
            fluidsAndFilters: [],
            completedChecklist: []
          }
        };

        console.log('Creating meter reading with data:', JSON.stringify(meterReadingData, null, 2));
        
        await addMeterReading(equipment._id, meterReadingData);

        const updateData = {
          hours: hours !== originalHours ? Number(hours) || null : undefined,
          odometer: odometer !== originalOdometer ? Number(odometer) || null : undefined
        };

        console.log('Updating equipment meters with:', updateData);
        
        await updateEquipmentMeters(equipment._id, updateData);

        setEquipment(prevEquipment => ({ 
          ...prevEquipment, 
          Hours: Number(hours) || null, 
          Odometer: Number(odometer) || null 
        }));
        setOriginalHours(hours);
        setOriginalOdometer(odometer);
      }
      
      return Promise.resolve();
    } catch (error) {
      console.error('Error updating equipment:', error);
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    onChangeStatus?.();
  }, [hours, odometer, onChangeStatus]);

  const hasChanges = () => {
    return hours !== originalHours || odometer !== originalOdometer;
  };

  React.useImperativeHandle(ref, () => ({
    handleUpdate,
    hasChanges
  }));

  const renderReadingInput = (type, value, setValue, ignored, originalValue) => {
    if (ignored) return null;
    
    const hasChanged = value !== originalValue;
    const lastReading = type === 'Hours' ? lastReadings.hours : lastReadings.odometer;
    const placeholderText = lastReading ? `Last: ${parseInt(lastReading).toLocaleString()}` : '';

    return (
      <div style={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: '8px',
        width: '100%',
        maxWidth: '400px',
        height: '32px',
        marginBottom: '8px'
      }}>
        <span style={{
          minWidth: '65px',
          fontSize: '14px',
          color: '#666'
        }}>
          {type}:
        </span>
        <div style={{ position: 'relative', flex: 1 }}>
          <input
            type="number"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            style={{
              width: '100%',
              height: '28px',
              padding: '4px 8px',
              border: '1px solid #ddd',
              borderRadius: '4px',
              fontSize: '14px',
              backgroundColor: 'white'
            }}
          />
          {!value && placeholderText && (
            <span style={{
              position: 'absolute',
              left: '8px',
              top: '50%',
              transform: 'translateY(-50%)',
              color: '#999',
              fontSize: '14px',
              pointerEvents: 'none',
              userSelect: 'none'
            }}>
              {placeholderText}
            </span>
          )}
        </div>
      </div>
    );
  };

  const getNextDueText = (schedule, equipment) => {
    const parts = [];
    
    // Map maintenance types to their checklist items
    const maintenanceTypeMap = {
      'Engine Oil Change': ['Engine Oil Change', 'Engine Oil'],
      'Transmission Oil Change': ['Transmission Oil Change', 'Transmission Oil'],
      'Hydraulic Oil Change': ['Hydraulic Oil Change', 'Hydraulic Oil'],
      'Coolant Change': ['Coolant Change', 'Coolant'],
      'Air Filter': ['Air Filter'],
      'Engine Oil Filter': ['Engine Oil Filter'],
      'Hydraulic Filter': ['Hydraulic Filter'],
      'Transmission Filter': ['Transmission Filter'],
      'Fuel Filter': ['Fuel Filter'],
      'Grease Service': ['Grease Service'],
      'Belt Inspection': ['Belt Inspection'],
      'General Inspection': ['General Inspection', 'Inspection']
    };

    // Find the last maintenance reading for this type
    const lastMaintenance = maintenanceTypeMap[schedule.type]?.reduce((latest, checklistItem) => {
      const history = maintenanceHistory[checklistItem];
      if (!latest || (history && new Date(history.date) > new Date(latest.date))) {
        return history;
      }
      return latest;
    }, null);
    
    if (schedule.dateInterval) {
      if (!lastMaintenance) {
        return 'No previous service recorded';
      }

      const lastServiceDate = new Date(lastMaintenance.date);
      const nextDueDate = new Date(lastServiceDate);
      nextDueDate.setDate(nextDueDate.getDate() + schedule.dateInterval);
      
      const today = new Date();
      const remainingDays = Math.ceil((nextDueDate - today) / (1000 * 60 * 60 * 24));

      if (remainingDays > 0) {
        parts.push(`${remainingDays} days remaining`);
      } else {
        parts.push(`Overdue by ${Math.abs(remainingDays)} days`);
      }
    }
    
    if (schedule.odometerInterval) {
      const baselineOdometer = lastMaintenance?.odometer || 0;
      const nextDueOdometer = baselineOdometer + schedule.odometerInterval;
      const currentOdometer = equipment.Odometer || 0;
      const remainingMiles = nextDueOdometer - currentOdometer;

      if (remainingMiles > 0) {
        parts.push(`${remainingMiles.toLocaleString()} miles remaining`);
      } else {
        parts.push(`Overdue by ${Math.abs(remainingMiles).toLocaleString()} miles`);
      }
    }
    
    if (schedule.hoursInterval) {
      const baselineHours = lastMaintenance?.hours || 0;
      const nextDueHours = baselineHours + schedule.hoursInterval;
      const currentHours = equipment.Hours || 0;
      const remainingHours = nextDueHours - currentHours;
      
      if (remainingHours > 0) {
        parts.push(`${remainingHours.toLocaleString()} hours remaining`);
      } else {
        parts.push(`Overdue by ${Math.abs(remainingHours).toLocaleString()} hours`);
      }
    }

    return parts.join(' or ') || 'Not scheduled';
  };

  const getStatusColor = (schedule, remainingValue, isDateInterval = false) => {
    // If remainingValue is negative, it's overdue
    if (remainingValue < 0) return 'rgba(255, 0, 0, 0.1)'; // Red

    // Calculate the interval value
    const interval = isDateInterval ? schedule.dateInterval : 
                    (schedule.hoursInterval || schedule.odometerInterval);
    if (!interval) return 'transparent';

    // Calculate percentage remaining
    const percentRemaining = (remainingValue / interval) * 100;

    if (percentRemaining > 15) return 'rgba(0, 255, 0, 0.1)'; // Green
    if (percentRemaining > 5) return 'rgba(255, 255, 0, 0.1)'; // Yellow
    return 'rgba(255, 165, 0, 0.1)'; // Orange
  };

  const renderMaintenanceSchedule = (equipment) => {
    if (!equipment?.maintenanceSchedule) return null;

    return (
      <div className="maintenance-schedule-display" style={{ padding: '0' }}>
        {equipment.maintenanceSchedule
          .filter(schedule => schedule.enabled)
          .map((schedule, index) => {
            const parts = [];
            if (schedule.dateInterval) {
              parts.push(`every ${schedule.dateInterval.toLocaleString()} days`);
            }
            if (schedule.odometerInterval) {
              parts.push(`every ${schedule.odometerInterval.toLocaleString()} miles`);
            }
            if (schedule.hoursInterval) {
              parts.push(`every ${schedule.hoursInterval.toLocaleString()} hours`);
            }

            const serviceTypes = {
              'Engine Oil Change': 'Change engine oil',
              'Transmission Oil Change': 'Change transmission oil',
              'Hydraulic Oil Change': 'Change hydraulic oil',
              'Coolant Change': 'Change coolant',
              'Air Filter': 'Replace air filter',
              'Engine Oil Filter': 'Replace engine oil filter',
              'Hydraulic Filter': 'Replace hydraulic filter',
              'Transmission Filter': 'Replace transmission filter',
              'Fuel Filter': 'Replace fuel filter',
              'Grease Service': 'Perform grease service',
              'Belt Inspection': 'Inspect belts',
              'General Inspection': 'Perform general inspection'
            };

            // Get last maintenance for this schedule type
            const maintenanceTypeMap = {
              'Engine Oil Change': ['Engine Oil Change', 'Engine Oil'],
              'Transmission Oil Change': ['Transmission Oil Change', 'Transmission Oil'],
              'Hydraulic Oil Change': ['Hydraulic Oil Change', 'Hydraulic Oil'],
              'Coolant Change': ['Coolant Change', 'Coolant'],
              'Air Filter': ['Air Filter'],
              'Engine Oil Filter': ['Engine Oil Filter'],
              'Hydraulic Filter': ['Hydraulic Filter'],
              'Transmission Filter': ['Transmission Filter'],
              'Fuel Filter': ['Fuel Filter'],
              'Grease Service': ['Grease Service'],
              'Belt Inspection': ['Belt Inspection'],
              'General Inspection': ['General Inspection', 'Inspection']
            };

            const lastMaintenance = maintenanceTypeMap[schedule.type]?.reduce((latest, checklistItem) => {
              const history = maintenanceHistory[checklistItem];
              if (!latest || (history && new Date(history.date) > new Date(latest.date))) {
                return history;
              }
              return latest;
            }, null);

            const serviceDescription = serviceTypes[schedule.type] || schedule.type;

            // Calculate remaining value for background color
            let remainingValue = 0;
            let isDateInterval = false;
            
            if (schedule.dateInterval) {
              isDateInterval = true;
              const lastMaintenance = maintenanceTypeMap[schedule.type]?.reduce((latest, checklistItem) => {
                const history = maintenanceHistory[checklistItem];
                if (!latest || (history && new Date(history.date) > new Date(latest.date))) {
                  return history;
                }
                return latest;
              }, null);

              if (lastMaintenance) {
                const lastServiceDate = new Date(lastMaintenance.date);
                const nextDueDate = new Date(lastServiceDate);
                nextDueDate.setDate(nextDueDate.getDate() + schedule.dateInterval);
                const today = new Date();
                remainingValue = Math.ceil((nextDueDate - today) / (1000 * 60 * 60 * 24));
              }
            } else if (schedule.odometerInterval) {
              const baselineOdometer = lastMaintenance?.odometer || 0;
              const nextDueOdometer = baselineOdometer + schedule.odometerInterval;
              const currentOdometer = equipment.Odometer || 0;
              remainingValue = nextDueOdometer - currentOdometer;
            } else if (schedule.hoursInterval) {
              const baselineHours = lastMaintenance?.hours || 0;
              const nextDueHours = baselineHours + schedule.hoursInterval;
              const currentHours = equipment.Hours || 0;
              remainingValue = nextDueHours - currentHours;
            }

            const backgroundColor = getStatusColor(schedule, remainingValue, isDateInterval);

            return (
              <div 
                key={index} 
                className="maintenance-item" 
                style={{ 
                  border: 'none', 
                  marginBottom: '4px',
                  backgroundColor,
                  padding: '8px',
                  borderRadius: '4px',
                  transition: 'background-color 0.3s ease'
                }}
              >
                <div className="maintenance-description">
                  <span className="service-text">
                    {serviceDescription} {parts.join(' or ')}
                  </span>
                </div>
                <div className="next-due">
                  Next due: {getNextDueText(schedule, equipment)}
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  if (loading) return (
    <tr>
      <td colSpan="3" style={{ padding: '16px', textAlign: 'center' }}>
        Loading equipment data...
      </td>
    </tr>
  );

  if (!equipment) return <tr><td colSpan="5">Loading...</td></tr>;

  return (
    <tr style={{ 
      borderBottom: '1px solid #eee',
      backgroundColor: 'white'
    }}>
      <td style={{ 
        padding: '5px',
        verticalAlign: 'middle',
        width: '120px'
      }}>
        {equipment.ID}
      </td>
      <td style={{ 
        padding: '12px',
        verticalAlign: 'top'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: '16px',
            gap: '16px'
          }}>
            <div style={{ flex: 1 }}>
              {!equipment.ignoreOdometer && renderReadingInput('Mileage', odometer, setOdometer, equipment.ignoreOdometer, originalOdometer)}
              {!equipment.ignoreHours && renderReadingInput('Hours', hours, setHours, equipment.ignoreHours, originalHours)}
            </div>
            {(hasChanges()) && (
              <button 
                onClick={handleUpdate}
                style={{
                  padding: '4px',
                  backgroundColor: 'transparent',
                  color: '#4CAF50',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: '32px',
                  height: '28px'
                }}
                title="Update Readings"
              >
                <FaRegCheckSquare size={20} />
              </button>
            )}
          </div>
          {renderMaintenanceSchedule(equipment)}
        </div>
      </td>
    </tr>
  );
});

EquipmentRow.displayName = 'EquipmentRow';

function EquipmentSelectionModal({ onClose, onSave, onRemove, currentSelection, equipment, types, locations }) {
  const [selectedItems, setSelectedItems] = useState(new Set(currentSelection.map(item => item._id)));
  const [typeFilter, setTypeFilter] = useState('');
  const [locationFilter, setLocationFilter] = useState('');
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);

  const handleSelect = (equipmentId) => {
    const newSelection = new Set(selectedItems);
    if (newSelection.has(equipmentId)) {
      newSelection.delete(equipmentId);
    } else {
      newSelection.add(equipmentId);
    }
    setSelectedItems(newSelection);
  };

  const handleSave = async () => {
    const selectionArray = Array.from(selectedItems);
    onSave(selectionArray);
  };

  const filteredEquipment = equipment.filter(item => {
    const matchesType = !typeFilter || item.Type?.name === typeFilter;
    const matchesLocation = !locationFilter || item.Location?.name === locationFilter;
    const matchesSelected = !showSelectedOnly || selectedItems.has(item._id);
    return matchesType && matchesLocation && matchesSelected;
  });

  const isCurrentlyTracked = (itemId) => {
    return currentSelection.some(item => item._id === itemId);
  };

  return (
    <div className="modal-overlay" style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }}>
      <div className="equipment-selection-modal" style={{
        backgroundColor: 'white',
        borderRadius: '8px',
        padding: '24px',
        width: '90%',
        maxWidth: '800px',
        maxHeight: '80vh',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
      }}>
        <h2 style={{ margin: 0, color: '#333' }}>Select Equipment</h2>
        
        <div className="filter-section" style={{ 
          display: 'flex', 
          gap: '16px', 
          marginBottom: '16px',
          padding: '16px',
          backgroundColor: '#f5f5f5',
          borderRadius: '6px'
        }}>
          <div style={{ flex: 1 }}>
            <label style={{ 
              display: 'block', 
              marginBottom: '8px', 
              color: '#666',
              fontSize: '14px' 
            }}>
              Filter View
            </label>
            <select 
              value={showSelectedOnly ? 'selected' : 'all'}
              onChange={(e) => setShowSelectedOnly(e.target.value === 'selected')}
              style={{
                width: '100%',
                padding: '8px',
                borderRadius: '4px',
                border: '1px solid #ddd',
                backgroundColor: 'white'
              }}
            >
              <option value="all">Show All Equipment</option>
              <option value="selected">Show Selected Only</option>
            </select>
          </div>

          <div style={{ flex: 1 }}>
            <label style={{ 
              display: 'block', 
              marginBottom: '8px', 
              color: '#666',
              fontSize: '14px' 
            }}>
              Equipment Type
            </label>
            <select 
              value={typeFilter} 
              onChange={(e) => setTypeFilter(e.target.value)}
              style={{
                width: '100%',
                padding: '8px',
                borderRadius: '4px',
                border: '1px solid #ddd',
                backgroundColor: 'white'
              }}
            >
              <option value="">All Types</option>
              {types.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>

          <div style={{ flex: 1 }}>
            <label style={{ 
              display: 'block', 
              marginBottom: '8px', 
              color: '#666',
              fontSize: '14px' 
            }}>
              Location
            </label>
            <select 
              value={locationFilter} 
              onChange={(e) => setLocationFilter(e.target.value)}
              style={{
                width: '100%',
                padding: '8px',
                borderRadius: '4px',
                border: '1px solid #ddd',
                backgroundColor: 'white'
              }}
            >
              <option value="">All Locations</option>
              {locations.map(location => (
                <option key={location} value={location}>{location}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="equipment-list" style={{
          overflowY: 'auto',
          flex: 1,
          border: '1px solid #ddd',
          borderRadius: '6px'
        }}>
          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            padding: '12px 16px',
            backgroundColor: '#f5f5f5',
            borderBottom: '1px solid #ddd',
            fontWeight: 'bold',
            color: '#666',
            fontSize: '14px'
          }}>
            <span>ID</span>
            <span>Type</span>
            <span>Location</span>
            <span>Category</span>
          </div>
          <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
            {filteredEquipment.map(item => {
              const isTracked = isCurrentlyTracked(item._id);
              const isSelected = selectedItems.has(item._id);
              const willBeRemoved = isTracked && !isSelected;
              
              return (
                <div 
                  key={item._id}
                  onClick={() => handleSelect(item._id)}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    padding: '12px 16px',
                    borderBottom: '1px solid #ddd',
                    backgroundColor: willBeRemoved ? '#ffe6e6' : 
                                  isSelected ? '#e6ffe6' : 
                                  isTracked ? '#f0f0f0' : 'white',
                    cursor: 'pointer',
                    transition: 'background-color 0.2s'
                  }}
                >
                  <span>{item.ID}</span>
                  <span>{item.Type?.name || '-'}</span>
                  <span>{item.Location?.name || '-'}</span>
                  <span>{item.Category?.name || '-'}</span>
                </div>
              );
            })}
          </div>
        </div>

        <div className="modal-actions" style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '12px',
          marginTop: '16px'
        }}>
          <button 
            onClick={onClose}
            style={{
              padding: '8px 16px',
              border: '1px solid #ddd',
              borderRadius: '4px',
              backgroundColor: 'white',
              cursor: 'pointer'
            }}
          >
            Cancel
          </button>
          <button 
            onClick={handleSave}
            style={{
              padding: '8px 16px',
              backgroundColor: '#4CAF50',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default UpdateMeters;