import React, { createContext, useState, useEffect } from 'react';
import { getSiteSettings } from '../services/settingsService';

export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const [siteTitle, setSiteTitle] = useState('Falcon Work Orders');

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settings = await getSiteSettings();
        if (settings && settings.siteTitle) {
          setSiteTitle(settings.siteTitle);
        }
      } catch (err) {
        console.error('Failed to fetch site settings:', err);
        // Keep the default title if there's an error
      }
    };

    fetchSettings();
  }, []);

  return (
    <SettingsContext.Provider value={{ siteTitle, setSiteTitle }}>
      {children}
    </SettingsContext.Provider>
  );
};
