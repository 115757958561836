import React from 'react';

const EquipmentList = ({ equipment, onSelectEquipment, selectedEquipment, sortConfig }) => {
  const getSortedEquipment = () => {
    const sortedEquipment = [...equipment];
    
    if (sortConfig) {
      sortedEquipment.sort((a, b) => {
        let aValue, bValue;

        // Handle nested properties and special cases
        switch (sortConfig.field) {
          case 'Type':
            aValue = a.Type?.name || '';
            bValue = b.Type?.name || '';
            break;
          case 'Category':
            aValue = a.Category?.name || '';
            bValue = b.Category?.name || '';
            break;
          case 'Location':
            aValue = a.Location?.name || '';
            bValue = b.Location?.name || '';
            break;
          case 'Hours':
            aValue = Number(a.Hours) || 0;
            bValue = Number(b.Hours) || 0;
            return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
          case 'Odometer':
            aValue = Number(a.Odometer) || 0;
            bValue = Number(b.Odometer) || 0;
            return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
          default:
            aValue = a[sortConfig.field] || '';
            bValue = b[sortConfig.field] || '';
        }

        // Handle string comparison
        if (typeof aValue === 'string') {
          if (sortConfig.direction === 'asc') {
            return aValue.localeCompare(bValue);
          }
          return bValue.localeCompare(aValue);
        }

        // Handle number comparison
        return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
      });
    }

    return sortedEquipment;
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'In-Service':
        return 'text-green-600';
      case 'Storage':
        return 'text-blue-600';
      case 'Maintenance':
        return 'text-yellow-600';
      case 'Repair':
        return 'text-orange-600';
      case 'Out-of-Service':
        return 'text-red-600';
      case 'Salvage':
        return 'text-gray-600';
      default:
        return 'text-gray-600';
    }
  };

  return (
    <ul className="divide-y divide-gray-200">
      {getSortedEquipment().map(item => (
        <li 
          key={item._id}
          onClick={() => onSelectEquipment(item)}
          className={`
            relative flex justify-between items-center p-2 cursor-pointer
            hover:bg-gray-50 overflow-hidden
            ${selectedEquipment?._id === item._id ? 'bg-gray-100' : ''}
          `}
        >
          <div className="flex items-center gap-4 flex-1">
            <span className="min-w-[60px] font-bold text-lg">{item.ID}</span>
            <div className="flex flex-col">
              <span className="text-sm font-medium">
                {item.Make} {item.Model}
              </span>
              <span className="text-xs text-gray-500">
                {item.Type?.name} - {item.Category?.name}
              </span>
            </div>
          </div>
          
          <div className="flex flex-col items-end">
            <span className={`text-sm font-medium ${getStatusColor(item.Status)}`}>
              {item.Status}
            </span>
            <span className="text-xs text-gray-500">
              {item.Location?.name || 'No Location'}
            </span>
          </div>

          {(item.Status === 'Out-of-Service' || item.Status === 'Salvage') && (
            <div className="absolute top-1/2 left-[70%] -translate-y-1/2 -rotate-12 
                          text-lg font-bold text-red-500 opacity-75 pointer-events-none
                          border-2 border-red-500 rounded-xl px-2 bg-white">
              {item.Status}
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

export default EquipmentList;
