import React, { useState, useEffect } from 'react';
import { addMeterReading, getLatestMeterReading, getLatestNonZeroReadings } from '../services/meterReadingService';
import { updateWorkOrder, addWorkOrderComment } from '../services/workOrderService';
import { getEquipmentMaintenanceItems } from '../services/maintenanceItemService';
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';
import MaintenanceItemSelectionModal from './MaintenanceItemSelectionModal';
import { FiTrash2, FiSave, FiList } from 'react-icons/fi';
import { updatePart, getPart } from '../services/partService';
import { updateEquipment, updateEquipmentPartApplications, getSavedConfigurations, saveMaintenanceConfiguration, updatePreviouslyUsedParts } from '../services/equipmentService';
import { toast } from 'react-toastify';
import axios from 'axios';

const WorkOrderCompletionModal = ({ workOrder, equipment, onClose, onComplete, existingComment = '' }) => {
  const [readings, setReadings] = useState({
    hours: '',
    odometer: ''
  });
  const [currentReadings, setCurrentReadings] = useState({
    hours: null,
    odometer: null
  });
  const [comment, setComment] = useState(existingComment);
  const [error, setError] = useState('');
  const [showMaintenanceItems, setShowMaintenanceItems] = useState(false);
  const [maintenanceItems, setMaintenanceItems] = useState([]);
  const [showMaintenanceModal, setShowMaintenanceModal] = useState(false);
  const [savedConfigurations, setSavedConfigurations] = useState([]);
  const [savedMaintenanceItems, setSavedMaintenanceItems] = useState([]);
  const [showSaveConfigModal, setShowSaveConfigModal] = useState(false);
  const [showLoadConfigModal, setShowLoadConfigModal] = useState(false);
  const [configName, setConfigName] = useState('');
  const [savedConfigs, setSavedConfigs] = useState([]);

  useEffect(() => {
    const fetchLatestReadings = async () => {
      try {
        console.log('Fetching latest readings for equipment:', equipment._id);
        const latestReadings = await getLatestNonZeroReadings(equipment._id);
        console.log('Latest non-zero readings received:', latestReadings);
        
        setCurrentReadings({
          hours: latestReadings?.hours ?? null,
          odometer: latestReadings?.odometer ?? null
        });
      } catch (error) {
        console.error('Error fetching latest readings:', error);
        setCurrentReadings({
          hours: null,
          odometer: null
        });
      }
    };

    if (equipment?._id) {
      fetchLatestReadings();
    }
  }, [equipment?._id]);

  useEffect(() => {
    const fetchSavedConfigurations = async () => {
      try {
        // Get saved maintenance configurations for this equipment
        const configs = await getEquipmentMaintenanceItems(equipment._id);
        setSavedConfigurations(configs);
        
        // Auto-populate maintenance items that have saved configurations
        const autoPopulatedItems = configs
          .filter(config => config.parts && config.parts.length > 0)
          .map(config => ({
            type: config.maintenanceItem.partCategories[0],
            part: config.parts[0].part,
            quantity: config.parts[0].quantity,
            required: true
          }));
        
        // Optionally auto-add these items
        // setMaintenanceItems(autoPopulatedItems);
        
        // Or store them for quick selection
        setSavedMaintenanceItems(autoPopulatedItems);
      } catch (error) {
        console.error('Error fetching saved configurations:', error);
      }
    };

    if (equipment?._id) {
      fetchSavedConfigurations();
    }
  }, [equipment?._id]);

  useEffect(() => {
    const fetchSavedConfigs = async () => {
      try {
        const configs = await getSavedConfigurations(equipment._id);
        setSavedConfigs(configs);
      } catch (error) {
        console.error('Error fetching saved configurations:', error);
      }
    };

    if (equipment?._id) {
      fetchSavedConfigs();
    }
  }, [equipment?._id]);

  const handleSubmit = async () => {
    try {
      const missingQuantities = maintenanceItems.some(
        item => item.part && !item.quantity
      );

      if (missingQuantities) {
        setError('Please enter quantities for all parts');
        return;
      }

      // Update parts inventory and equipment applications
      const partUpdates = maintenanceItems
        .filter(item => item.part)
        .map(async (item) => {
          try {
            // Get current part info
            const currentPart = await getPart(item.part._id);
            
            // Calculate new quantity
            const currentQuantity = currentPart.inventory?.quantity || 0;
            const newQuantity = Math.max(0, currentQuantity - item.quantity);
            
            // Find existing application with same equipment and usage
            const existingApplication = currentPart.equipmentApplications?.find(
              app => app.equipment?.toString() === equipment._id.toString() && 
                     app.usage === item.type
            );

            let equipmentApplications = currentPart.equipmentApplications || [];
            
            if (existingApplication) {
              // Update existing application
              equipmentApplications = equipmentApplications.map(app => 
                app.equipment?.toString() === equipment._id.toString() && app.usage === item.type
                  ? { ...app, quantity: item.quantity }
                  : app
              );
            } else {
              // Add new application
              equipmentApplications.push({
                equipment: equipment._id,
                usage: item.type,
                quantity: item.quantity,
                notes: `Added from Work Order ${workOrder.orderNumber}`
              });
            }

            // Update the part with new inventory and equipment applications
            await updatePart(item.part._id, {
              inventory: {
                ...currentPart.inventory,
                quantity: newQuantity
              },
              equipmentApplications: equipmentApplications.map(app => ({
                equipment: app.equipment,
                usage: app.usage,
                quantity: app.quantity,
                notes: app.notes
              }))
            });
          } catch (error) {
            console.error(`Error updating part ${item.part.partNumber}:`, error);
            toast.error(`Failed to update inventory for ${item.part.manufacturer} - ${item.part.partNumber}`);
            throw error;
          }
        });

      // Wait for all part updates to complete
      await Promise.all(partUpdates);

      // Update previously used parts
      await updatePreviouslyUsedParts(equipment._id, {
        parts: maintenanceItems
          .filter(item => item.part)
          .map(item => ({
            part: item.part._id,
            category: item.type,
            workOrderId: workOrder._id
          })),
        workOrderId: workOrder._id
      });

      // Create the completion comment
      let completionComment = comment ? `${comment}\n\n` : '';
      completionComment += 'Maintenance Items:\n';
      
      maintenanceItems.forEach(item => {
        if (item.part) {
          completionComment += `\n${item.type}:\n`;
          completionComment += `- ${item.part.manufacturer} - ${item.part.partNumber}\n`;
          completionComment += `  Quantity: ${item.quantity} ${item.part.unit}${item.quantity > 1 ? 's' : ''}\n`;
        } else {
          completionComment += `\n${item.type} - Completed\n`;
        }
      });

      if (currentReadings.hours || currentReadings.odometer) {
        completionComment += '\nMeter Readings:\n';
        if (currentReadings.hours) {
          completionComment += `Hours: ${currentReadings.hours}\n`;
        }
        if (currentReadings.odometer) {
          completionComment += `Odometer: ${currentReadings.odometer}\n`;
        }
      }

      const meterReadingData = {
        type: 'Work Order Completion',
        readings: {
          hours: currentReadings.hours ? Number(currentReadings.hours) : null,
          odometer: currentReadings.odometer ? Number(currentReadings.odometer) : null
        },
        workOrder: workOrder._id,
        maintenanceDetails: {
          fluidsAndFilters: maintenanceItems
            .filter(item => item.part)
            .map(item => ({
              type: item.type,
              brand: item.part.manufacturer,
              partNumber: item.part.partNumber,
              quantity: item.quantity
            })),
          completedChecklist: maintenanceItems.map(item => ({
            item: item.type,
            completed: true,
            notes: ''
          }))
        }
      };

      await addMeterReading(equipment._id, meterReadingData);

      const updatedWorkOrder = await updateWorkOrder(workOrder._id, {
        status: 'closed',
        completionDetails: {
          readings: currentReadings,
          completedAt: new Date(),
          maintenanceItems: maintenanceItems.map(item => ({
            type: item.type,
            partId: item.part?._id,
            quantity: item.quantity
          }))
        }
      });

      const commentResponse = await addWorkOrderComment(workOrder._id, { text: completionComment });

      toast.success('Work order completed successfully');
      onComplete(updatedWorkOrder);
    } catch (error) {
      console.error('Error completing work order:', error);
      toast.error('Failed to complete work order. Please try again.');
      setError('Failed to complete work order');
    }
  };

  const handleMaintenanceItemSelect = (item) => {
    if (item.type === 'service') {
      setMaintenanceItems(prev => [...prev, {
        type: item.name,
        details: null
      }]);
    } else if (item.type === 'part') {
      // Handle multiple parts without duplicating existing ones
      const newItems = item.parts.map(partInfo => ({
        type: item.maintenanceItem.name, // Use the maintenance item name instead of category
        part: partInfo.part,
        quantity: partInfo.quantity,
        required: true
      }));
      
      // Filter out any duplicates before adding new items
      setMaintenanceItems(prev => {
        const existingPartIds = new Set(prev.map(p => p.part?._id));
        const uniqueNewItems = newItems.filter(newItem => !existingPartIds.has(newItem.part._id));
        return [...prev, ...uniqueNewItems];
      });
    }
    setShowMaintenanceModal(false);
  };

  const handleQuantityChange = (index, value) => {
    setMaintenanceItems(prev => prev.map((item, i) => 
      i === index ? { ...item, quantity: value } : item
    ));
  };

  const handleRemoveItem = (index) => {
    setMaintenanceItems(prev => prev.filter((_, i) => i !== index));
  };

  const handleAddSavedConfiguration = () => {
    setShowMaintenanceModal(true);
  };

  const handleSaveConfiguration = async () => {
    try {
      const config = {
        name: configName,
        maintenanceItems: maintenanceItems.map(item => ({
          part: item.part._id,
          quantity: item.quantity
        }))
      };

      await saveMaintenanceConfiguration(equipment._id, config);
      toast.success('Configuration saved successfully');
      setShowSaveConfigModal(false);
      setConfigName('');
    } catch (error) {
      console.error('Error saving configuration:', error);
      toast.error('Failed to save configuration');
    }
  };

  const handleLoadConfiguration = (config) => {
    const newItems = config.maintenanceItems.map(item => ({
      type: item.part.category,
      part: item.part,
      quantity: item.quantity,
      required: true
    }));

    setMaintenanceItems(prev => [...prev, ...newItems]);
    setShowLoadConfigModal(false);
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      style={{ zIndex: 999999 }}
      onClick={onClose}
    >
      <div 
        className="bg-white rounded-lg shadow-xl w-full max-w-3xl max-h-[90vh] overflow-y-auto relative"
        onClick={(e) => e.stopPropagation()}
        onTouchStart={(e) => e.stopPropagation()}
      >
        <div className="p-6 space-y-6">
          {/* Header */}
          <div className="border-b pb-4">
            <h2 className="text-2xl font-bold">Complete Work Order {workOrder.orderNumber}</h2>
            <h3 className="text-lg text-gray-600 mt-1">{equipment?.ID} - {workOrder.title}</h3>
          </div>
          
          {error && (
            <div className="bg-red-50 text-red-600 p-4 rounded-md">
              {error}
            </div>
          )}
          
          <div className="space-y-6">
            {/* Readings Section */}
            <div>
              <h3 className="text-lg font-semibold mb-3">Latest Readings</h3>
              <div className="space-y-3">
                {!equipment?.ignoreHours && (
                  <input
                    type="number"
                    placeholder={`Latest Hours: ${currentReadings.hours || 'N/A'}`}
                    value={readings.hours}
                    onChange={(e) => setReadings(prev => ({ ...prev, hours: e.target.value }))}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                )}
                {!equipment?.ignoreOdometer && (
                  <input
                    type="number"
                    placeholder={`Latest Odometer: ${currentReadings.odometer || 'N/A'}`}
                    value={readings.odometer}
                    onChange={(e) => setReadings(prev => ({ ...prev, odometer: e.target.value }))}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                )}
              </div>
            </div>

            {/* Comment Section */}
            <div>
              <h3 className="text-lg font-semibold mb-3">Completion Notes</h3>
              <textarea
                value={comment}
                onChange={e => setComment(e.target.value)}
                placeholder="Add completion notes..."
                rows={4}
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                onClick={(e) => e.stopPropagation()}
                onTouchStart={(e) => e.stopPropagation()}
              />
            </div>

            {/* Maintenance Items Section */}
            <div>
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold">Maintenance Items</h3>
                <div className="flex gap-2">
                  <button 
                    onClick={() => setShowMaintenanceModal(true)}
                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                  >
                    Add Maintenance Item
                  </button>
                  {maintenanceItems.length > 0 && (
                    <button 
                      onClick={() => setShowSaveConfigModal(true)}
                      className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50 transition-colors flex items-center gap-2"
                    >
                      <FiSave className="w-4 h-4" /> Save as Configuration
                    </button>
                  )}
                  {savedConfigs.length > 0 && (
                    <button 
                      onClick={() => setShowLoadConfigModal(true)}
                      className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50 transition-colors flex items-center gap-2"
                    >
                      <FiList className="w-4 h-4" /> Load Configuration
                    </button>
                  )}
                </div>
              </div>
              
              <div className="space-y-3">
                {maintenanceItems.map((item, index) => (
                  <div key={index} className="flex items-center justify-between p-3 bg-gray-50 rounded-md">
                    {item.part ? (
                      <>
                        <div className="flex-1">
                          <span className="font-medium">{item.part.manufacturer} - {item.part.partNumber}</span>
                          <span className="text-gray-600 ml-2">- {item.type}</span>
                        </div>
                        <div className="flex items-center gap-3">
                          <input
                            type="number"
                            placeholder="Quantity"
                            value={item.quantity}
                            onChange={(e) => handleQuantityChange(index, e.target.value)}
                            required={item.required}
                            className="w-24 p-2 border rounded-md"
                            onClick={(e) => e.stopPropagation()}
                            onTouchStart={(e) => e.stopPropagation()}
                          />
                          <button
                            onClick={() => handleRemoveItem(index)}
                            className="text-red-500 hover:text-red-700 p-1"
                          >
                            <FiTrash2 className="w-5 h-5" />
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex-1">
                          <span className="font-medium">{item.type} - Completed</span>
                        </div>
                        <button
                          onClick={() => handleRemoveItem(index)}
                          className="text-red-500 hover:text-red-700 p-1"
                        >
                          <FiTrash2 className="w-5 h-5" />
                        </button>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="border-t pt-6 flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50 transition-colors"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
            >
              Complete Work Order
            </button>
          </div>
        </div>
      </div>

      {/* Maintenance Item Modal */}
      {showMaintenanceModal && (
        <MaintenanceItemSelectionModal 
          onClose={() => setShowMaintenanceModal(false)}
          onSelect={handleMaintenanceItemSelect}
          equipment={equipment}
          existingItems={maintenanceItems}
        />
      )}

      {/* Save Configuration Modal */}
      {showSaveConfigModal && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          style={{ zIndex: 1000000 }}
        >
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-xl font-bold mb-4">Save Configuration</h3>
            <input
              type="text"
              value={configName}
              onChange={(e) => setConfigName(e.target.value)}
              placeholder="Enter configuration name"
              className="w-full p-2 border rounded-md mb-4"
            />
            <div className="flex justify-end gap-3">
              <button
                onClick={() => setShowSaveConfigModal(false)}
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveConfiguration}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Load Configuration Modal */}
      {showLoadConfigModal && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          style={{ zIndex: 1000000 }}
        >
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-xl font-bold mb-4">Load Configuration</h3>
            <div className="space-y-2 max-h-[60vh] overflow-y-auto">
              {savedConfigs.map(config => (
                <div key={config._id} className="flex items-center justify-between p-3 bg-gray-50 rounded-md">
                  <span className="font-medium">{config.name}</span>
                  <button
                    onClick={() => handleLoadConfiguration(config)}
                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                  >
                    Load
                  </button>
                </div>
              ))}
            </div>
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => setShowLoadConfigModal(false)}
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkOrderCompletionModal; 