import React, { useEffect } from 'react';
import { FaCheckCircle, FaExclamationCircle, FaTimes } from 'react-icons/fa';

const Toast = ({ message, type = 'success', onClose, duration = 3000 }) => {
    useEffect(() => {
        if (duration) {
            const timer = setTimeout(() => {
                onClose();
            }, duration);
            return () => clearTimeout(timer);
        }
    }, [duration, onClose]);

    const bgColor = type === 'success' ? 'bg-green-500' : 'bg-red-500';
    const Icon = type === 'success' ? FaCheckCircle : FaExclamationCircle;

    return (
        <div className={`fixed bottom-5 right-5 z-50 flex items-center ${bgColor} text-white px-4 py-3 rounded-lg shadow-lg transform transition-all duration-300 ease-in-out`}>
            <Icon className="h-5 w-5 mr-2" />
            <span className="mr-2">{message}</span>
            <button
                onClick={onClose}
                className="ml-4 text-white hover:text-gray-200 focus:outline-none"
            >
                <FaTimes className="h-4 w-4" />
            </button>
        </div>
    );
};

export default Toast;
