import React, { useState } from 'react';
import { forgotCredentials } from '../services/authService';
import '../App.css'; // Create and style this CSS file as needed

function ForgotCredentialsModal({ isOpen, onClose }) {
  const [email, setEmail] = useState('');
  const [action, setAction] = useState('retrieve_username');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    if (!email) {
      setError('Please enter your email address.');
      return;
    }

    try {
      const response = await forgotCredentials(email, action);
      setMessage(response.data.message);
      setEmail('');
    } catch (err) {
      console.error('Forgot Credentials error:', err);
      setError(err.response?.data?.message || err.message || 'An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2>Forgot Username/Password</h2>
        {message && <p className="success-message">{message}</p>}
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email Address:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your registered email"
            />
          </div>
          <div className="form-group">
            <label>Action:</label>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  value="retrieve_username"
                  checked={action === 'retrieve_username'}
                  onChange={(e) => setAction(e.target.value)}
                />
                Retrieve username
              </label>
              <label>
                <input
                  type="radio"
                  value="reset_password"
                  checked={action === 'reset_password'}
                  onChange={(e) => setAction(e.target.value)}
                />
                Reset password
              </label>
            </div>
          </div>
          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default ForgotCredentialsModal;