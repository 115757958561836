import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { FaGripLines, FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import MaintenanceItemEditModal from './MaintenanceItemEditModal';
import { getMaintenanceItems, deleteMaintenanceItem, updateMaintenanceItemsOrder } from '../../services/maintenanceItemService';

const CATEGORIES = ['Fluid', 'Filter', 'Part', 'Service'];

const MaintenanceItemManagement = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('Fluid');

  const fetchItems = async () => {
    try {
      setLoading(true);
      console.log('Fetching maintenance items...');
      const data = await getMaintenanceItems(false);
      console.log('Fetched items:', data);
      
      const sortedItems = [...data].sort((a, b) => {
        if (a.order !== b.order) return a.order - b.order;
        return a.name.localeCompare(b.name);
      });
      
      setItems(sortedItems);
      setError(null);
    } catch (err) {
      console.error('Error fetching items:', err);
      setError('Failed to load maintenance items');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const handleSaveItem = async () => {
    await fetchItems();
    setShowModal(false);
    setSelectedItem(null);
  };

  const handleDeleteItem = async (id) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        await deleteMaintenanceItem(id);
        await fetchItems();
      } catch (err) {
        setError('Failed to delete item');
        console.error('Error deleting item:', err);
      }
    }
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, reorderedItem);

    const updatedItems = reorderedItems.map((item, index) => ({
      ...item,
      order: index
    }));

    setItems(updatedItems);

    try {
      console.log('Attempting to update items order...');
      await updateMaintenanceItemsOrder(updatedItems);
      console.log('Successfully updated items order');
    } catch (err) {
      console.error('Failed to update items order:', err);
      setError('Failed to save the new order. Please try again.');
      await fetchItems();
    }
  };

  const filteredItems = items.filter(item => item.category === selectedCategory);

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-2xl font-bold text-gray-900">Maintenance Items Management</h2>
          <p className="mt-1 text-sm text-gray-500">
            Manage and organize your maintenance items and PM schedules
          </p>
        </div>
        <button 
          className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-150"
          onClick={() => {
            setSelectedItem(null);
            setShowModal(true);
          }}
        >
          <FaPlus className="mr-2" /> Add New Item
        </button>
      </div>

      <div className="flex space-x-2 mb-6">
        {CATEGORIES.map(cat => (
          <button
            key={cat}
            className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-150 ${
              selectedCategory === cat
                ? 'bg-indigo-100 text-indigo-700'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
            onClick={() => setSelectedCategory(cat)}
          >
            {cat}
          </button>
        ))}
      </div>

      {error && (
        <div className="mb-4 p-4 bg-red-50 border border-red-200 text-red-700 rounded-md">
          {error}
        </div>
      )}

      {loading ? (
        <div className="flex justify-center items-center h-48 text-gray-500">
          Loading...
        </div>
      ) : items.length === 0 ? (
        <div className="flex justify-center items-center h-48 text-gray-500">
          No maintenance items found. Click "Add New Item" to create one.
        </div>
      ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="maintenance-items">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="space-y-3"
              >
                {filteredItems.map((item, index) => (
                  <Draggable 
                    key={item._id} 
                    draggableId={item._id} 
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={`bg-white rounded-lg border ${
                          !item.active ? 'border-gray-200 bg-gray-50' : 'border-gray-200 hover:border-gray-300'
                        } transition-colors duration-150`}
                      >
                        <div className="flex p-4">
                          <div
                            {...provided.dragHandleProps}
                            className="flex items-center px-2 text-gray-400 hover:text-gray-600"
                          >
                            <FaGripLines />
                          </div>
                          <div className="flex-grow min-w-0">
                            <h3 className="text-lg font-medium text-gray-900">{item.name}</h3>
                            <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                            <div className="mt-2 flex flex-wrap gap-2">
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                {item.category}
                              </span>
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                                {item.type}
                              </span>
                              {item.requiresParts && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                  Requires Parts
                                </span>
                              )}
                              {!item.active && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                  Inactive
                                </span>
                              )}
                            </div>
                            {item.type === 'Group' && item.includedItems?.length > 0 && (
                              <div className="mt-2">
                                <p className="text-sm font-medium text-gray-700">Includes:</p>
                                <ul className="mt-1 text-sm text-gray-500">
                                  {item.includedItems.map((included, idx) => (
                                    <li key={idx} className="flex items-center">
                                      <span className="w-2 h-2 rounded-full bg-gray-400 mr-2"></span>
                                      {included.maintenanceItem?.name || 'Unknown Item'}
                                      {included.quantity > 1 && (
                                        <span className="ml-1 text-gray-400">
                                          (x{included.quantity})
                                        </span>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                          <div className="flex items-start space-x-2 ml-4">
                            <button
                              className="p-2 text-indigo-600 hover:text-indigo-700 focus:outline-none"
                              onClick={() => {
                                setSelectedItem(item);
                                setShowModal(true);
                              }}
                              title="Edit"
                            >
                              <FaEdit className="w-4 h-4" />
                            </button>
                            <button
                              className="p-2 text-red-600 hover:text-red-700 focus:outline-none"
                              onClick={() => handleDeleteItem(item._id)}
                              title="Delete"
                            >
                              <FaTrash className="w-4 h-4" />
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {showModal && (
        <MaintenanceItemEditModal
          item={selectedItem}
          onClose={() => {
            setShowModal(false);
            setSelectedItem(null);
          }}
          onSave={handleSaveItem}
        />
      )}
    </div>
  );
};

export default MaintenanceItemManagement;
