import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { getMaintenanceItems, createMaintenanceItem, updateMaintenanceItem } from '../../services/maintenanceItemService';

const MaintenanceItemEditModal = ({ onClose, onSave, item = null }) => {
  const [formData, setFormData] = useState({
    name: item?.name || '',
    description: item?.description || '',
    category: item?.category || 'Service',
    type: item?.type || 'Single',
    requiresParts: item?.requiresParts || false,
    partCategories: item?.partCategories || [],
    includedItems: item?.includedItems || [],
    defaultInterval: item?.defaultInterval || {
      hours: '',
      odometer: '',
      days: ''
    },
    active: item?.active ?? true
  });

  const [availableItems, setAvailableItems] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchAvailableItems();
  }, []);

  const fetchAvailableItems = async () => {
    try {
      const items = await getMaintenanceItems(true);
      setAvailableItems(items.filter(i => 
        i._id !== item?._id && i.type === 'Single'
      ));
    } catch (err) {
      setError('Failed to load available maintenance items');
    }
  };

  const categories = ['Fluid', 'Filter', 'Service', 'Inspection'];
  const partCategoryOptions = [
    'Engine Oil',
    'Engine Oil Filter',
    'Transmission Oil',
    'Transmission Filter',
    'Hydraulic Oil',
    'Hydraulic Filter',
    'Compressor Oil',
    'Clutch Oil',
    'Clutch Filter',
    'Coolant',
    'Air Filter',
    'Fuel Filter',
    'Fuel Water Separator',
    'Other'
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (item) {
        await updateMaintenanceItem(item._id, formData);
      } else {
        await createMaintenanceItem(formData);
      }
      onSave();
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred while saving');
    }
  };

  const handleIncludedItemChange = (itemId, quantity) => {
    setFormData(prev => ({
      ...prev,
      includedItems: prev.includedItems.find(i => i.maintenanceItem === itemId)
        ? prev.includedItems.map(i => 
            i.maintenanceItem === itemId 
              ? { ...i, quantity: Number(quantity) }
              : i
          )
        : [...prev.includedItems, { maintenanceItem: itemId, quantity: Number(quantity) }]
    }));
  };

  const handleRemoveIncludedItem = (itemId) => {
    setFormData(prev => ({
      ...prev,
      includedItems: prev.includedItems.filter(i => i.maintenanceItem !== itemId)
    }));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-hidden">
        <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-900">
            {item ? 'Edit' : 'Add'} Maintenance Item
          </h2>
          <button 
            className="text-gray-400 hover:text-gray-500 focus:outline-none"
            onClick={onClose}
          >
            <FaTimes className="w-5 h-5" />
          </button>
        </div>

        {error && (
          <div className="mx-6 mt-4 p-4 bg-red-50 border border-red-200 text-red-700 rounded-md">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="p-6 overflow-y-auto max-h-[calc(90vh-8rem)]">
          <div className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name *
              </label>
              <input
                id="name"
                type="text"
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>

            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                id="description"
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>

            <div>
              <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                Category *
              </label>
              <select
                id="category"
                value={formData.category}
                onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="">Select Category</option>
                {['Fluid', 'Filter', 'Part', 'Service'].map(cat => (
                  <option key={cat} value={cat}>{cat}</option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                Type *
              </label>
              <select
                id="type"
                value={formData.type}
                onChange={(e) => setFormData(prev => ({ ...prev, type: e.target.value }))}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="Single">Single Item</option>
                <option value="Group">Group (PM)</option>
              </select>
            </div>

            {formData.type === 'Group' && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Included Items
                </label>
                <div className="space-y-3 max-h-60 overflow-y-auto border border-gray-200 rounded-md p-4">
                  {availableItems.map(availableItem => (
                    <div key={availableItem._id} className="flex items-center justify-between">
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          checked={formData.includedItems.some(i => i.maintenanceItem === availableItem._id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              handleIncludedItemChange(availableItem._id, 1);
                            } else {
                              handleRemoveIncludedItem(availableItem._id);
                            }
                          }}
                          className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                        <span className="ml-2 text-sm text-gray-700">{availableItem.name}</span>
                      </label>
                      {formData.includedItems.some(i => i.maintenanceItem === availableItem._id) && (
                        <input
                          type="number"
                          min="1"
                          value={formData.includedItems.find(i => i.maintenanceItem === availableItem._id)?.quantity || 1}
                          onChange={(e) => handleIncludedItemChange(availableItem._id, e.target.value)}
                          className="w-20 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {formData.type === 'Single' && (
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="requiresParts"
                  checked={formData.requiresParts}
                  onChange={(e) => setFormData(prev => ({ ...prev, requiresParts: e.target.checked }))}
                  className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label htmlFor="requiresParts" className="ml-2 text-sm text-gray-700">
                  Requires Parts
                </label>
              </div>
            )}

            {formData.requiresParts && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Part Categories
                </label>
                <div className="space-y-2 max-h-60 overflow-y-auto border border-gray-200 rounded-md p-4">
                  {partCategoryOptions.map(category => (
                    <label key={category} className="flex items-center">
                      <input
                        type="checkbox"
                        checked={formData.partCategories.includes(category)}
                        onChange={(e) => {
                          const updatedCategories = e.target.checked
                            ? [...formData.partCategories, category]
                            : formData.partCategories.filter(c => c !== category);
                          setFormData(prev => ({ ...prev, partCategories: updatedCategories }));
                        }}
                        className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <span className="ml-2 text-sm text-gray-700">{category}</span>
                    </label>
                  ))}
                </div>
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Default Service Interval
              </label>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                <div>
                  <label htmlFor="hours" className="block text-sm text-gray-500">
                    Hours
                  </label>
                  <input
                    type="number"
                    id="hours"
                    min="0"
                    value={formData.defaultInterval.hours}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      defaultInterval: { ...prev.defaultInterval, hours: e.target.value }
                    }))}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label htmlFor="odometer" className="block text-sm text-gray-500">
                    Odometer (km)
                  </label>
                  <input
                    type="number"
                    id="odometer"
                    min="0"
                    value={formData.defaultInterval.odometer}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      defaultInterval: { ...prev.defaultInterval, odometer: e.target.value }
                    }))}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label htmlFor="days" className="block text-sm text-gray-500">
                    Days
                  </label>
                  <input
                    type="number"
                    id="days"
                    min="0"
                    value={formData.defaultInterval.days}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      defaultInterval: { ...prev.defaultInterval, days: e.target.value }
                    }))}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                id="active"
                checked={formData.active}
                onChange={(e) => setFormData(prev => ({ ...prev, active: e.target.checked }))}
                className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <label htmlFor="active" className="ml-2 text-sm text-gray-700">
                Active
              </label>
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {item ? 'Update' : 'Create'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MaintenanceItemEditModal;