import React, { useState, useEffect } from 'react';
import { getEquipmentTypes, getEquipmentCategories } from '../services/equipmentService';
import { getLocations } from '../services/locationService';

// Add status grouping constants
const ACTIVE_STATUSES = ['In-Service', 'Storage', 'Maintenance', 'Repair'];
const INACTIVE_STATUSES = ['Out-of-Service', 'Salvage'];

function EquipmentFilterModal({ filters, onApply, onReset, onClose }) {
  const [localFilters, setLocalFilters] = useState({
    ...filters,
    status: filters.status || 'Active' // Set default to Active if not specified
  });
  const [types, setTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedType, setSelectedType] = useState(null);
  const [availableCategories, setAvailableCategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [typesData, locationsData] = await Promise.all([
          getEquipmentTypes(),
          getLocations()
        ]);
        setTypes(typesData);
        setLocations(locationsData);

        // If there's a selected type in filters, fetch its categories
        if (filters.type !== 'All Types') {
          const selectedTypeData = typesData.find(t => t._id === filters.type);
          if (selectedTypeData) {
            setSelectedType(selectedTypeData);
            setAvailableCategories(selectedTypeData.categories || []);
          }
        }
      } catch (error) {
        console.error('Error fetching filter data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [filters.type]);

  const handleTypeChange = async (typeId) => {
    if (typeId === 'All Types') {
      setSelectedType(null);
      setAvailableCategories([]);
      setLocalFilters(prev => ({
        ...prev,
        type: 'All Types',
        category: 'All' // Reset category when type changes
      }));
    } else {
      const selectedType = types.find(t => t._id === typeId);
      setSelectedType(selectedType);
      setAvailableCategories(selectedType?.categories || []);
      setLocalFilters(prev => ({
        ...prev,
        type: typeId,
        category: 'All'
      }));
    }
  };

  const handleApply = () => {
    // Convert Active/Inactive to array of statuses before applying
    const processedFilters = { ...localFilters };
    if (localFilters.status === 'Active') {
      processedFilters.statusArray = ACTIVE_STATUSES;
    } else if (localFilters.status === 'Inactive') {
      processedFilters.statusArray = INACTIVE_STATUSES;
    } else {
      processedFilters.statusArray = [localFilters.status];
    }
    onApply(processedFilters);
  };

  if (loading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999]">
        <div className="bg-white p-6 rounded-lg">Loading...</div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999]">
      <div className="bg-white p-6 rounded-lg w-full max-w-md relative">
        <h2 className="text-xl font-semibold mb-4">Filter Equipment</h2>
        
        <div className="space-y-4">
          <div className="flex flex-col">
            <label className="mb-1 text-gray-700">Status:</label>
            <select
              value={localFilters.status}
              onChange={(e) => setLocalFilters(prev => ({ ...prev, status: e.target.value }))}
              className="w-full border border-gray-300 rounded-md p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
              <option value="All">All Status</option>
              <option value="In-Service">In-Service</option>
              <option value="Storage">Storage</option>
              <option value="Maintenance">Maintenance</option>
              <option value="Repair">Repair</option>
              <option value="Out-of-Service">Out-of-Service</option>
              <option value="Salvage">Salvage</option>
            </select>
          </div>

          <div className="flex flex-col">
            <label className="mb-1 text-gray-700">Location:</label>
            <select
              value={localFilters.location}
              onChange={(e) => setLocalFilters(prev => ({ ...prev, location: e.target.value }))}
              className="w-full border border-gray-300 rounded-md p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            >
              <option value="All Locations">All Locations</option>
              {locations.map(location => (
                <option key={location._id} value={location._id}>
                  {location.name}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col">
            <label className="mb-1 text-gray-700">Type:</label>
            <select
              value={localFilters.type}
              onChange={(e) => handleTypeChange(e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            >
              <option value="All Types">All Types</option>
              {types.map(type => (
                <option key={type._id} value={type._id}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>

          {selectedType && (
            <div className="flex flex-col">
              <label className="mb-1 text-gray-700">Category:</label>
              <select
                value={localFilters.category}
                onChange={(e) => setLocalFilters(prev => ({ ...prev, category: e.target.value }))}
                className="w-full border border-gray-300 rounded-md p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              >
                <option value="All">All Categories</option>
                {availableCategories.map(category => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="flex justify-end gap-2 mt-6">
            <button
              onClick={onReset}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
            >
              Reset
            </button>
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={handleApply}
              className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 transition-colors"
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EquipmentFilterModal;