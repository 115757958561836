import axiosInstance from './api';

// Fetch current site settings
export const getSiteSettings = async () => {
  const response = await axiosInstance.get('/settings');
  return response.data;
};

// Update the site settings
export const updateSiteSettings = async (settings) => {
  const response = await axiosInstance.put('/settings', settings);
  return response.data;
};

// Update email settings
export const updateEmailSettings = async (emailSettings) => {
  const response = await axiosInstance.put('/settings/email-settings', emailSettings);
  return response.data;
};
