import axiosInstance from './api';

// Get logs with optional filtering
export const getLogs = async (filters = {}, options = {}) => {
  try {
    const response = await axiosInstance.get('/logs', { 
      params: filters,
      ...options
    });
    
    // If requesting export, return raw response data
    if (filters.export) {
      return response.data;
    }
    
    if (!response.data.success) {
      throw new Error(response.data.message || 'Failed to fetch logs');
    }
    
    return response.data.data;
  } catch (error) {
    console.error('Error fetching logs:', error);
    throw error;
  }
};

// Get log statistics
export const getLogStats = async () => {
  try {
    const response = await axiosInstance.get('/logs/stats');
    
    if (!response.data.success) {
      throw new Error(response.data.message || 'Failed to fetch log statistics');
    }
    
    return response.data.data;
  } catch (error) {
    console.error('Error fetching log statistics:', error);
    throw error;
  }
};
