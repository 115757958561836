// client/src/components/Admin/EquipmentManagement.js
import React, { useState, useEffect } from 'react';
import { getEquipmentTypes, createEquipmentType, updateEquipmentType, deleteEquipmentType } from '../../services/equipmentService';
import EquipmentTypeList from './Equipment/EquipmentTypeList';
import AddEquipmentTypeModal from './Equipment/AddEquipmentTypeModal';
import EditEquipmentTypeModal from './Equipment/EditEquipmentTypeModal';
import '../../styles/Admin.css';

function EquipmentManagement() {
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingType, setEditingType] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchEquipmentTypes();
  }, []);

  const fetchEquipmentTypes = async () => {
    try {
      const types = await getEquipmentTypes();
      setEquipmentTypes(types);
    } catch (error) {
      setError('Failed to fetch equipment types.');
    }
  };

  const handleAddType = async (newType) => {
    try {
      await createEquipmentType(newType);
      fetchEquipmentTypes();
      setShowAddModal(false);
    } catch (error) {
      setError('Failed to add equipment type.');
    }
  };

  const handleUpdateType = async (updatedType) => {
    try {
      await updateEquipmentType(updatedType._id, updatedType);
      fetchEquipmentTypes();
      setShowEditModal(false);
    } catch (error) {
      setError('Failed to update equipment type.');
    }
  };

  const handleDeleteType = async (typeId) => {
    try {
      await deleteEquipmentType(typeId);
      fetchEquipmentTypes();
    } catch (error) {
      setError('Failed to delete equipment type.');
    }
  };

  return (
    <div className="equipment-management">
      <h2>Equipment Type Management</h2>
      {error && <p className="error-message">{error}</p>}
      <button onClick={() => setShowAddModal(true)}>Add Equipment Type</button>
      <EquipmentTypeList 
        types={equipmentTypes} 
        onUpdate={(type) => {
          setEditingType(type);
          setShowEditModal(true);
        }} 
        onDelete={handleDeleteType} 
      />
      {showAddModal && (
        <AddEquipmentTypeModal 
          onSubmit={handleAddType} 
          onClose={() => setShowAddModal(false)} 
        />
      )}
      {showEditModal && (
        <EditEquipmentTypeModal 
          type={editingType}
          onSubmit={handleUpdateType} 
          onClose={() => setShowEditModal(false)} 
        />
      )}
    </div>
  );
}

export default EquipmentManagement;
