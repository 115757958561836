import React, { useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FiLogOut, FiUser } from 'react-icons/fi';
import { SettingsContext } from '../context/SettingsContext';
import { useAuth } from '../hooks/useAuth';
import { useMediaQuery } from 'react-responsive';

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const { siteTitle } = useContext(SettingsContext);
  const { user, logout } = useAuth();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <header className="w-full h-10 bg-primary fixed top-0 left-0 z-[9999]">
      <div className="h-full w-full flex flex-row justify-between items-center">
        {/* Left Section - Logo and Title */}
        <div className="flex items-center">
          {/* Logo - Positioned to align with sidebar logo */}
          {user ? (
            <Link to="/dashboard" className="flex items-center">
              <img 
                src="/logo.png" 
                alt="FGR Logo" 
                className={`w-6 h-6 ml-[-10px] cursor-pointer`}
              />
              {/* Title - Positioned after logo */}
              <h1 className={`text-white font-medium m-0 ml-3 text-lg`}>
                {siteTitle}
              </h1>
            </Link>
          ) : (
            <>
              <img 
                src="/logo.png" 
                alt="FGR Logo" 
                className={`w-6 h-6 ml-[-10px]`}
              />
              {/* Title - Positioned after logo */}
              <h1 className={`text-white font-medium m-0 ml-3 text-lg`}>
                {siteTitle}
              </h1>
            </>
          )}
        </div>

        {/* Right Section - Auth Links */}
        <div className="flex items-center mr-0">
          {user ? (
            <div className="flex items-center gap-4">
              <Link 
                to="/profile" 
                className="text-white hover:text-gray-200 transition-colors duration-200"
                title="Profile"
              >
                <FiUser className="w-5 h-5" />
              </Link>
              <button 
                onClick={handleLogout} 
                className="text-white hover:text-gray-200 transition-colors duration-200 p-1"
                title="Logout"
              >
                <FiLogOut className="w-5 h-5" />
              </button>
            </div>
          ) : (
            <div className="flex items-center">
              {location.pathname === '/login' ? (
                <Link 
                  to="/register" 
                  className="text-white hover:text-gray-200 transition-colors duration-200"
                >
                  Register
                </Link>
              ) : (
                <Link 
                  to="/login" 
                  className="text-white hover:text-gray-200 transition-colors duration-200"
                >
                  Login
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
