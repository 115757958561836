import React, { useState, useEffect } from 'react';
import { FiX, FiPlus } from 'react-icons/fi';
import { getMeterReadings, addMeterReading } from '../services/meterReadingService';
import { getEquipmentById } from '../services/equipmentService';
import { useAuth } from '../hooks/useAuth';
import '../styles/MeterReadingsModal.css';

function MeterReadingsModal({ equipment, onClose, onUpdate }) {
  const [readings, setReadings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddForm, setShowAddForm] = useState(false);
  const [newReading, setNewReading] = useState({
    value: '',
    notes: ''
  });
  const { user } = useAuth();

  const fetchReadings = async () => {
    try {
      setLoading(true);
      const data = await getMeterReadings(equipment._id);
      // Sort readings by date in descending order
      const sortedReadings = data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setReadings(sortedReadings);
    } catch (error) {
      console.error('Error fetching meter readings:', error);
    } finally {
      setLoading(false);
    }
  };

  const refreshEquipment = async () => {
    try {
      const updatedEquipment = await getEquipmentById(equipment._id);
      if (onUpdate) {
        onUpdate(updatedEquipment);
      }
    } catch (error) {
      console.error('Error refreshing equipment:', error);
    }
  };

  useEffect(() => {
    if (equipment?._id) {
      fetchReadings();
    }
  }, [equipment]);

  const renderReadingValue = (reading) => {
    if (!reading.readings) return 'N/A';

    const parts = [];
    if (!equipment.ignoreHours && reading.readings.hours !== null && reading.readings.hours !== undefined) {
      parts.push(`Hours: ${reading.readings.hours}`);
    }
    if (!equipment.ignoreOdometer && reading.readings.odometer !== null && reading.readings.odometer !== undefined) {
      parts.push(`Miles: ${reading.readings.odometer}`);
    }
    return parts.join(' | ') || 'N/A';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const readingData = {
        type: 'Meter Check',
        readings: {
          hours: equipment.ignoreHours ? null : Number(newReading.value),
          odometer: equipment.ignoreOdometer ? null : Number(newReading.value)
        },
        notes: newReading.notes,
        maintenanceDetails: {
          fluidsAndFilters: [],
          completedChecklist: []
        }
      };

      await addMeterReading(equipment._id, readingData);
      setNewReading({ value: '', notes: '' });
      setShowAddForm(false);
      await fetchReadings();
      await refreshEquipment();
    } catch (error) {
      console.error('Error adding meter reading:', error);
    }
  };

  return (
    <div className="meter-readings-modal-overlay" onClick={onClose}>
      <div className="meter-readings-modal" onClick={e => e.stopPropagation()}>
        <div className="meter-readings-header">
          <h2>Meter Reading History - {equipment.ID}</h2>
          <button className="close-button" onClick={onClose}>
            <FiX />
          </button>
        </div>

        <div className="meter-readings-content">
          {loading ? (
            <p>Loading readings...</p>
          ) : readings.length === 0 ? (
            <p>No readings found</p>
          ) : (
            <>
              <div className="readings-list">
                <table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Reading</th>
                      <th>Type</th>
                      <th>Notes</th>
                      <th>Submitted By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {readings.map((reading) => (
                      <tr key={reading._id}>
                        <td>{new Date(reading.date).toLocaleDateString()}</td>
                        <td>{renderReadingValue(reading)}</td>
                        <td>{reading.type}</td>
                        <td>{reading.notes || '-'}</td>
                        <td>{reading.submittedBy?.displayName || reading.submittedBy?.username || '-'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default MeterReadingsModal; 