import React, { useState, useEffect } from 'react';
import { getSiteSettings, updateSiteSettings } from '../../services/settingsService';
import axios from 'axios';
import { FaTrash } from 'react-icons/fa';
import Toast from '../ui/Toast';

const Settings = () => {
  const [settings, setSettings] = useState({
    siteTitle: '',
    workOrderNumberingSchema: {
      prefix: '',
      startingNumber: 0,
      currentNumber: 0
    },
    defaultScheduleDelay: '',
    logRetention: {
      enabled: true,
      days: 90
    }
  });
  const [toast, setToast] = useState(null);
  const [isCleaningLogs, setIsCleaningLogs] = useState(false);

  useEffect(() => {
    fetchSettings();
  }, []);

  const showToast = (message, type = 'success') => {
    setToast({ message, type });
  };

  const fetchSettings = async () => {
    try {
      const fetchedSettings = await getSiteSettings();
      setSettings(fetchedSettings);
    } catch (error) {
      console.error('Failed to fetch settings:', error);
      showToast('Failed to fetch settings. Please try again.', 'error');
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setSettings(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: type === 'checkbox' ? checked : value
        }
      }));
    } else {
      setSettings(prev => ({ 
        ...prev, 
        [name]: type === 'checkbox' ? checked : value 
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      await updateSiteSettings(settings);
      showToast('Settings updated successfully');
    } catch (error) {
      console.error('Failed to update settings:', error);
      showToast('Failed to update settings. Please try again.', 'error');
    }
  };

  const handleManualCleanup = async () => {
    try {
      setIsCleaningLogs(true);
      const response = await axios.post('/api/logs/cleanup');
      showToast(`Successfully cleaned up ${response.data.deletedCount} logs`);
    } catch (error) {
      console.error('Failed to clean up logs:', error);
      showToast('Failed to clean up logs. Please try again.', 'error');
    } finally {
      setIsCleaningLogs(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-6">System Settings</h2>

      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Site Title */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4">Site Information</h3>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Site Title</label>
              <input
                type="text"
                name="siteTitle"
                value={settings.siteTitle}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>
          </div>
        </div>

        {/* Work Order Numbering */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4">Work Order Numbering</h3>
          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Prefix</label>
                <input
                  type="text"
                  name="workOrderNumberingSchema.prefix"
                  value={settings.workOrderNumberingSchema.prefix}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Starting Number</label>
                <input
                  type="number"
                  name="workOrderNumberingSchema.startingNumber"
                  value={settings.workOrderNumberingSchema.startingNumber}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Next Work Order Number</label>
              <div className="mt-1 flex items-center">
                <input
                  type="text"
                  value={`${settings.workOrderNumberingSchema.prefix}${settings.workOrderNumberingSchema.currentNumber}`}
                  readOnly
                  className="block w-full rounded-md border-gray-300 bg-gray-50 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 cursor-not-allowed"
                />
                <div className="ml-2 text-sm text-gray-500">
                  (Read-only)
                </div>
              </div>
              <p className="mt-1 text-sm text-gray-500">
                This is the number that will be assigned to the next work order
              </p>
            </div>
          </div>
        </div>

        {/* Log Retention Settings */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4">Log Retention</h3>
          <div className="space-y-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="logRetentionEnabled"
                name="logRetention.enabled"
                checked={settings.logRetention?.enabled}
                onChange={handleChange}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor="logRetentionEnabled" className="ml-2 block text-sm text-gray-700">
                Enable automatic log cleanup
              </label>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Retention Period (days)</label>
              <input
                type="number"
                name="logRetention.days"
                value={settings.logRetention?.days}
                onChange={handleChange}
                min="1"
                max="3650"
                disabled={!settings.logRetention?.enabled}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-100"
              />
              <p className="mt-1 text-sm text-gray-500">
                Logs older than this many days will be automatically deleted. (1-3650 days)
              </p>
            </div>

            <div className="pt-4">
              <button
                type="button"
                onClick={handleManualCleanup}
                disabled={isCleaningLogs}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-red-300"
              >
                <FaTrash className="mr-2" />
                {isCleaningLogs ? 'Cleaning...' : 'Clean Up Logs Now'}
              </button>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save Settings
          </button>
        </div>
      </form>
    </div>
  );
};

export default Settings;
