import React, { useState, useEffect } from 'react';
import { FiEdit, FiCheckSquare, FiXSquare, FiSquare, FiClock, FiChevronDown, FiChevronRight, FiPlus } from 'react-icons/fi';
import { updateEquipment, getEquipmentTypes, getEquipmentCategories, getEquipmentById, updateEquipmentMeters } from '../services/equipmentService';
import { addMeterReading, getLatestNonZeroReadings } from '../services/meterReadingService';
import { getLocations } from '../services/locationService';
import { useAuth } from '../hooks/useAuth';
import '../styles/EquipmentStyles.css';
import '../styles/EquipmentModal.css';
import MeterReadingsModal from './MeterReadingsModal';
import MaintenanceScheduleSection from './MaintenanceScheduleSection';
import { toast } from 'react-toastify';
import { getPart, getParts, createPart } from '../services/partService';
import PartSelectionSection from './PartSelectionSection';

const EQUIPMENT_STATUSES = [
  'In-Service',
  'Storage',
  'Maintenance',
  'Repair',
  'Out-of-Service',
  'Salvage'
];

const getStatusColor = (status) => {
  if (status === 'In-Service') return '#4CAF50'; // Green
  if (['Storage', 'Maintenance', 'Repair'].includes(status)) return '#2196F3'; // Blue
  return '#f44336'; // Red for Out-of-Service and Salvage
};

const getSerialLabel = (type) => {
  if (type?.name === 'Vehicle' || type?.name === 'Trailer') {
    return 'VIN';
  }
  return 'Serial';
};

const unitOptions = [
  'Each',
  'Quart',
  'Gallon',
  'Liter',
  'Foot',
  'Inch',
  'Meter',
  'Millimeter',
  'Kilogram',
  'Pound',
  'Ounce'
];

function EquipmentModal({ equipment: initialEquipment, onClose, onUpdate, isInlineDisplay }) {
  const [equipment, setEquipment] = useState(initialEquipment);
  const [isEditing, setIsEditing] = useState(false);
  const [editedValues, setEditedValues] = useState({
    ID: initialEquipment.ID,
    Type: initialEquipment.Type?._id || '',
    Category: initialEquipment.Category?._id || '',
    Make: initialEquipment.Make,
    Model: initialEquipment.Model,
    Year: initialEquipment.Year,
    Serial: initialEquipment.Serial,
    Location: initialEquipment.Location?._id || initialEquipment.Location || '',
    Status: initialEquipment.Status,
    RequireFuelLog: initialEquipment.RequireFuelLog || false,
    RequireIndividualMileageLog: initialEquipment.RequireIndividualMileageLog || false,
    LicensePlate: {
      State: initialEquipment.LicensePlate?.State || '',
      Number: initialEquipment.LicensePlate?.Number || ''
    }
  });
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editingField, setEditingField] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [showMeterReadingsModal, setShowMeterReadingsModal] = useState(false);
  const [selectedMeterType, setSelectedMeterType] = useState(null);
  const [types, setTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [showMeterReadings, setShowMeterReadings] = useState(false);
  const { user } = useAuth();
  const [currentReadings, setCurrentReadings] = useState({
    hours: null,
    odometer: null
  });
  const [isEditingMaintenanceSchedule, setIsEditingMaintenanceSchedule] = useState(false);
  const [editingPart, setEditingPart] = useState(null);
  const [partEditValues, setPartEditValues] = useState({});
  const [isPartsExpanded, setIsPartsExpanded] = useState(true);
  const [partsData, setPartsData] = useState({});
  const [showAddPartModal, setShowAddPartModal] = useState(false);
  const [availableParts, setAvailableParts] = useState([]);
  const [selectedPart, setSelectedPart] = useState(null);
  const [newPartHistory, setNewPartHistory] = useState({
    part: '',
    quantityUsed: 1,
    unit: 'Each',
    lastUsed: new Date().toISOString().split('T')[0],
    category: ''
  });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [showNewPartForm, setShowNewPartForm] = useState(false);
  const [newPart, setNewPart] = useState({
    manufacturer: '',
    partNumber: '',
    description: ''
  });

  useEffect(() => {
    setEquipment(initialEquipment);
    setEditedValues({
      ID: initialEquipment.ID,
      Type: initialEquipment.Type?._id || '',
      Category: initialEquipment.Category?._id || '',
      Make: initialEquipment.Make,
      Model: initialEquipment.Model,
      Year: initialEquipment.Year,
      Serial: initialEquipment.Serial,
      Location: initialEquipment.Location?._id || initialEquipment.Location || '',
      Status: initialEquipment.Status,
      RequireFuelLog: initialEquipment.RequireFuelLog || false,
      RequireIndividualMileageLog: initialEquipment.RequireIndividualMileageLog || false,
      LicensePlate: {
        State: initialEquipment.LicensePlate?.State || '',
        Number: initialEquipment.LicensePlate?.Number || ''
      }
    });
    setIsEditing(false);
    setEditingField(null);
    setEditValue('');
  }, [initialEquipment]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [typesData, locationsData] = await Promise.all([
          getEquipmentTypes(),
          getLocations()
        ]);
        setTypes(typesData);
        setLocations(locationsData);

        if (initialEquipment.Type) {
          const selectedTypeData = typesData.find(t => t._id === initialEquipment.Type._id);
          if (selectedTypeData) {
            setSelectedType(selectedTypeData);
            setAvailableCategories(selectedTypeData.categories || []);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [initialEquipment]);

  useEffect(() => {
    const fetchLatestReadings = async () => {
      try {
        console.log('Fetching latest readings for equipment:', equipment._id);
        const latestReadings = await getLatestNonZeroReadings(equipment._id);
        console.log('Latest non-zero readings received:', latestReadings);
        
        setCurrentReadings({
          hours: latestReadings?.hours ?? null,
          odometer: latestReadings?.odometer ?? null
        });
      } catch (error) {
        console.error('Error fetching latest readings:', error);
        // Set to null on error to ensure proper display
        setCurrentReadings({
          hours: null,
          odometer: null
        });
      }
    };

    if (equipment?._id) {
      fetchLatestReadings();
    }
  }, [equipment?._id]);

  useEffect(() => {
    const fetchPartsData = async () => {
      const partsMap = {};
      if (equipment.previouslyUsedParts && Array.isArray(equipment.previouslyUsedParts)) {
        for (const partRecord of equipment.previouslyUsedParts) {
          if (!partsData[partRecord.part]) {
            try {
              const part = await getPart(partRecord.part);
              partsMap[partRecord.part] = part;
            } catch (error) {
              console.error('Error fetching part:', error);
            }
          }
        }
      }
      setPartsData(partsMap);
    };

    fetchPartsData();
  }, [equipment.previouslyUsedParts]);

  const handleInputChange = (field, value) => {
    setEditedValues(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSave = async () => {
    try {
      if (isEditing) {
        const updateData = {
          ...editedValues,
          Location: editedValues.Location || null,
          Type: editedValues.Type || equipment.Type?._id,
          Category: editedValues.Category || equipment.Category?._id,
          LicensePlate: {
            State: editedValues.LicensePlate?.State || '',
            Number: editedValues.LicensePlate?.Number || ''
          },
          RequireFuelLog: editedValues.RequireFuelLog,
          RequireIndividualMileageLog: editedValues.RequireIndividualMileageLog,
          maintenanceSchedule: equipment.maintenanceSchedule
        };

        console.log('Sending update with data:', updateData);

        const updatedEquipment = await updateEquipment(equipment._id, updateData);
        
        setEquipment(updatedEquipment);
        setEditedValues({
          ...editedValues,
          Type: updatedEquipment.Type?._id || '',
          Category: updatedEquipment.Category?._id || '',
          Location: updatedEquipment.Location?._id || '',
        });
        
        onUpdate(updatedEquipment);
        setIsEditing(false);
      } else if (editingField) {
        const updateData = {
          [editingField]: editValue
        };

        if (editingField === 'LicensePlate') {
          updateData.LicensePlate = {
            ...equipment.LicensePlate,
            ...JSON.parse(editValue)
          };
        }

        const updatedEquipment = await updateEquipment(equipment._id, updateData);
        setEquipment(updatedEquipment);
        onUpdate(updatedEquipment);
        setEditingField(null);
      }
    } catch (error) {
      console.error('Failed to update equipment:', error);
    }
  };

  const renderField = (label, field) => {
    if (field === 'ID' && isEditing) {
      return (
        <div className="equipment-field">
          <label>{label}:</label>
          <input
            type="text"
            value={editedValues.ID || ''}
            onChange={(e) => handleInputChange('ID', e.target.value)}
            className="edit-input"
          />
        </div>
      );
    }

    if (field === 'Type' && isEditing) {
      return (
        <div className="equipment-field">
          <label>{label}:</label>
          <select
            value={editedValues.Type}
            onChange={(e) => handleTypeChange(e.target.value)}
            className="edit-select"
          >
            <option value="">Select Type</option>
            {types.map(type => (
              <option key={type._id} value={type._id}>
                {type.name}
              </option>
            ))}
          </select>
        </div>
      );
    }

    if (field === 'Category' && isEditing) {
      return (
        <div className="equipment-field">
          <label>{label}:</label>
          <select
            value={editedValues.Category}
            onChange={(e) => handleInputChange('Category', e.target.value)}
            className="edit-select"
            disabled={!selectedType}
          >
            <option value="">Select Category</option>
            {availableCategories.map(category => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      );
    }

    if (field === 'Location') {
      return (
        <div className="equipment-field">
          <label>{label}:</label>
          {isEditing ? (
            <select
              value={editedValues.Location}
              onChange={(e) => handleInputChange('Location', e.target.value)}
              className="edit-select"
            >
              <option value="">Select Location</option>
              {locations.map(location => (
                <option key={location._id} value={location._id}>
                  {location.name}
                </option>
              ))}
            </select>
          ) : (
            <span className="field-value">
              {equipment.Location?.name || 
               locations.find(l => l._id === equipment.Location)?.name || 
               'N/A'}
            </span>
          )}
        </div>
      );
    }

    if (field === 'Serial') {
      const serialLabel = getSerialLabel(equipment.Type);
      return (
        <div className="equipment-field">
          <label>{serialLabel}:</label>
          {isEditing ? (
            <input
              type="text"
              value={editedValues[field] || ''}
              onChange={(e) => handleInputChange(field, e.target.value)}
              className="edit-input"
            />
          ) : (
            <span className="field-value">
              {equipment[field] || 'N/A'}
            </span>
          )}
        </div>
      );
    }

    return (
      <div className="equipment-field">
        <label>{label}:</label>
        {isEditing ? (
          <input
            type={field === 'Year' ? 'number' : 'text'}
            value={editedValues[field] || ''}
            onChange={(e) => handleInputChange(field, e.target.value)}
            className="edit-input"
          />
        ) : (
          <span className="field-value">
            {field === 'Type' ? equipment.Type?.name || 'N/A' :
             field === 'Category' ? equipment.Category?.name || 'N/A' :
             field === 'Location' ? equipment.Location?.name || 'N/A' :
             equipment[field] || 'N/A'}
          </span>
        )}
      </div>
    );
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('equipment-modal-overlay')) {
      onClose();
    }
  };

  const canEdit = user?.abilities?.includes('CanEditEquipment') || 
                  user?.roles?.some(role => role.name === 'Admin');

  const handleToggleMeters = async (field) => {
    try {
      const updateField = field === 'Hours' ? 'ignoreHours' : 'ignoreOdometer';
      const currentValue = field === 'Hours' ? equipment.ignoreHours : equipment.ignoreOdometer;
      
      const updatedEquipment = await updateEquipment(equipment._id, { 
        [updateField]: !currentValue 
      });
      
      setEquipment(prev => ({
        ...prev,
        [updateField]: !currentValue,
        Type: prev.Type,
        Category: prev.Category,
        Location: prev.Location
      }));
      
      onUpdate({
        ...equipment,
        [updateField]: !currentValue
      });
    } catch (error) {
      console.error(`Failed to update ${field} tracking:`, error);
    }
  };

  const handleEditMeter = (field) => {
    const currentValue = field === 'Hours' ? 
      currentReadings.hours : 
      currentReadings.odometer;
    
    setEditingField(field);
    setEditValue(currentValue?.toString() || '');
  };

  const handleSaveMeter = async (field) => {
    try {
      const meterValue = Number(editValue);

      const meterReadingData = {
        type: 'Equipment Edit',
        readings: {
          ...(field === 'Hours' ? { hours: meterValue } : {}),
          ...(field === 'Odometer' ? { odometer: meterValue } : {})
        },
        notes: `Updated ${field.toLowerCase()} via equipment modal`,
        maintenanceDetails: {
          fluidsAndFilters: [],
          completedChecklist: []
        }
      };

      console.log('Creating meter reading:', meterReadingData);

      // Only create the meter reading, don't update equipment
      await addMeterReading(equipment._id, meterReadingData);

      // Refresh the latest readings
      const latestReadings = await getLatestNonZeroReadings(equipment._id);
      setCurrentReadings({
        hours: latestReadings.hours,
        odometer: latestReadings.odometer
      });
      
      setEditingField(null);

      // Refresh meter readings modal if it's open
      if (showMeterReadingsModal) {
        setShowMeterReadingsModal(false);
        setTimeout(() => setShowMeterReadingsModal(true), 100);
      }
    } catch (error) {
      console.error(`Failed to update ${field}:`, error);
    }
  };

  const handleShowMeterHistory = (type) => {
    setSelectedMeterType(type);
    setShowMeterReadingsModal(true);
  };

  const handleEdit = (field, value) => {
    setEditingField(field);
    setEditValue(value || '');
  };

  const handleCancel = () => {
    setEditingField(null);
    setEditValue('');
  };

  const renderMeterField = (field, value) => {
    const isEditingThis = editingField === field;
    const currentValue = field === 'Hours' ? 
      currentReadings.hours : 
      currentReadings.odometer;
    
    console.log(`Rendering ${field} field with value:`, currentValue);
    
    return (
      <div className="meter-field">
        {isEditingThis ? (
          <input
            type="number"
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            className="meter-input"
          />
        ) : (
          <span className="meter-value">
            {currentValue > 0 ? currentValue : 'N/A'}
          </span>
        )}
        <div className="meter-actions">
          {isEditingThis ? (
            <>
              <FiCheckSquare 
                className="icon save" 
                onClick={() => handleSaveMeter(field)} 
              />
              <FiXSquare 
                className="icon cancel" 
                onClick={handleCancel} 
              />
            </>
          ) : (
            <>
              <FiEdit 
                className="icon edit" 
                onClick={() => handleEditMeter(field)} 
              />
              <button 
                className="history-button"
                onClick={() => handleShowMeterHistory(field)}
                aria-label={`View ${field} history`}
              >
                <FiClock />
              </button>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderEditableField = (field, value, isTitle = false) => {
    if (editingField === field) {
      return (
        <div className="editable-field">
          <input
            type="text"
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
          />
          <div className="edit-actions">
            <FiCheckSquare className="icon save" onClick={handleSave} />
            <FiXSquare className="icon cancel" onClick={handleCancel} />
          </div>
        </div>
      );
    }
    return (
      <div className="editable-field">
        {isTitle ? (
          <h3 className="modal-equipment-title">{value}</h3>
        ) : (
          <span className="field-value">{value || 'N/A'}</span>
        )}
        {canEdit && (
          <div className="edit-icon-container">
            <FiEdit className="icon edit" onClick={() => handleEdit(field, value || '')} />
          </div>
        )}
      </div>
    );
  };

  const fetchEquipment = async () => {
    try {
      const updatedEquipment = await getEquipmentById(equipment._id);
      setEquipment(updatedEquipment);
      onUpdate(updatedEquipment);
    } catch (error) {
      console.error('Error fetching equipment:', error);
    }
  };

  const renderLocationField = () => {
    if (editingField === 'Location') {
      return (
        <div className="editable-field">
          <select
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            className="location-select"
          >
            <option value="">Select Location</option>
            {locations.map(location => (
              <option key={location._id} value={location._id}>
                {location.name}
              </option>
            ))}
          </select>
          <div className="edit-actions">
            <FiCheckSquare className="icon save" onClick={handleSave} />
            <FiXSquare className="icon cancel" onClick={handleCancel} />
          </div>
        </div>
      );
    }
    return (
      <div className="editable-field">
        <span className="field-value">{equipment.Location?.name || 'N/A'}</span>
        {canEdit && (
          <div className="edit-icon-container">
            <FiEdit 
              className="icon edit" 
              onClick={() => handleEdit('Location', equipment.Location?._id || '')} 
            />
          </div>
        )}
      </div>
    );
  };

  const handleTypeChange = async (typeId) => {
    const selectedType = types.find(t => t._id === typeId);
    setSelectedType(selectedType);
    setAvailableCategories(selectedType?.categories || []);
    setEditedValues(prev => ({
      ...prev,
      Type: typeId,
      Category: ''
    }));
  };

  const handleUpdate = async (id, updates) => {
    try {
      const updatedEquipment = await updateEquipment(id, updates);
      setEquipment(updatedEquipment);
      onUpdate(updatedEquipment);
    } catch (error) {
      console.error('Failed to update equipment:', error);
    }
  };

  const handleMeterUpdate = async (type, value) => {
    try {
      // Create meter reading with the new schema
      const meterReadingData = {
        type: 'Meter Check',
        readings: {
          hours: type === 'Hours' ? Number(value) : null,
          odometer: type === 'Odometer' ? Number(value) : null
        },
        notes: `Updated ${type.toLowerCase()} via equipment modal`,
        maintenanceDetails: {
          fluidsAndFilters: [],
          completedChecklist: []
        }
      };

      // Create the meter reading
      await addMeterReading(equipment._id, meterReadingData);

      // Update equipment's current values
      const updateData = {
        hours: type === 'Hours' ? Number(value) : undefined,
        odometer: type === 'Odometer' ? Number(value) : undefined
      };

      await updateEquipmentMeters(equipment._id, updateData);

      // Update local state
      setEquipment(prev => ({
        ...prev,
        [type]: Number(value)
      }));

      // Refresh meter readings if they're being displayed
      if (showMeterReadings) {
        setShowMeterReadings(false);
        setTimeout(() => setShowMeterReadings(true), 100); // Add small delay for state update
      }

    } catch (error) {
      console.error(`Error updating ${type.toLowerCase()}:`, error);
      // Handle error appropriately
    }
  };

  const renderLicensePlateFields = () => {
    // Only show license plate fields if type is Vehicle
    const isVehicle = equipment.Type?.name === 'Vehicle';
    
    if (!isVehicle) {
      return null;
    }

    if (isEditing) {
      return (
        <div className="license-plate-fields">
          <div className="equipment-field">
            <label>License State:</label>
            <input
              type="text"
              value={editedValues.LicensePlate.State || ''}
              onChange={(e) => handleInputChange('LicensePlate', {
                ...editedValues.LicensePlate,
                State: e.target.value
              })}
              className="edit-input"
            />
          </div>
          <div className="equipment-field">
            <label>License Number:</label>
            <input
              type="text"
              value={editedValues.LicensePlate.Number || ''}
              onChange={(e) => handleInputChange('LicensePlate', {
                ...editedValues.LicensePlate,
                Number: e.target.value
              })}
              className="edit-input"
            />
          </div>
        </div>
      );
    }

    return (
      <div className="license-plate-info">
        <div className="equipment-field">
          <label>License Plate:</label>
          <span className="field-value">
            {equipment.LicensePlate?.State && equipment.LicensePlate?.Number
              ? `${equipment.LicensePlate.State} - ${equipment.LicensePlate.Number}`
              : 'N/A'}
          </span>
        </div>
      </div>
    );
  };

  const renderLogRequirements = () => {
    if (isEditing) {
      return (
        <div className="log-requirements">
          <div className="equipment-field">
            <label>
              <input
                type="checkbox"
                checked={editedValues.RequireFuelLog}
                onChange={(e) => handleInputChange('RequireFuelLog', e.target.checked)}
                className="checkbox-input"
              />
              Require Fuel Log
            </label>
          </div>
          <div className="equipment-field">
            <label>
              <input
                type="checkbox"
                checked={editedValues.RequireIndividualMileageLog}
                onChange={(e) => handleInputChange('RequireIndividualMileageLog', e.target.checked)}
                className="checkbox-input"
              />
              Require Individual Mileage Log
            </label>
          </div>
        </div>
      );
    }

    return (
      <div className="log-requirements">
        <div className="equipment-field">
          <label>Required Logs:</label>
          <span className="field-value">
            {[
              equipment.RequireFuelLog && 'Fuel',
              equipment.RequireIndividualMileageLog && 'Individual Mileage'
            ].filter(Boolean).join(', ') || 'None'}
          </span>
        </div>
      </div>
    );
  };

  const handlePartClick = (record) => {
    setEditingPart(record._id);
    setPartEditValues({
      quantityUsed: record.quantityUsed || 1,
      unit: record.unit || 'Each'
    });
  };

  const handleSavePartEdit = async (record) => {
    try {
      const updatedPart = {
        ...record,
        quantityUsed: partEditValues.quantityUsed,
        unit: partEditValues.unit
      };

      // Update the part in the equipment's previouslyUsedParts array
      const updatedEquipment = await updateEquipment(equipment._id, {
        previouslyUsedParts: equipment.previouslyUsedParts.map(p => 
          p._id === record._id ? updatedPart : p
        )
      });

      setEquipment(updatedEquipment);
      setEditingPart(null);
      setPartEditValues({});
      toast.success('Part details updated successfully');
    } catch (error) {
      console.error('Error updating part:', error);
      toast.error('Failed to update part details');
    }
  };

  const fetchAvailableParts = async () => {
    try {
      const parts = await getParts();
      setAvailableParts(parts);
    } catch (error) {
      console.error('Error fetching parts:', error);
      toast.error('Failed to load parts');
    }
  };

  const renderAddPartButton = () => (
    <button 
      className="add-part-history-button"
      onClick={() => {
        fetchAvailableParts();
        setShowAddPartModal(true);
      }}
    >
      <FiPlus /> Add Part to History
    </button>
  );

  const renderPartsSection = () => {
    // Group parts by mainCategory
    const groupedParts = equipment.previouslyUsedParts?.reduce((acc, record) => {
      const part = partsData[record.part];
      if (!part) return acc;

      const mainCategory = part.mainCategory || 'Other';
      if (!acc[mainCategory]) {
        acc[mainCategory] = [];
      }
      acc[mainCategory].push({ ...record, partData: part });
      return acc;
    }, {});

    const categoryOrder = ['Fluid', 'Filter', 'Part'];

    return (
      <div className="equipment-parts-section">
        <div className="section-header">
          <div className="header-content">
            {isPartsExpanded ? <FiChevronDown /> : <FiChevronRight />}
            <h3>Parts History</h3>
          </div>
          <button 
            className="add-part-button"
            onClick={(e) => {
              e.stopPropagation();
              fetchAvailableParts();
              setShowAddPartModal(true);
            }}
            title="Add Part"
          >
            <FiPlus />
          </button>
        </div>

        {isPartsExpanded && (
          <div className="parts-content">
            {categoryOrder.map(mainCategory => {
              const parts = groupedParts?.[mainCategory] || [];
              if (parts.length === 0) return null;

              return (
                <div key={mainCategory} className="parts-category">
                  <h4>{mainCategory}s</h4>
                  <div className="parts-list">
                    {parts.map((record) => (
                      <div key={record._id} className="part-item">
                        <div className="part-details">
                          <div className="part-header">
                            <div className="part-info">
                              <span className="part-name">
                                {record.partData.manufacturer} - {record.partData.partNumber}
                              </span>
                              <span className="part-category">
                                {record.category}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="part-usage-container">
                          {editingPart === record._id ? (
                            <div className="part-edit-controls">
                              <input
                                type="number"
                                value={partEditValues.quantityUsed}
                                onChange={(e) => setPartEditValues(prev => ({
                                  ...prev,
                                  quantityUsed: Number(e.target.value)
                                }))}
                                min="1"
                                onClick={e => e.stopPropagation()}
                              />
                              <select
                                value={partEditValues.unit}
                                onChange={(e) => setPartEditValues(prev => ({
                                  ...prev,
                                  unit: e.target.value
                                }))}
                                onClick={e => e.stopPropagation()}
                              >
                                {unitOptions.map(unit => (
                                  <option key={unit} value={unit}>{unit}</option>
                                ))}
                              </select>
                              <button 
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSavePartEdit(record);
                                }}
                                className="save-edit-button"
                              >
                                Save
                              </button>
                              <button 
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEditingPart(null);
                                }}
                                className="cancel-edit-button"
                              >
                                Cancel
                              </button>
                            </div>
                          ) : (
                            <div 
                              className="part-quantity"
                              onClick={(e) => {
                                e.stopPropagation();
                                handlePartClick(record);
                              }}
                            >
                              Qty: {record.quantityUsed} {record.unit}
                            </div>
                          )}
                          <div className="part-usage">
                            <span className="usage-count">
                              Used {record.useCount} time{record.useCount !== 1 ? 's' : ''}
                            </span>
                            <span className="last-used">
                              Last: {new Date(record.lastUsed).toLocaleDateString('en-US', {
                                month: '2-digit',
                                day: '2-digit',
                                year: '2-digit'
                              })}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const getSubCategories = (mainCategory) => {
    switch (mainCategory) {
      case 'Fluid':
        return [
          'Engine Oil',
          'Transmission Oil',
          'Hydraulic Oil',
          'Coolant',
          'Compressor Oil',
          'Clutch Oil'
        ];
      case 'Filter':
        return [
          'Engine Oil Filter',
          'Transmission Filter',
          'Hydraulic Filter',
          'Air Filter',
          'Fuel Filter',
          'Fuel Water Separator'
        ];
      case 'Part':
        return [
          'Brake',
          'Suspension',
          'Electrical',
          'Engine',
          'Transmission',
          'Other'
        ];
      default:
        return [];
    }
  };

  const handleSelectPart = async (part) => {
    try {
      const updatedParts = [{
        part: part._id,
        category: selectedSubCategory,
        quantityUsed: 1,
        unit: part.unit || 'Each',
        lastUsed: new Date(),
        useCount: 1
      }];

      const updatedEquipment = await updateEquipment(equipment._id, {
        previouslyUsedParts: [
          ...equipment.previouslyUsedParts,
          ...updatedParts
        ]
      });

      setEquipment(updatedEquipment);
      setShowAddPartModal(false);
      toast.success('Part added to history successfully');
    } catch (error) {
      console.error('Error adding part to history:', error);
      toast.error('Failed to add part to history');
    }
  };

  const handleCreatePart = async () => {
    try {
      const updatedEquipment = await createPart(newPart);
      setEquipment(updatedEquipment);
      setShowNewPartForm(false);
      toast.success('New part created successfully');
    } catch (error) {
      console.error('Error creating new part:', error);
      toast.error('Failed to create new part');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div 
        className={isInlineDisplay ? "equipment-details" : "equipment-modal-overlay"} 
        onClick={isInlineDisplay ? undefined : handleOverlayClick}
      >
        <div className={isInlineDisplay ? "" : "equipment-modal-container"} onClick={e => e.stopPropagation()}>
          {console.log('User permissions:', { user, canEdit })}
          
          {(canEdit || user?.role === 'Admin') && (
            <button 
              className="equipment-modal-edit"
              onClick={() => setIsEditing(!isEditing)}
              title={isEditing ? "Cancel Editing" : "Edit Equipment"}
            >
              <FiEdit size={16} />
            </button>
          )}
          <div className="equipment-modal-header">
            <div className="equipment-modal-title">
              <span className="equipment-modal-id">{equipment.ID}</span>
              <span className="equipment-modal-category">
                {equipment.Category?.name || 'No Category'}
              </span>
            </div>
            <div className="equipment-modal-status">
              {isEditing ? (
                <div className="status-field">
                  <label>Status:</label>
                  <select
                    value={editedValues.Status || equipment.Status}
                    onChange={(e) => handleInputChange('Status', e.target.value)}
                    className="status-select"
                  >
                    {EQUIPMENT_STATUSES.map(status => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <span style={{ color: getStatusColor(equipment.Status) }}>
                  {equipment.Status}
                </span>
              )}
            </div>
          </div>

          <div className="equipment-modal-body">
            {isEditing && (
              <>
                {renderField('ID', 'ID')}
                {renderField('Type', 'Type')}
                {renderField('Category', 'Category')}
              </>
            )}
            
            <div className="equipment-field-row">
              {renderField('Make', 'Make')}
              {renderField('Model', 'Model')}
            </div>
            {renderField('Year', 'Year')}
            {renderField(getSerialLabel(equipment.Type), 'Serial')}
            {renderField('Location', 'Location')}

            <div className="equipment-meter-field">
              <div className="equipment-meter-container">
                <button 
                  className="equipment-meter-checkbox"
                  onClick={() => handleToggleMeters('Hours')}
                  aria-label={equipment.ignoreHours ? "Enable hours tracking" : "Disable hours tracking"}
                >
                  {equipment.ignoreHours ? <FiSquare /> : <FiCheckSquare />}
                </button>
                <label className="equipment-modal-label">Hours:</label>
                {renderMeterField('Hours', equipment.Hours)}
              </div>
            </div>

            <div className="equipment-meter-field">
              <div className="equipment-meter-container">
                <button 
                  className="equipment-meter-checkbox"
                  onClick={() => handleToggleMeters('Odometer')}
                  aria-label={equipment.ignoreOdometer ? "Enable odometer tracking" : "Disable odometer tracking"}
                >
                  {equipment.ignoreOdometer ? <FiSquare /> : <FiCheckSquare />}
                </button>
                <label className="equipment-modal-label">Odometer:</label>
                {renderMeterField('Odometer', equipment.Odometer)}
              </div>
            </div>

            {renderLicensePlateFields()}
            {renderLogRequirements()}

            <div className="equipment-notes-field">
              <label className="equipment-modal-label">Notes:</label>
              {renderEditableField('Notes', equipment.Notes)}
            </div>

            <MaintenanceScheduleSection 
              equipment={equipment}
              onUpdate={(updates) => handleUpdate(equipment._id, updates)}
              isEditing={isEditingMaintenanceSchedule}
              canEdit={canEdit}
              onToggleEdit={() => setIsEditingMaintenanceSchedule(!isEditingMaintenanceSchedule)}
            />

            {renderPartsSection()}
          </div>

          {isEditing && (
            <div className="equipment-modal-actions">
              <button onClick={handleSave} className="equipment-modal-save">Save Changes</button>
              <button onClick={() => setIsEditing(false)} className="equipment-modal-cancel">Cancel</button>
            </div>
          )}
        </div>
      </div>

      {showMeterReadingsModal && (
        <MeterReadingsModal
          equipment={equipment}
          type={selectedMeterType}
          onClose={() => setShowMeterReadingsModal(false)}
          onUpdate={() => {
            fetchEquipment();
            setShowMeterReadingsModal(false);
          }}
        />
      )}

      {showAddPartModal && (
        <div className="modal-overlay" onClick={() => setShowAddPartModal(false)}>
          <div className="add-part-history-modal" onClick={e => e.stopPropagation()}>
            <h3>Add Part to History</h3>
            
            {!selectedCategory ? (
              // Step 1: Select main category
              <div className="category-grid">
                {['Fluid', 'Filter', 'Part'].map(category => (
                  <button
                    key={category}
                    className="category-button"
                    onClick={() => setSelectedCategory(category)}
                  >
                    {category}
                  </button>
                ))}
              </div>
            ) : !selectedSubCategory ? (
              // Step 2: Select subcategory based on main category
              <div className="subcategory-section">
                <button className="back-button" onClick={() => setSelectedCategory(null)}>
                  ← Back to Categories
                </button>
                <div className="subcategory-grid">
                  {getSubCategories(selectedCategory).map(subCat => (
                    <button
                      key={subCat}
                      className="subcategory-button"
                      onClick={() => setSelectedSubCategory(subCat)}
                    >
                      {subCat}
                    </button>
                  ))}
                </div>
              </div>
            ) : (
              // Step 3: Select part or create new one
              <div className="part-selection-section">
                <button 
                  className="back-button" 
                  onClick={() => setSelectedSubCategory(null)}
                >
                  ← Back to {selectedCategory} Types
                </button>
                
                <h4>Available Parts</h4>
                <div className="available-parts-list">
                  {availableParts
                    .filter(part => part.category === selectedSubCategory)
                    .map(part => (
                      <div key={part._id} className="part-option">
                        <div className="part-info">
                          <span className="part-name">
                            {part.manufacturer} - {part.partNumber}
                          </span>
                          <span className="part-stock">
                            In stock: {part.inventory?.quantity || 0}
                          </span>
                        </div>
                        <button 
                          onClick={() => handleSelectPart(part)}
                          className="select-part-button"
                        >
                          Select
                        </button>
                      </div>
                    ))}
                </div>

                <div className="create-part-section">
                  <button 
                    onClick={() => setShowNewPartForm(true)}
                    className="create-part-button"
                  >
                    + Create New Part
                  </button>
                </div>
              </div>
            )}

            {showNewPartForm && (
              <div className="new-part-form">
                <h4>Create New Part</h4>
                <form onSubmit={handleCreatePart}>
                  <div className="form-group">
                    <label>Manufacturer</label>
                    <input
                      type="text"
                      value={newPart.manufacturer}
                      onChange={(e) => setNewPart(prev => ({
                        ...prev,
                        manufacturer: e.target.value
                      }))}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Part Number</label>
                    <input
                      type="text"
                      value={newPart.partNumber}
                      onChange={(e) => setNewPart(prev => ({
                        ...prev,
                        partNumber: e.target.value
                      }))}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <input
                      type="text"
                      value={newPart.description}
                      onChange={(e) => setNewPart(prev => ({
                        ...prev,
                        description: e.target.value
                      }))}
                    />
                  </div>
                  <div className="form-actions">
                    <button type="submit" className="save-button">
                      Create Part
                    </button>
                    <button 
                      type="button" 
                      className="cancel-button"
                      onClick={() => setShowNewPartForm(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default EquipmentModal; 