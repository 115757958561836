import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';
import { addMeterReading } from '../services/meterReadingService';
import { toast } from 'react-toastify';
import '../styles/MaintenanceServiceHistoryModal.css';

const MaintenanceServiceHistoryModal = ({ equipment, maintenanceItem, onClose, onUpdate }) => {
  const [readings, setReadings] = useState({
    hours: '',
    odometer: '',
    date: new Date().toISOString().split('T')[0]
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  // Determine which readings to track based on maintenance item settings
  const tracksOdometer = maintenanceItem.odometerInterval || maintenanceItem.maintenanceItem?.odometerInterval;
  const tracksHours = maintenanceItem.hoursInterval || maintenanceItem.maintenanceItem?.hoursInterval;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    
    setIsSubmitting(true);
    setError('');

    try {
      // Validate inputs
      if (!readings.date) {
        throw new Error('Service date is required');
      }

      // Only require readings that are being tracked
      if (tracksOdometer && !readings.odometer) {
        throw new Error('Odometer reading is required for this service');
      }
      if (tracksHours && !readings.hours) {
        throw new Error('Hours reading is required for this service');
      }

      // Create a proper Date object from the input date
      // Set the time to noon UTC to avoid timezone issues
      const serviceDate = new Date(readings.date);
      serviceDate.setUTCHours(12, 0, 0, 0);

      const meterReadingData = {
        type: 'Service History Update',
        readings: {
          hours: tracksHours && readings.hours ? Number(readings.hours) : null,
          odometer: tracksOdometer && readings.odometer ? Number(readings.odometer) : null
        },
        date: serviceDate.toISOString(), // Send as ISO string
        notes: `Historical service record update for ${maintenanceItem.type || maintenanceItem.maintenanceItem?.name}`,
        maintenanceDetails: {
          fluidsAndFilters: [],
          completedChecklist: [{
            item: maintenanceItem.type || maintenanceItem.maintenanceItem?.name,
            completed: true,
            notes: 'Service history update'
          }]
        }
      };

      console.log('Submitting service history update:', {
        equipmentId: equipment._id,
        readings: meterReadingData.readings,
        date: meterReadingData.date,
        type: meterReadingData.type
      });

      const result = await addMeterReading(equipment._id, meterReadingData);
      
      console.log('Service history update successful:', {
        readingId: result._id,
        date: result.date,
        readings: result.readings
      });

      toast.success('Service history updated successfully');
      onUpdate();
      onClose();
    } catch (error) {
      console.error('Service history update failed:', error);
      setError(error.message || 'Failed to update service history');
      toast.error(error.message || 'Failed to update service history');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="modal-overlay" onClick={(e) => {
      if (e.target === e.currentTarget) onClose();
    }}>
      <div className="service-history-modal" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h2>Update Service History</h2>
          <button 
            className="close-button" 
            onClick={onClose}
            disabled={isSubmitting}
          >
            <FiX />
          </button>
        </div>

        {error && (
          <div className="error-message">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="modal-content">
            <div className="service-info">
              <h3>Service: {maintenanceItem.type || maintenanceItem.maintenanceItem?.name}</h3>
              <p>Equipment: {equipment.ID}</p>
            </div>

            <div className="form-group">
              <label>Service Date: *</label>
              <input
                type="date"
                value={readings.date}
                onChange={e => setReadings(prev => ({ ...prev, date: e.target.value }))}
                required
                max={new Date().toISOString().split('T')[0]}
              />
            </div>

            {/* Show odometer field first if it's being tracked */}
            {tracksOdometer && !equipment.ignoreOdometer && (
              <div className="form-group">
                <label>Odometer at Service: *</label>
                <input
                  type="number"
                  value={readings.odometer}
                  onChange={e => setReadings(prev => ({ ...prev, odometer: e.target.value }))}
                  placeholder="Enter odometer reading"
                  min="0"
                  required
                />
              </div>
            )}

            {/* Show hours field second if it's being tracked */}
            {tracksHours && !equipment.ignoreHours && (
              <div className="form-group">
                <label>Hours at Service: *</label>
                <input
                  type="number"
                  value={readings.hours}
                  onChange={e => setReadings(prev => ({ ...prev, hours: e.target.value }))}
                  placeholder="Enter hours reading"
                  min="0"
                  step="0.1"
                  required
                />
              </div>
            )}
          </div>

          <div className="modal-actions">
            <button 
              type="submit" 
              className="save-button"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Updating...' : 'Update Service History'}
            </button>
            <button 
              type="button" 
              className="cancel-button" 
              onClick={onClose}
              disabled={isSubmitting}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MaintenanceServiceHistoryModal; 