import axiosInstance from './api';

// Fetch all parts with optional query parameters
export const getParts = async (params = {}) => {
  try {
    const response = await axiosInstance.get('/parts', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching parts:', error);
    throw error;
  }
};

// Fetch parts associated with a specific equipment
export const getPartsByEquipment = async (equipmentId) => {
  try {
    const response = await axiosInstance.get(`/parts/equipment/${equipmentId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching parts for equipment:', error);
    throw error;
  }
};

// Adjust the inventory of a part
export const updatePartInventory = async (partId, adjustment, reason) => {
  try {
    const response = await axiosInstance.post(`/parts/${partId}/inventory/adjust`, {
      adjustment,
      reason
    });
    return response.data;
  } catch (error) {
    console.error('Error adjusting part inventory:', error);
    throw error;
  }
};

// Create a new part
export const createPart = async (partData) => {
  try {
    const response = await axiosInstance.post('/parts', partData);
    return response.data;
  } catch (error) {
    console.error('Error creating part:', error);
    throw error;
  }
};

// Update a part
export const updatePart = async (id, updates) => {
  try {
    const response = await axiosInstance.put(`/parts/${id}`, updates);
    return response.data;
  } catch (error) {
    console.error('Error updating part:', error);
    throw error;
  }
};

// Get a single part
export const getPart = async (id) => {
  try {
    const response = await axiosInstance.get(`/parts/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching part:', error);
    throw error;
  }
};

// Add this new function for deleting parts
export const deletePart = async (id) => {
  try {
    const response = await axiosInstance.delete(`/parts/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting part:', error);
    throw error;
  }
}; 