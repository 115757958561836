import React, { useState } from 'react'
import { FaPrint } from 'react-icons/fa'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog'

const AssignedOrders = () => {
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false)

  const handlePrint = (selection) => {
    // TODO: Implement print functionality
    console.log('Printing:', selection)
    setIsPrintModalOpen(false)
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Assigned Orders</h2>
        
        <button
          onClick={() => setIsPrintModalOpen(true)}
          className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
          aria-label="Print Work Orders"
        >
          <FaPrint className="text-lg" />
          <span>Print Work Orders</span>
        </button>
      </div>

      {/* Print Modal */}
      <Dialog open={isPrintModalOpen} onOpenChange={setIsPrintModalOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Print Work Orders</DialogTitle>
          </DialogHeader>
          
          <div className="grid gap-4 py-4">
            <button
              onClick={() => handlePrint('blank')}
              className="w-full p-3 text-left border rounded-md hover:bg-gray-50"
            >
              Blank Work Order Form
            </button>
            
            <div className="text-sm text-gray-500 font-medium px-2">
              - OR -
            </div>
            
            <button
              onClick={() => handlePrint('equipment')}
              className="w-full p-3 text-left border rounded-md hover:bg-gray-50"
            >
              Select Equipment ID
            </button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Main content area - we'll add the work orders list here later */}
      <div className="mt-6">
        {/* Work orders will be listed here */}
      </div>
    </div>
  )
}

export default AssignedOrders