import axiosInstance from './api';

export const addMeterReading = async (equipmentId, readingData) => {
  try {
    console.log('Sending meter reading data:', { equipmentId, readingData });
    const response = await axiosInstance.post(`/meter-readings/${equipmentId}`, readingData);
    console.log('Meter reading response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error adding meter reading:', error);
    throw error;
  }
};

export const getMeterReadings = async (equipmentId, type = null) => {
  try {
    const params = type ? { type } : {};
    const response = await axiosInstance.get(`/meter-readings/equipment/${equipmentId}`, { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching meter readings:', error);
    throw error;
  }
};

export const getLatestMeterReading = async (equipmentId, type = null) => {
  try {
    const params = type ? { type } : {};
    const response = await axiosInstance.get(`/meter-readings/equipment/${equipmentId}/latest`, { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching latest meter reading:', error);
    throw error;
  }
};

export const getMeterReadingsByDateRange = async (equipmentId, startDate, endDate, type = null) => {
  try {
    const params = {
      startDate,
      endDate,
      ...(type && { type })
    };
    const response = await axiosInstance.get(`/meter-readings/equipment/${equipmentId}/range`, { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching meter readings by date range:', error);
    throw error;
  }
};

export const getLatestNonZeroReadings = async (equipmentId) => {
  try {
    const response = await axiosInstance.get(`/meter-readings/${equipmentId}/latest-non-zero`, {
      params: {
        excludeTypes: ['Service History Update', 'Historical Service Entry']
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching latest non-zero readings:', error);
    return {
      hours: null,
      odometer: null
    };
  }
}; 