import React, { useState, useEffect } from 'react';
import '../styles/WorkOrderFilterModal.css';

function WorkOrderFilterModal({ 
  filters, 
  onApply, 
  onClose, 
  onReset, 
  locationList, 
  authorList, 
  canViewAllWorkOrders, 
  equipmentList, 
  equipmentTypes, 
  equipmentCategories 
}) {
  // Define the same default filters
  const defaultFilters = {
    status: 'open',
    type: 'All',
    category: 'All',
    equipment: 'All',
    author: 'All',
    location: 'All Locations'
  };

  // Initialize with provided filters or defaults
  const [localFilters, setLocalFilters] = useState({
    ...defaultFilters,
    ...filters
  });

  // Derived state for filtered options
  const [availableCategories, setAvailableCategories] = useState([]);
  const [availableEquipment, setAvailableEquipment] = useState([]);

  // Update available categories when type changes
  useEffect(() => {
    if (localFilters.type === 'All') {
      setAvailableCategories([]);
    } else {
      const selectedType = equipmentTypes.find(type => type._id === localFilters.type);
      if (selectedType && selectedType.categories) {
        setAvailableCategories(selectedType.categories);
      }
    }
    // Reset category and equipment when type changes
    setLocalFilters(prev => ({
      ...prev,
      category: 'All',
      equipment: 'All'
    }));
  }, [localFilters.type, equipmentTypes]);

  // Update available equipment when category changes
  useEffect(() => {
    if (localFilters.type === 'All' || localFilters.category === 'All') {
      setAvailableEquipment([]);
    } else {
      const equipmentInCategory = equipmentList.filter(eq => 
        eq.Type._id === localFilters.type &&
        eq.Category === localFilters.category
      );
      setAvailableEquipment(equipmentInCategory);
    }
    // Reset equipment when category changes
    setLocalFilters(prev => ({
      ...prev,
      equipment: 'All'
    }));
  }, [localFilters.type, localFilters.category, equipmentList]);

  const handleChange = (field, value) => {
    setLocalFilters(prev => ({ ...prev, [field]: value }));
  };

  const handleReset = () => {
    setLocalFilters(defaultFilters);
    onReset();
  };

  const handleApply = () => {
    // Ensure all filter fields are present
    const completeFilters = {
      ...defaultFilters,
      ...localFilters
    };
    onApply(completeFilters);
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[12000]"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">Filter Work Orders</h2>
        
        {/* Filter controls */}
        <div className="space-y-4">
          {/* Status filter */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Status:</label>
            <select 
              className="w-full border border-gray-300 rounded-md p-2"
              value={localFilters.status} 
              onChange={(e) => handleChange('status', e.target.value)}
            >
              <option value="All">All Statuses</option>
              <option value="open">Open</option>
              <option value="postponed">Postponed</option>
              <option value="closed">Closed</option>
            </select>
          </div>

          {/* Equipment Type Filter */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Equipment Type
            </label>
            <select 
              className="w-full border border-gray-300 rounded-md p-2"
              value={localFilters.type}
              onChange={(e) => handleChange('type', e.target.value)}
            >
              <option value="All">All Types</option>
              {equipmentTypes.map(type => (
                <option key={type._id} value={type._id}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>

          {/* Category Filter - Only show if type is selected */}
          {localFilters.type !== 'All' && availableCategories.length > 0 && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Category
              </label>
              <select 
                className="w-full border border-gray-300 rounded-md p-2"
                value={localFilters.category}
                onChange={(e) => handleChange('category', e.target.value)}
              >
                <option value="All">All Categories</option>
                {availableCategories.map(category => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Equipment Filter - Only show if category is selected */}
          {localFilters.category !== 'All' && availableEquipment.length > 0 && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Equipment
              </label>
              <select 
                className="w-full border border-gray-300 rounded-md p-2"
                value={localFilters.equipment}
                onChange={(e) => handleChange('equipment', e.target.value)}
              >
                <option value="All">All Equipment</option>
                {availableEquipment.map(eq => (
                  <option key={eq._id} value={eq.ID}>
                    {eq.ID} - {eq.Make} {eq.Model}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Location Filter */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Location
            </label>
            <select 
              className="w-full border border-gray-300 rounded-md p-2"
              value={localFilters.location}
              onChange={(e) => handleChange('location', e.target.value)}
            >
              <option value="All Locations">All Locations</option>
              {locationList.map(location => (
                <option key={location._id} value={location.name}>
                  {location.name}
                </option>
              ))}
            </select>
          </div>

          {/* Author Filter - Only show if user can view all work orders */}
          {canViewAllWorkOrders && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Author
              </label>
              <select 
                className="w-full border border-gray-300 rounded-md p-2"
                value={localFilters.author}
                onChange={(e) => handleChange('author', e.target.value)}
              >
                <option value="All">All Authors</option>
                {authorList.map(author => (
                  <option key={author._id} value={author._id}>
                    {author.displayName || author.username}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        {/* Updated Action Buttons - using flex with justify-between */}
        <div className="flex justify-between items-center mt-6">
          {/* Cancel button on the left */}
          <button
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
            onClick={onClose}
          >
            Cancel
          </button>

          {/* Reset and Apply buttons grouped on the right */}
          <div className="flex gap-2">
            <button
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
              onClick={onReset}
            >
              Reset
            </button>
            <button
              className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition-colors"
              onClick={() => onApply(localFilters)}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkOrderFilterModal; 