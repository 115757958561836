import axiosInstance from './api';

// Update user profile
export const updateUserProfile = async (userId, profileData) => {
  const response = await axiosInstance.put(`/users/${userId}/profile`, profileData);
  return response.data;
};

// Change password for the authenticated user
export const changeOwnPassword = async (userId, passwordData) => {
  try {
    const response = await axiosInstance.put('/auth/change-password', { userId, passwordData });
    return response.data;
  } catch (error) {
    console.error('Error in changeOwnPassword:', error.response || error);
    throw error;
  }
};

export const getUsers = async () => {
  try {
    const response = await axiosInstance.get('/users');
    //console.log('API response:', response.data);
    return response.data.data.map(user => ({
      ...user,
      abilities: user.roles.flatMap(role => role.abilities)
    }));
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const updateUserTrackedEquipment = async (userId, trackedEquipment) => {
  try {
    const response = await axiosInstance.put(`/users/${userId}/tracked-equipment`, { trackedEquipment });
    return response.data;
  } catch (error) {
    throw error;
  }
};


