import React, { useState, useEffect } from 'react';
import { FiPlus, FiRefreshCw, FiPower, FiX } from 'react-icons/fi';
import { getDevices, registerDevice, regenerateToken, deactivateDevice } from '../../services/deviceService';
import { getEquipment } from '../../services/equipmentService';

function DeviceManagement() {
  const [devices, setDevices] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showTokenModal, setShowTokenModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [error, setError] = useState('');
  const [newToken, setNewToken] = useState('');

  useEffect(() => {
    fetchDevices();
    fetchEquipment();
  }, []);

  const fetchDevices = async () => {
    try {
      const data = await getDevices();
      setDevices(data);
      setError('');
    } catch (err) {
      setError('Failed to fetch devices');
    }
  };

  const fetchEquipment = async () => {
    try {
      const data = await getEquipment();
      setEquipment(data);
      setError('');
    } catch (err) {
      setError('Failed to fetch equipment');
    }
  };

  const handleRegisterDevice = async (deviceData) => {
    try {
      await registerDevice(deviceData);
      fetchDevices();
      setShowAddModal(false);
      setError('');
    } catch (err) {
      setError('Failed to register device');
    }
  };

  const handleRegenerateToken = async (deviceId) => {
    try {
      const response = await regenerateToken(deviceId);
      setNewToken(response.accessToken);
      setShowTokenModal(true);
      fetchDevices();
      setError('');
    } catch (err) {
      setError('Failed to regenerate token');
    }
  };

  const handleDeactivateDevice = async (deviceId) => {
    if (window.confirm('Are you sure you want to deactivate this device?')) {
      try {
        await deactivateDevice(deviceId);
        fetchDevices();
        setError('');
      } catch (err) {
        setError('Failed to deactivate device');
      }
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-2xl font-bold text-gray-900">Device Management</h2>
          <p className="mt-1 text-sm text-gray-500">
            Manage your IoT devices and their access tokens
          </p>
        </div>
        <button
          onClick={() => setShowAddModal(true)}
          className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-150"
        >
          <FiPlus className="mr-2" /> Add Device
        </button>
      </div>

      {error && (
        <div className="mb-4 p-4 bg-red-50 border border-red-200 text-red-700 rounded-md">
          {error}
        </div>
      )}

      <div className="mt-4 overflow-hidden rounded-lg border border-gray-200">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Device ID</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Equipment</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Seen</th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {devices.map((device) => (
                <tr key={device._id} className={!device.isActive ? 'bg-gray-50' : 'hover:bg-gray-50'}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {device.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {device.deviceId}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {device.equipment?.ID || 'N/A'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`inline-flex px-2 py-1 text-xs font-semibold rounded-full ${
                      device.isActive
                        ? 'bg-green-100 text-green-800'
                        : 'bg-gray-100 text-gray-800'
                    }`}>
                      {device.isActive ? 'Active' : 'Inactive'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {device.lastSeen ? new Date(device.lastSeen).toLocaleString() : 'Never'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
                    <button
                      onClick={() => handleRegenerateToken(device._id)}
                      disabled={!device.isActive}
                      className={`inline-flex items-center p-1.5 border border-transparent rounded-md ${
                        device.isActive
                          ? 'text-indigo-700 bg-indigo-100 hover:bg-indigo-200'
                          : 'text-gray-400 bg-gray-100 cursor-not-allowed'
                      } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      title="Regenerate Token"
                    >
                      <FiRefreshCw className="w-4 h-4" />
                    </button>
                    {device.isActive && (
                      <button
                        onClick={() => handleDeactivateDevice(device._id)}
                        className="inline-flex items-center p-1.5 border border-transparent rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        title="Deactivate Device"
                      >
                        <FiPower className="w-4 h-4" />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showAddModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-[9999]">
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-lg w-full">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-gray-900">Add New Device</h2>
              <button
                onClick={() => setShowAddModal(false)}
                className="text-gray-400 hover:text-gray-500 focus:outline-none"
              >
                <span className="sr-only">Close</span>
                <FiX className="w-6 h-6" />
              </button>
            </div>
            <AddDeviceForm
              onClose={() => setShowAddModal(false)}
              onSubmit={handleRegisterDevice}
              equipment={equipment}
            />
          </div>
        </div>
      )}

      {showTokenModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-[9999]">
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-lg w-full">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-gray-900">New Access Token</h2>
              <button
                onClick={() => {
                  setShowTokenModal(false);
                  setNewToken('');
                }}
                className="text-gray-400 hover:text-gray-500 focus:outline-none"
              >
                <span className="sr-only">Close</span>
                <FiX className="w-6 h-6" />
              </button>
            </div>
            <div className="bg-gray-50 p-4 rounded-md">
              <p className="text-sm text-gray-500 mb-2">
                Please copy this token now. You won't be able to see it again!
              </p>
              <div className="font-mono text-sm bg-white p-3 rounded border border-gray-200 break-all">
                {newToken}
              </div>
            </div>
            <div className="mt-6 flex justify-end">
              <button
                onClick={() => {
                  setShowTokenModal(false);
                  setNewToken('');
                }}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function AddDeviceForm({ onClose, onSubmit, equipment }) {
  const [formData, setFormData] = useState({
    name: '',
    deviceId: '',
    equipmentId: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
          Device Name
        </label>
        <input
          type="text"
          id="name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>

      <div>
        <label htmlFor="deviceId" className="block text-sm font-medium text-gray-700">
          Device ID
        </label>
        <input
          type="text"
          id="deviceId"
          value={formData.deviceId}
          onChange={(e) => setFormData({ ...formData, deviceId: e.target.value })}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>

      <div>
        <label htmlFor="equipment" className="block text-sm font-medium text-gray-700">
          Equipment
        </label>
        <select
          id="equipment"
          value={formData.equipmentId}
          onChange={(e) => setFormData({ ...formData, equipmentId: e.target.value })}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="">Select Equipment</option>
          {equipment.map((eq) => (
            <option key={eq._id} value={eq._id}>
              {eq.ID} - {eq.Make} {eq.Model}
            </option>
          ))}
        </select>
      </div>

      <div className="mt-6 flex justify-end space-x-3">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Add Device
        </button>
      </div>
    </form>
  );
}

export default DeviceManagement;
