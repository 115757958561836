import React from 'react';

const PartsList = ({ parts, onSelectPart, selectedPart, sortConfig }) => {
  const getSortedParts = () => {
    const sortedParts = [...parts];
    if (sortConfig) {
      sortedParts.sort((a, b) => {
        if (sortConfig.field === 'inventory.quantity') {
          const aValue = a.inventory?.quantity || 0;
          const bValue = b.inventory?.quantity || 0;
          return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
        }
        
        const aValue = a[sortConfig.field] || '';
        const bValue = b[sortConfig.field] || '';
        return sortConfig.direction === 'asc' 
          ? aValue.toString().localeCompare(bValue.toString())
          : bValue.toString().localeCompare(aValue.toString());
      });
    }
    return sortedParts;
  };

  return (
    <ul className="divide-y divide-gray-200">
      {getSortedParts().map(part => (
        <li
          key={part._id}
          className={`
            relative flex flex-col p-2 cursor-pointer hover:bg-gray-50
            ${selectedPart?._id === part._id ? 'bg-gray-100' : ''}
          `}
          onClick={() => onSelectPart(part)}
        >
          <div className="flex justify-between items-start">
            <span className="font-bold text-lg">{part.partNumber}</span>
            <span className="text-sm text-gray-600">{part.manufacturer}</span>
          </div>
          <div className="flex justify-between items-end mt-1">
            <span className="text-sm text-gray-500">{part.category}</span>
            <div className="flex items-center gap-1">
              <span className="text-sm">
                Stock: {part.inventory?.quantity || 0} {part.unit}
              </span>
              {part.inventory?.quantity <= part.inventory?.minimumQuantity && (
                <span className="px-1.5 py-0.5 text-xs font-medium bg-red-100 text-red-800 rounded">
                  Low Stock
                </span>
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default PartsList;