import React, { useState } from 'react';
import './EditEquipmentTypeModal.css';

function EditEquipmentTypeModal({ type, onSubmit, onClose }) {
  const [typeName, setTypeName] = useState(type.name);
  const [typeDescription, setTypeDescription] = useState(type.description || '');
  const [categories, setCategories] = useState(type.categories);

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedType = {
      ...type,
      name: typeName,
      description: typeDescription,
      categories: categories.map(cat => {
        if (cat._id) {
          return {
            _id: cat._id,
            name: cat.name
          }
        }
        return { name: cat.name }
      })
    };
    console.log('Submitting updated type:', updatedType);
    onSubmit(updatedType);
  };

  const handleAddCategory = () => {
    setCategories([...categories, { name: '' }]);
  };

  const handleUpdateCategory = (index, newName) => {
    const updatedCategories = [...categories];
    updatedCategories[index] = { ...updatedCategories[index], name: newName };
    console.log('Updated category:', updatedCategories[index]);
    setCategories(updatedCategories);
  };

  const handleDeleteCategory = (index) => {
    setCategories(categories.filter((_, i) => i !== index));
  };

  return (
    <div className="modal-overlay">
      <div className="equipment-type-modal">
        <h2>Edit Equipment Type</h2>
        <form onSubmit={handleSubmit}>
          <div className="modal-content">
            <div className="form-group">
              <label htmlFor="typeName">Type:</label>
              <input
                type="text"
                id="typeName"
                value={typeName}
                onChange={(e) => setTypeName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="typeDescription">Description:</label>
              <textarea
                id="typeDescription"
                value={typeDescription}
                onChange={(e) => setTypeDescription(e.target.value)}
                rows="3"
              />
            </div>
            <div className="categories-section">
              <h3>Categories</h3>
              <div className="categories-table-container">
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories.map((category, index) => (
                      <tr key={category._id || index}>
                        <td>
                          <input
                            type="text"
                            value={category.name}
                            onChange={(e) => handleUpdateCategory(index, e.target.value)}
                          />
                        </td>
                        <td>
                          <button type="button" onClick={() => handleDeleteCategory(index)}>Delete</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <button type="button" onClick={handleAddCategory}>Add Category</button>
            </div>
          </div>
          <div className="form-actions">
            <button type="submit">Save</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditEquipmentTypeModal;
