import React, { useState, useEffect } from 'react';
import { FiCheck, FiX } from 'react-icons/fi';
import { getMaintenanceItems } from '../services/maintenanceItemService';
import '../styles/PartFilterModal.css';

const PartFilterModal = ({ filters, onApply, onClose }) => {
  const [filterConfig, setFilterConfig] = useState(filters);
  const [mainCategories] = useState(['Fluid', 'Filter', 'Part']);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedMainCategory, setSelectedMainCategory] = useState('All');
  const [maintenanceItems, setMaintenanceItems] = useState([]);

  useEffect(() => {
    fetchMaintenanceItems();
  }, []);

  const fetchMaintenanceItems = async () => {
    try {
      const items = await getMaintenanceItems();
      const activeItems = items.filter(item => 
        item.category !== 'Service' && item.active
      );
      setMaintenanceItems(activeItems);
    } catch (error) {
      console.error('Error fetching maintenance items:', error);
    }
  };

  useEffect(() => {
    if (selectedMainCategory === 'All') {
      setSubCategories([]);
      setFilterConfig(prev => ({
        ...prev,
        category: 'All',
        subCategory: 'All'
      }));
    } else {
      const filteredItems = maintenanceItems.filter(
        item => item.category === selectedMainCategory
      );
      const uniqueNames = [...new Set(filteredItems.map(item => item.name))];
      setSubCategories(uniqueNames);
    }
  }, [selectedMainCategory, maintenanceItems]);

  const handleMainCategoryChange = (category) => {
    setSelectedMainCategory(category);
    setFilterConfig(prev => ({
      ...prev,
      category: category,
      subCategory: 'All'
    }));
  };

  const handleSubCategoryChange = (subCategory) => {
    setFilterConfig(prev => ({
      ...prev,
      subCategory: subCategory
    }));
  };

  const handleApplyFilters = () => {
    const apiFilters = {
      ...filterConfig,
      category: filterConfig.subCategory !== 'All' 
        ? filterConfig.subCategory 
        : filterConfig.category
    };
    onApply(apiFilters);
  };

  return (
    <div className="modal-overlay">
      <div className="part-filter-modal">
        <h2>Filter Parts</h2>

        <div className="filter-section">
          <label>Main Category:</label>
          <select
            value={selectedMainCategory}
            onChange={(e) => handleMainCategoryChange(e.target.value)}
          >
            <option value="All">All Categories</option>
            {mainCategories.map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        </div>

        {selectedMainCategory !== 'All' && (
          <div className="filter-section">
            <label>Sub Category:</label>
            <select
              value={filterConfig.subCategory}
              onChange={(e) => handleSubCategoryChange(e.target.value)}
            >
              <option value="All">All {selectedMainCategory}s</option>
              {subCategories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>
        )}

        <div className="filter-section">
          <label>Manufacturer:</label>
          <select
            value={filterConfig.manufacturer}
            onChange={(e) => setFilterConfig(prev => ({ ...prev, manufacturer: e.target.value }))}
          >
            <option value="All">All Manufacturers</option>
            {/* Add manufacturer options dynamically */}
          </select>
        </div>

        <div className="filter-section">
          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={filterConfig.lowStock}
              onChange={(e) => setFilterConfig(prev => ({ ...prev, lowStock: e.target.checked }))}
            />
            Show Low Stock Items Only
          </label>
        </div>

        <div className="filter-actions">
          <button onClick={handleApplyFilters} className="apply-button">
            <FiCheck /> Apply Filters
          </button>
          <button onClick={onClose} className="cancel-button">
            <FiX /> Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default PartFilterModal; 