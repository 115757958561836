import React, { useState, useEffect } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { updateUser, getRoles, getUserById } from '../../services/adminService';

function EditUserForm({ userId, onClose, onUserUpdated }) {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    cellPhone: '',
    roles: [],
    changePassword: false,
    newPassword: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        setError('');
        
        if (!userId) {
          console.error('No userId provided');
          setError('User ID is missing');
          return;
        }

        console.log('Loading data for user:', userId);
        
        // Fetch user and roles data in parallel
        const [userResponse, rolesResponse] = await Promise.all([
          getUserById(userId),
          getRoles()
        ]);

        console.log('User Response:', userResponse);
        console.log('Roles Response:', rolesResponse);

        // Handle roles data
        if (rolesResponse && Array.isArray(rolesResponse)) {
          const sortedRoles = [...rolesResponse].sort((a, b) => a.order - b.order);
          setAvailableRoles(sortedRoles);
        } else {
          console.error('Invalid roles data:', rolesResponse);
          setError('Failed to load roles');
        }

        // Handle user data
        if (userResponse && userResponse.success && userResponse.data) {
          const userData = userResponse.data;
          console.log('Setting user data:', userData);
          
          setFormData({
            username: userData.username || '',
            email: userData.email || '',
            firstname: userData.firstname || '',
            lastname: userData.lastname || '',
            cellPhone: userData.cellPhone || '',
            roles: Array.isArray(userData.roles) 
              ? userData.roles.map(role => role._id)
              : [],
            changePassword: false,
            newPassword: ''
          });
        } else {
          console.error('Invalid user data:', userResponse);
          setError('Failed to load user data');
        }
      } catch (error) {
        console.error('Error loading data:', error);
        setError('Failed to load data: ' + (error.message || 'Unknown error'));
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleRoleChange = (e) => {
    const { value, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      roles: checked
        ? [...prevState.roles, value]
        : prevState.roles.filter(roleId => roleId !== value)
    }));
  };

  const handlePasswordToggle = (e) => {
    const { checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      changePassword: checked,
      newPassword: ''
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      const response = await updateUser(userId, formData);
      if (response.success) {
        setSuccess('User updated successfully');
        onUserUpdated();
        onClose();
      } else {
        setError(response.message || 'Failed to update user');
      }
    } catch (error) {
      console.error('Error updating user:', error);
      setError(error.response?.data?.message || 'Failed to update user');
    }
  };

  if (isLoading) {
    return (
      <div className="fixed inset-0 z-[9999] overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-center justify-center min-h-screen">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <p className="text-center">Loading user data...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 z-[9999] overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        {/* Background overlay */}
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-[9999]" aria-hidden="true"></div>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        {/* Modal panel */}
        <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full z-[10000]">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="w-full">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Edit User</h2>
              
              {error && (
                <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md border border-red-200">
                  {error}
                </div>
              )}
              
              {success && (
                <div className="mb-4 p-3 bg-green-50 text-green-700 rounded-md border border-green-200">
                  {success}
                </div>
              )}

              <form onSubmit={handleSubmit} className="space-y-6">
                {/* Username and Email */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="username" className="block text-sm font-medium text-gray-700 mb-1">
                      Username
                    </label>
                    <input
                      type="text"
                      id="username"
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                      required
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>
                </div>

                {/* First Name and Last Name */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="firstname" className="block text-sm font-medium text-gray-700 mb-1">
                      First Name
                    </label>
                    <input
                      type="text"
                      id="firstname"
                      name="firstname"
                      value={formData.firstname}
                      onChange={handleChange}
                      required
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>
                  <div>
                    <label htmlFor="lastname" className="block text-sm font-medium text-gray-700 mb-1">
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lastname"
                      name="lastname"
                      value={formData.lastname}
                      onChange={handleChange}
                      required
                      className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>
                </div>

                {/* Cell Phone */}
                <div>
                  <label htmlFor="cellPhone" className="block text-sm font-medium text-gray-700 mb-1">
                    Cell Phone
                  </label>
                  <input
                    type="tel"
                    id="cellPhone"
                    name="cellPhone"
                    value={formData.cellPhone}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>

                {/* Password Section */}
                <div>
                  <div className="flex items-center space-x-2 mb-2">
                    <input
                      type="checkbox"
                      id="changePassword"
                      name="changePassword"
                      checked={formData.changePassword}
                      onChange={handlePasswordToggle}
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label htmlFor="changePassword" className="text-sm text-gray-700">
                      Change Password
                    </label>
                  </div>

                  {formData.changePassword && (
                    <div className="relative">
                      <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700 mb-1">
                        New Password
                      </label>
                      <div className="relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="newPassword"
                          name="newPassword"
                          value={formData.newPassword}
                          onChange={handleChange}
                          required={formData.changePassword}
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 pr-10"
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-500 hover:text-gray-700"
                        >
                          {showPassword ? <FiEyeOff className="h-5 w-5" /> : <FiEye className="h-5 w-5" />}
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                {/* Roles */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Roles
                  </label>
                  <div className="space-y-2 max-h-40 overflow-y-auto p-3 border border-gray-200 rounded-md">
                    {availableRoles.map(role => (
                      <div key={role._id} className="flex items-center">
                        <input
                          type="checkbox"
                          id={role.name}
                          name="roles"
                          value={role._id}
                          checked={formData.roles.includes(role._id)}
                          onChange={handleRoleChange}
                          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        />
                        <label htmlFor={role.name} className="ml-2 block text-sm text-gray-700">
                          {role.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Form Actions */}
                <div className="flex justify-end space-x-3 pt-4 border-t border-gray-200">
                  <button
                    type="button"
                    onClick={onClose}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Update User
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUserForm;