import React, { useState } from 'react';
import { FiCheck, FiX, FiArrowDown } from 'react-icons/fi';

function EquipmentSortModal({ onApply, onClose, currentSort }) {
  const [sortConfig, setSortConfig] = useState(currentSort || {
    field: 'ID',
    direction: 'asc'
  });
  
  const sortOptions = [
    { value: 'ID', label: 'Equipment ID' },
    { value: 'Type', label: 'Type' },
    { value: 'Category', label: 'Category' },
    { value: 'Make', label: 'Make' },
    { value: 'Model', label: 'Model' },
    { value: 'Location', label: 'Location' },
    { value: 'Status', label: 'Status' }
  ];

  const handleChange = (field, value) => {
    setSortConfig(prev => ({ ...prev, [field]: value }));
  };

  const toggleDirection = () => {
    setSortConfig(prev => ({
      ...prev,
      direction: prev.direction === 'desc' ? 'asc' : 'desc'
    }));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999]">
      <div className="bg-white p-6 rounded-lg w-full max-w-md">
        <h2 className="text-xl font-semibold mb-4">Sort Equipment</h2>
        
        <div className="space-y-4">
          <div className="flex flex-col">
            <label className="mb-1 text-gray-700">Sort By:</label>
            <select 
              value={sortConfig.field} 
              onChange={(e) => handleChange('field', e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            >
              {sortOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <button 
              className="flex items-center gap-2 px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
              onClick={toggleDirection}
            >
              <FiArrowDown className={`transform transition-transform ${sortConfig.direction === 'desc' ? 'rotate-180' : ''}`} />
              {sortConfig.direction === 'desc' ? 'Z to A' : 'A to Z'}
            </button>
          </div>
        </div>

        <div className="flex justify-end gap-2 mt-6">
          <button 
            className="flex items-center gap-2 px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
            onClick={onClose}
          >
            <FiX />
            Cancel
          </button>
          <button 
            className="flex items-center gap-2 px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 transition-colors"
            onClick={() => onApply(sortConfig)}
          >
            <FiCheck />
            Apply
          </button>
        </div>
      </div>
    </div>
  );
}

export default EquipmentSortModal;