import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { createPart } from '../services/partService';
import { getMaintenanceItems, createMaintenanceItem } from '../services/maintenanceItemService';
import '../styles/AddPartModal.css';

const AddPartModal = ({ onClose, onPartAdded }) => {
  const [mainCategories] = useState(['Fluid', 'Filter', 'Part']);
  const [maintenanceItems, setMaintenanceItems] = useState([]);
  const [selectedMainCategory, setSelectedMainCategory] = useState('');
  const [availableSubCategories, setAvailableSubCategories] = useState([]);
  const [formData, setFormData] = useState({
    partNumber: '',
    name: '',
    manufacturer: '',
    description: '',
    mainCategory: '',
    category: '',
    unit: 'Each',
    inventory: {
      quantity: 0,
      minimumQuantity: 0,
      location: '',
      binNumber: ''
    }
  });
  const [error, setError] = useState('');
  const [isAddingNewSubCategory, setIsAddingNewSubCategory] = useState(false);
  const [newSubCategory, setNewSubCategory] = useState({
    name: '',
    description: '',
    requiresParts: true
  });

  useEffect(() => {
    fetchMaintenanceItems();
  }, []);

  useEffect(() => {
    if (selectedMainCategory && maintenanceItems.length > 0) {
      updateSubCategories(selectedMainCategory);
    }
  }, [selectedMainCategory, maintenanceItems]);

  const fetchMaintenanceItems = async () => {
    try {
      const items = await getMaintenanceItems(true); // Get only active items
      const activeItems = items.filter(item => 
        item.category !== 'Service' && item.active
      );
      setMaintenanceItems(activeItems);
    } catch (error) {
      console.error('Error fetching maintenance items:', error);
      setError('Failed to load maintenance categories');
    }
  };

  const updateSubCategories = (mainCategory) => {
    const filteredItems = maintenanceItems.filter(
      item => item.category === mainCategory
    );
    const uniqueNames = [...new Set(filteredItems.map(item => item.name))];
    setAvailableSubCategories(uniqueNames);
  };

  const handleMainCategoryChange = (category) => {
    setSelectedMainCategory(category);
    setFormData(prev => ({
      ...prev,
      mainCategory: category,
      category: '' // Reset subcategory when main category changes
    }));
  };

  const handleSubCategoryChange = (subCategory) => {
    setFormData(prev => ({
      ...prev,
      category: subCategory
    }));
  };

  const handleCreateSubCategory = async () => {
    try {
      // Create new maintenance item for the subcategory
      const maintenanceItemData = {
        name: newSubCategory.name,
        description: newSubCategory.description,
        category: selectedMainCategory,
        type: 'Single',
        requiresParts: true,
        active: true
      };

      const createdItem = await createMaintenanceItem(maintenanceItemData);
      
      // Add the new subcategory to the available subcategories
      setAvailableSubCategories(prev => [...prev, createdItem.name]);
      
      // Select the newly created subcategory
      handleSubCategoryChange(createdItem.name);
      
      // Reset form and close new subcategory section
      setNewSubCategory({
        name: '',
        description: '',
        requiresParts: true
      });
      setIsAddingNewSubCategory(false);
    } catch (error) {
      console.error('Error creating subcategory:', error);
      setError('Failed to create new subcategory');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Generate name from manufacturer and part number
      const partName = `${formData.manufacturer} ${formData.partNumber}`;
      
      const partData = {
        ...formData,
        name: partName
      };

      const newPart = await createPart(partData);
      onPartAdded(newPart);
      onClose();
    } catch (error) {
      console.error('Error creating part:', error);
      setError('Failed to create part');
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="add-part-modal" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h2>Add New Part</h2>
          <button className="close-button" onClick={onClose}>
            <FaTimes />
          </button>
        </div>

        {error && <div className="error-message">{error}</div>}

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Main Category</label>
            <select
              value={formData.mainCategory}
              onChange={e => handleMainCategoryChange(e.target.value)}
              required
            >
              <option value="">Select Main Category</option>
              {mainCategories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Sub Category</label>
            <div className="subcategory-section">
              <select
                value={formData.category}
                onChange={e => handleSubCategoryChange(e.target.value)}
                required
                disabled={!selectedMainCategory || isAddingNewSubCategory}
              >
                <option value="">Select Sub Category</option>
                {availableSubCategories.map(category => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
              {!isAddingNewSubCategory ? (
                <button
                  type="button"
                  onClick={() => setIsAddingNewSubCategory(true)}
                  className="add-subcategory-button"
                  disabled={!selectedMainCategory}
                >
                  + New Sub Category
                </button>
              ) : (
                <div className="new-subcategory-form">
                  <input
                    type="text"
                    placeholder="New Sub Category Name"
                    value={newSubCategory.name}
                    onChange={e => setNewSubCategory(prev => ({
                      ...prev,
                      name: e.target.value
                    }))}
                    required
                  />
                  <input
                    type="text"
                    placeholder="Description (optional)"
                    value={newSubCategory.description}
                    onChange={e => setNewSubCategory(prev => ({
                      ...prev,
                      description: e.target.value
                    }))}
                  />
                  <div className="subcategory-actions">
                    <button
                      type="button"
                      onClick={handleCreateSubCategory}
                      className="save-subcategory-button"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={() => setIsAddingNewSubCategory(false)}
                      className="cancel-subcategory-button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="form-group">
            <label>Manufacturer</label>
            <input
              type="text"
              value={formData.manufacturer}
              onChange={e => setFormData({ ...formData, manufacturer: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label>Part Number</label>
            <input
              type="text"
              value={formData.partNumber}
              onChange={e => setFormData({ ...formData, partNumber: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label>Description</label>
            <input
              type="text"
              value={formData.description}
              onChange={e => setFormData({ ...formData, description: e.target.value })}
            />
          </div>

          <div className="form-group">
            <label>Initial Quantity</label>
            <input
              type="number"
              value={formData.inventory.quantity}
              onChange={e => setFormData({
                ...formData,
                inventory: { ...formData.inventory, quantity: Number(e.target.value) }
              })}
              min="0"
            />
          </div>

          <div className="form-group">
            <label>Minimum Quantity</label>
            <input
              type="number"
              value={formData.inventory.minimumQuantity}
              onChange={e => setFormData({
                ...formData,
                inventory: { ...formData.inventory, minimumQuantity: Number(e.target.value) }
              })}
              min="0"
            />
          </div>

          <div className="form-group">
            <label>Location</label>
            <input
              type="text"
              value={formData.inventory.location}
              onChange={e => setFormData({
                ...formData,
                inventory: { ...formData.inventory, location: e.target.value }
              })}
            />
          </div>

          <div className="form-group">
            <label>Bin Number</label>
            <input
              type="text"
              value={formData.inventory.binNumber}
              onChange={e => setFormData({
                ...formData,
                inventory: { ...formData.inventory, binNumber: e.target.value }
              })}
            />
          </div>

          <div className="modal-actions">
            <button type="submit" className="save-button">Create Part</button>
            <button type="button" className="cancel-button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPartModal; 