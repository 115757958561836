import axiosInstance from './api';

export const getDevices = async () => {
  try {
    const response = await axiosInstance.get('/devices');
    return response.data;
  } catch (error) {
    console.error('Error fetching devices:', error);
    throw error;
  }
};

export const registerDevice = async (deviceData) => {
  try {
    const response = await axiosInstance.post('/devices/register', deviceData);
    return response.data;
  } catch (error) {
    console.error('Error registering device:', error);
    throw error;
  }
};

export const regenerateToken = async (deviceId) => {
  try {
    const response = await axiosInstance.post(`/devices/${deviceId}/token`);
    return response.data;
  } catch (error) {
    console.error('Error regenerating token:', error);
    throw error;
  }
};

export const deactivateDevice = async (deviceId) => {
  try {
    const response = await axiosInstance.post(`/devices/${deviceId}/deactivate`);
    return response.data;
  } catch (error) {
    console.error('Error deactivating device:', error);
    throw error;
  }
};
