import React, { useState, useEffect } from 'react';
import { getParts } from '../services/partService';
import { useAuth } from '../hooks/useAuth';
import { useMediaQuery } from 'react-responsive';
import { TbCubePlus } from 'react-icons/tb';
import { FiFilter, FiSearch } from 'react-icons/fi';
import { FaSort } from 'react-icons/fa';
import PartsList from './PartsList';
import PartModal from './PartModal';
import AddPartModal from './AddPartModal';
import PartFilterModal from './PartFilterModal';
import PartSortModal from './PartSortModal';

function Parts() {
  const { user } = useAuth();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  
  const [parts, setParts] = useState([]);
  const [selectedPart, setSelectedPart] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showSortModal, setShowSortModal] = useState(false);
  const [showPartModal, setShowPartModal] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    category: 'All',
    manufacturer: 'All',
    lowStock: false
  });
  const [sortConfig, setSortConfig] = useState({
    field: 'partNumber',
    direction: 'asc'
  });
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchParts();
  }, [filters, sortConfig, searchQuery]);

  const fetchParts = async () => {
    try {
      setIsLoading(true);
      const partsData = await getParts({
        ...filters,
        search: searchQuery
      });
      setParts(partsData);
    } catch (error) {
      setError('Failed to fetch parts');
      console.error('Error fetching parts:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePartClick = (part) => {
    setSelectedPart(part);
    if (isMobile) {
      setShowPartModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowPartModal(false);
    setSelectedPart(null);
  };

  const handleFilterApply = (newFilters) => {
    setFilters(newFilters);
    setShowFilterModal(false);
  };

  const handleSortApply = (newSortConfig) => {
    setSortConfig(newSortConfig);
    setShowSortModal(false);
  };

  const handlePartDeleted = (deletedPartId) => {
    setParts(prevParts => prevParts.filter(part => part._id !== deletedPartId));
    if (selectedPart?._id === deletedPartId) {
      setSelectedPart(null);
      setShowPartModal(false);
    }
  };

  return (
    <div className="flex flex-col h-[calc(100vh-80px)] mt-[80px]">
      <div className="flex h-full overflow-hidden">
        {/* Left section - Parts List */}
        <div className={`flex flex-col min-w-0 ${!isMobile ? 'w-[400px]' : 'flex-1'}`}>
          {/* Controls section - always visible */}
          <div className="px-2 pt-2 pb-1 bg-white">
            <div className="flex justify-between items-center">
              <button
                className="p-1 text-green-500 hover:text-green-600 transition-colors"
                onClick={() => setShowAddModal(true)}
                title="Add Part"
              >
                <TbCubePlus className="w-8 h-8" />
              </button>
              
              <div className="flex gap-1">
                <button
                  className={`p-1 ${showSearch ? 'text-blue-500 hover:text-blue-600' : 'text-gray-600 hover:text-gray-800'} transition-colors`}
                  onClick={() => setShowSearch(!showSearch)}
                  title="Toggle Search"
                >
                  <FiSearch className="w-7 h-7" />
                </button>

                <button
                  className="p-1 text-gray-600 hover:text-gray-800 transition-colors"
                  onClick={() => setShowFilterModal(true)}
                  title="Filter Parts"
                >
                  <FiFilter className="w-7 h-7" />
                </button>

                <button
                  className="p-1 text-gray-600 hover:text-gray-800 transition-colors"
                  onClick={() => setShowSortModal(true)}
                  title="Sort Parts"
                >
                  <FaSort className="w-7 h-7" />
                </button>
              </div>
            </div>
          </div>

          {/* List Header - Fixed */}
          <div className="px-2 py-1 bg-white border-b border-gray-200">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-bold">Parts</h2>
              <span className="text-sm text-gray-600">Sort: {sortConfig.field}</span>
            </div>
          </div>

          {/* Search Bar - Toggleable */}
          {showSearch && (
            <div className="px-2 py-2 bg-white border-b border-gray-200">
              <div className="flex items-center gap-2 bg-white border border-gray-300 rounded-lg px-3 py-2">
                <FiSearch className="w-5 h-5 text-gray-500" />
                <input
                  type="text"
                  placeholder="Search parts..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full outline-none text-sm"
                  autoFocus
                />
              </div>
            </div>
          )}
          {error && (
            <p className="text-red-500 text-center my-4">{error}</p>
          )}

          {/* Parts List section - scrollable */}
          {isLoading ? (
            <p className="text-center p-4">Loading parts...</p>
          ) : (
            <div className="flex-1 overflow-y-auto">
              <PartsList 
                parts={parts}
                onSelectPart={handlePartClick}
                selectedPart={selectedPart}
                sortConfig={sortConfig}
              />
            </div>
          )}
        </div>

        {/* Right section - Part Modal */}
        {!isMobile && selectedPart && (
          <div className="flex-1 border-l border-gray-200 flex flex-col relative z-[9000]">
            <div className="flex-1 overflow-y-auto pt-8">
              <PartModal 
                part={selectedPart}
                onClose={() => setSelectedPart(null)}
                onUpdate={fetchParts}
                onDelete={handlePartDeleted}
                isInlineDisplay={true}
              />
            </div>
          </div>
        )}
      </div>

      {/* Modals */}
      {showAddModal && (
        <AddPartModal
          onClose={() => setShowAddModal(false)}
          onPartAdded={fetchParts}
        />
      )}

      {showFilterModal && (
        <PartFilterModal
          filters={filters}
          onApply={handleFilterApply}
          onClose={() => setShowFilterModal(false)}
        />
      )}

      {showSortModal && (
        <PartSortModal
          sortConfig={sortConfig}
          onApply={handleSortApply}
          onClose={() => setShowSortModal(false)}
        />
      )}

      {isMobile && showPartModal && selectedPart && (
        <PartModal
          part={selectedPart}
          onClose={handleCloseModal}
          onUpdate={fetchParts}
          onDelete={handlePartDeleted}
          isInlineDisplay={false}
        />
      )}
    </div>
  );
}

export default Parts;