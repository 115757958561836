import React, { useState, useEffect, useCallback } from 'react';
import { FiUserPlus } from 'react-icons/fi';
import { getUsersWithFilter, softDeleteUser, reactivateUser, resetUserPassword, permanentDeleteUser } from '../../services/adminService';
import AddUserForm from './AddUserForm';
import EditUserForm from './EditUserForm';
import { useMediaQuery } from 'react-responsive';

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [showEditUserForm, setShowEditUserForm] = useState(false);
  const [editingUserId, setEditingUserId] = useState(null);
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [showPermanentDeleteConfirmation, setShowPermanentDeleteConfirmation] = useState(false);
  const [userToPermanentDelete, setUserToPermanentDelete] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const fetchUsers = useCallback(async () => {
    try {
      setError('');
      const data = await getUsersWithFilter(showDeletedUsers);
      
      if (data.success && Array.isArray(data.data)) {
        setUsers(data.data);
      } else {
        throw new Error('Unexpected data format');
      }
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Failed to fetch users: ' + (err.response?.data?.message || err.message));
    }
  }, [showDeletedUsers]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers, showDeletedUsers]);

  const handleEditUser = (userId) => {
    setEditingUserId(userId);
    setShowEditUserForm(true);
  };

  const handleDeleteUser = (user) => {
    setUserToDelete(user);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteUser = async () => {
    try {
      await softDeleteUser(userToDelete._id);
      setShowDeleteConfirmation(false);
      fetchUsers();
    } catch (err) {
      console.error('Error deleting user:', err);
      setError('Failed to delete user');
    }
  };

  const handleReactivateUser = async (userId) => {
    try {
      console.log('Attempting to reactivate user:', userId);
      console.log('Current user roles:', localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).roles : 'No user data');
      const result = await reactivateUser(userId);
      console.log('Reactivation result:', result);
      fetchUsers();
    } catch (err) {
      console.error('Error reactivating user:', err);
      console.error('Error details:', err.response?.data);
      setError('Failed to reactivate user: ' + (err.response?.data?.message || err.message));
    }
  };

  const handleResetPassword = async (userId) => {
    try {
      await resetUserPassword(userId);
      // Show success message
      // You might want to add a toast notification here
    } catch (error) {
      setError('Failed to reset password');
    }
  };

  const handlePermanentDelete = (user) => {
    setUserToPermanentDelete(user);
    setShowPermanentDeleteConfirmation(true);
  };

  const confirmPermanentDelete = async () => {
    try {
      await permanentDeleteUser(userToPermanentDelete._id);
      setShowPermanentDeleteConfirmation(false);
      fetchUsers();
    } catch (err) {
      console.error('Error permanently deleting user:', err);
      setError('Failed to permanently delete user');
    }
  };

  const handleShowDeletedChange = () => {
    setShowDeletedUsers(prev => !prev);
  };

  const renderUser = (user) => (
    <tr key={user._id} className="hover:bg-gray-50">
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.username}</td>
      {!isMobile && (
        <>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.email}</td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {user.roles.map(role => role.name).join(', ')}
          </td>
        </>
      )}
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium space-x-2">
        {!user.isDeleted ? (
          <>
            <button 
              className="text-indigo-600 hover:text-indigo-900 bg-indigo-50 hover:bg-indigo-100 px-3 py-1 rounded-md transition-colors duration-150"
              onClick={() => handleEditUser(user._id)}
            >
              Edit
            </button>
            <button 
              className="text-red-600 hover:text-red-900 bg-red-50 hover:bg-red-100 px-3 py-1 rounded-md transition-colors duration-150"
              onClick={() => handleDeleteUser(user)}
            >
              Delete
            </button>
            <button 
              className="text-yellow-600 hover:text-yellow-900 bg-yellow-50 hover:bg-yellow-100 px-3 py-1 rounded-md transition-colors duration-150"
              onClick={() => handleResetPassword(user._id)}
            >
              Reset Password
            </button>
          </>
        ) : (
          <>
            <button 
              className="text-green-600 hover:text-green-900 bg-green-50 hover:bg-green-100 px-3 py-1 rounded-md transition-colors duration-150"
              onClick={() => handleReactivateUser(user._id)}
            >
              Reactivate
            </button>
            <button 
              className="text-red-600 hover:text-red-900 bg-red-50 hover:bg-red-100 px-3 py-1 rounded-md transition-colors duration-150"
              onClick={() => handlePermanentDelete(user)}
            >
              Permanent Delete
            </button>
          </>
        )}
      </td>
    </tr>
  );

  return (
    <div className="p-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 space-y-4 sm:space-y-0">
        <h2 className="text-2xl font-bold text-gray-900">User Management</h2>
        <div className="flex items-center space-x-4">
          <label className="flex items-center space-x-2 text-sm text-gray-600">
            <input
              type="checkbox"
              checked={showDeletedUsers}
              onChange={handleShowDeletedChange}
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            <span>Show Deleted Users</span>
          </label>
          <button 
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-md shadow-sm transition-colors duration-150"
            onClick={() => setShowAddUserForm(true)}
          >
            <FiUserPlus className="mr-2" /> Add User
          </button>
        </div>
      </div>

      {error && (
        <div className="mb-4 p-4 bg-red-50 border border-red-200 text-red-700 rounded-md">
          {error}
        </div>
      )}

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Username</th>
              {!isMobile && (
                <>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Roles</th>
                </>
              )}
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {users.map(user => renderUser(user))}
          </tbody>
        </table>
      </div>

      {showAddUserForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <AddUserForm
            onClose={() => setShowAddUserForm(false)}
            onUserAdded={fetchUsers}
          />
        </div>
      )}

      {showEditUserForm && (
        <div className="fixed inset-0 z-[9999] bg-black bg-opacity-50 flex items-center justify-center p-4">
          <EditUserForm
            userId={editingUserId}
            onClose={() => {
              setShowEditUserForm(false);
              setEditingUserId(null);
            }}
            onUserUpdated={fetchUsers}
          />
        </div>
      )}

      {showDeleteConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-md w-full">
            <h2 className="text-xl font-bold text-gray-900 mb-4">Delete User</h2>
            <p className="text-gray-600 mb-2">Are you sure you want to delete the user "{userToDelete.username}"?</p>
            <p className="text-gray-600 mb-6">This action will deactivate the user account.</p>
            <div className="flex justify-end space-x-3">
              <button
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => setShowDeleteConfirmation(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                onClick={confirmDeleteUser}
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {showPermanentDeleteConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-md w-full">
            <h2 className="text-xl font-bold text-gray-900 mb-4">Permanent Delete User</h2>
            <p className="text-gray-600 mb-2">Are you sure you want to permanently delete the user "{userToPermanentDelete.username}"?</p>
            <p className="text-gray-600 mb-6">This action cannot be undone.</p>
            <div className="flex justify-end space-x-3">
              <button
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => setShowPermanentDeleteConfirmation(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                onClick={confirmPermanentDelete}
              >
                Yes, Delete Permanently
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserManagement;
